import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './ForgotPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { notification } from 'antd';
import { postForgotPassword } from '@app/api/login.api';

interface ForgotPasswordFormData {
  email: string;
}

const initValues = {
  email: null,
};

export const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: ForgotPasswordFormData) => {
    setLoading(true);
    postForgotPassword(values)
      .then((response: any) => {
        if (response?.status === 'success') {
          notification.success({ message: response?.data || response.data?.message });
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.BackWrapper onClick={() => navigate('/login')}>
          <Auth.BackIcon />
          {t('button.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('common.forgotPassword')}</Auth.FormTitle>
        <S.Description>{t('forgotPassword.description')}</S.Description>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('common.requiredField')));
              },
            },
            { type: 'email', message: t('validator.notValidEmail') },
            { max: 255, message: t('validator.maxLength', { count: 255 }) },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('forgotPassword.sendInstructions')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
