import userReducer from '@app/store/slices/userSlice';
import memberReducer from '@app/store/member/member';
import dirtyReducer from '@app/store/dirty/dirty';
import authReducer from '@app/store/slices/authSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';

export default {
  user: userReducer,
  member: memberReducer,
  dirty: dirtyReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
};
