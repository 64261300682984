import { callApi, IApiResponse, IPageQuery, IPageResponse, METHOD } from '@app/api/http.api';
import { notification } from 'antd';
import { getProfile } from '@app/api/login.api';
import { IGroup } from './group.api';
import i18n from 'i18next';
import { ILogObject, objToQueryString, TypeUser } from '@app/utils/utils';

export interface IDomainExportResponse {
  addressCity: string;
  addressWardStreet: string;
  chatBotLink: string;
  companyName: string;
  department: string;
  domainName: string;
  externalApps: IExternalapp[];
  groupName: string;
  isActive: boolean;
  mailAccount: string;
  numberOfAllUser: string;
  numberOfAppUser: string;
  numberOfChatbotUser: string;
  numberOfSmoothUser: string;
  numberPostOffice: string;
  representativeName: string;
  smoothDateOfExpiry: string;
  smoothIsActive: string;
  smoothLink: string;
  subMailAccount: string;
  subTelephone: string;
  telephone: string;
}

export interface IExternalapp {
  createdAt: string;
  createdBy: string;
  deletedAt: string;
  domainId: number;
  id: number;
  isActive: boolean;
  name: string;
  updatedAt: string;
  updatedBy: string;
  url: string;
}

export interface IDomain {
  addressCity: string;
  addressWardStreet: string;
  chatbot: any;
  companyName: string;
  createdAt: string;
  department: string;
  domainName: string;
  externalApps: IExternalapp[];
  group: IGroup;
  id: number;
  isActive: boolean;
  mailAccount: string;
  numberPostOffice: string;
  representativeName: string;
  smooth: any;
  subMailAccount: string;
  subTelephone: string;
  telephone: string;
}

export interface IDomainsRequest extends IPageQuery {
  groupId?: number;
}

export interface IDomainsResponse extends IPageResponse {
  data: IDomain[];
}

export const getGroupInScope = async (): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.GET, `domains/groupsInScope`, null);
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const getDomainsRequest = async (payload: IDomainsRequest): Promise<IApiResponse<IDomainsResponse>> => {
  try {
    let response: any = await callApi(METHOD.GET, `domains`, payload);
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const postDomain = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null && v != ''));
    payload = JSON.parse(JSON.stringify(payload).replace(/"\s+|\s+"/g, '"'));
    const query: ILogObject = {
      objectName: payload.domainName,
      whereId: null,
      whereObject: TypeUser.DOMAIN,
      whereName: payload.domainName,
    };
    let response: any = await callApi(METHOD.POST, `domains${objToQueryString(query)}`, payload);
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    } else {
      getProfile();
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const getDomain = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.GET, `domains/${payload.domainId}?objectName=${payload.name}`, null);
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const patchDomain = async (idDomain: any, payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = Object.fromEntries(
      Object.entries(payload).filter(
        ([_, v]) => typeof v == 'boolean' || (typeof v != 'boolean' && v != null && !Number.isNaN(v)),
      ),
    );
    payload = JSON.parse(JSON.stringify(payload).replace(/"\s+|\s+"/g, '"'));
    const query: ILogObject = {
      objectName: payload.domainName,
      whereId: idDomain,
      whereObject: TypeUser.DOMAIN,
      whereName: payload.domainName,
    };
    let response: any = await callApi(METHOD.PATCH, `domains/${idDomain}${objToQueryString(query)}`, payload);
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    } else {
      getProfile();
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const exportDomain = async (
  groupId: number,
  search: string,
): Promise<{ statusCode: number; data: IDomainExportResponse[] } | undefined> => {
  try {
    const query: ILogObject = {
      objectName: null,
      whereId: null,
      whereObject: TypeUser.DOMAIN,
      whereName: null,
    };
    const payload = {
      groupId,
      search,
      ...query,
    };
    let response: any = await callApi(METHOD.GET, `domains/export`, payload);
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const blockDomain = async ({
  id,
  domainName,
}: {
  id: number;
  domainName: string;
}): Promise<{ data: any } | undefined> => {
  try {
    const query: ILogObject = {
      objectName: domainName,
      whereId: id,
      whereObject: TypeUser.DOMAIN,
      whereName: domainName,
    };
    let response: any = await callApi(METHOD.PUT, `domains/${id}/block${objToQueryString(query)}`, {});
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    } else {
      getProfile();
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const deleteDomain = async ({
  id,
  domainName,
}: {
  id: number;
  domainName: string;
}): Promise<{ data: any } | undefined> => {
  try {
    const query: ILogObject = {
      objectName: domainName,
      whereId: id,
      whereObject: TypeUser.DOMAIN,
      whereName: domainName,
    };
    let response: any = await callApi(METHOD.DELETE, `domains/${id}${objToQueryString(query)}`, { domainName });
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    } else {
      getProfile();
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};
