/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Card } from 'components/common/Card/Card';
import { useTranslation } from 'react-i18next';
import { PieChart } from '../../common/charts/PieChart';
import { TeamOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useNavigate } from 'react-router-dom';
import { getChartData } from '@app/api/dashboard.api';
import { message, notification } from 'antd';
import { Skeleton } from '@app/components/common/Skeleton/Skeleton.styles';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useLanguage } from '@app/hooks/useLanguage';

export const MemberPieChart: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [dataChart, setDataChart] = React.useState<any>([]);
  const name = t('members.status');
  const { language, setLanguage } = useLanguage();

  const onGetData = async () => {
    await getChartData()
      .then((response) => {
        if (response?.status == 'success') {
          setDataChart([
            { value: response?.data?.numUserActive, name: t('dashboard.active') },
            { value: response?.data?.numUsersSuspended, name: t('dashboard.suspended') },
          ]);
        }
      })
      .catch((e: any) => {});
  };

  const user = useAppSelector((state) => state.user.user);

  React.useEffect(() => {
    if (user && !user?.isPasswordReset) {
      onGetData();
    }
  }, [language]);

  return (
    <Card
      padding="0 0 1.875rem"
      title={
        <>
          <TeamOutlined />
          &nbsp;
          {t('dashboard.members')}
          <Button
            type="primary"
            className="btn-add-pie-chart"
            onClick={() => {
              navigate('/members/add-user');
            }}
          >
            <PlusOutlined />
            {t('button.add')}
          </Button>
        </>
      }
    >
      <PieChart height={260} data={dataChart} name={name} showLegend={true} />
    </Card>
  );
};
