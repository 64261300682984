import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Switch } from '@app/components/common/Switch/Switch';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { notification } from 'antd';
import { InputNumber } from '../../common/inputs/Input/Input';
import { IPasswordPolicyRequest } from '@app/api/policySetting.api';
import { useNavigate, useParams } from 'react-router-dom';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

interface IDataForm {
  pw_lock?: number;
  pw_unlock?: string;
  minutes?: number;
  mixed_numbers?: number;
  mixed_symbols?: string;
  mixed_lowercase?: number;
  mixed_uppercase?: number;
  pw_validity_period?: number;
  google_authenticator?: boolean;
  mixed_special_characters?: number;
  prohibition_of_reuse_of_pw?: number;
  maximum_number_of_characters?: number;
  minimum_number_of_characters?: number;
}

interface IPolicyApi {
  getPolicy: (request: IPasswordPolicyRequest) => Promise<{ data: any[] } | undefined>;
  updatePolicy: (request: IPasswordPolicyRequest) => Promise<{ data: any[] } | undefined>;
}

export const PolicyForm: React.FC<IPolicyApi> = ({ getPolicy, updatePolicy }) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [typeUnlock, setTypeUnlock] = useState('');
  const [minutes, setMinutes] = useState(0);
  const [isShowForm, setIsShowForm] = useState(false);
  const [dataForm, setDataForm] = useState<IDataForm>();
  const [maximum, setMaximum] = useState(0);
  const [minimum, setMinimum] = useState(0);
  const [mixNumber, setMixNumber] = useState(0);
  const [mixSpecial, setMixSpecial] = useState(0);
  const [mixLowercase, setMixLowercase] = useState(0);
  const [mixUppercase, setMixUppercase] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { idGroup, idDomain, action } = useParams();
  const [isEdit, setIsEdit] = useState<boolean>(true); //false

  // useEffect(() => {
  //   setIsEdit(action === 'edit');
  // }, [action]);

  useEffect(() => {
    onSearchPassWordPolicy();
  }, []);

  const onFinish = async (values = {}) => {
    onSubmitPassWordPolicy({
      value: values,
    });
  };

  const typeValidatorMono = (str = typeUnlock) => {
    const splited_string = str.trim().split(' ');
    const reg = new RegExp('^[0-9]+$');
    const cond =
      splited_string[0] === 'After' &&
      (splited_string[1] === '{X}' || reg.test(splited_string[1])) &&
      splited_string[2] === 'Minutes';
    return cond;
  };

  const typeValidatorDual = (str = typeUnlock) => {
    const splited_string = str.trim().split(' ');
    const reg = new RegExp('^[0-9]+$');
    const cond =
      splited_string[0] === 'After' &&
      (splited_string[1] === '{X}' || reg.test(splited_string[1])) &&
      splited_string[2] === 'Minutes;Admin';
    return cond;
  };

  const onSearchPassWordPolicy = () => {
    // const request: IPasswordPolicyRequest = { };
    const request: IPasswordPolicyRequest = { id: idDomain || idGroup || undefined };
    getPolicy(request)
      .then((res: any) => {
        if (!res) notification.error({ message: t('message.error500') });
        if (res && res?.status == 'success') {
          let { value: data } = res.data;
          if (typeValidatorMono(data.pw_unlock))
            data = {
              ...data,
              minutes: data.pw_unlock.split(' ')[1],
              pw_unlock: t('After {X} Minutes'),
            };
          if (typeValidatorDual(data.pw_unlock))
            data = {
              ...data,
              minutes: data.pw_unlock.split(' ')[1],
              pw_unlock: t('After {X} Minutes;Admin Manual'),
            };
          setDataForm(data);
          setTypeUnlock(data.pw_unlock);
          setMinutes(data.minutes);
          setMaximum(data.maximum_number_of_characters);
          setMinimum(data.minimum_number_of_characters);
          setMixNumber(data.mixed_numbers);
          setMixSpecial(data.mixed_special_characters);
          setMixLowercase(data.mixed_lowercase);
          setMixUppercase(data.mixed_uppercase);
          setIsShowForm(true);
        } else {
          notification.error({ message: res.message || t('message.error500') });
        }
      })
      .catch((e) => notification.error({ message: e.message }));
  };

  const onSubmitPassWordPolicy = (data: any) => {
    const payload = data;
    if (payload.value.minutes) {
      payload.value.pw_unlock = payload.value.pw_unlock.replace('{X}', minutes);
      delete payload.value.minutes;
    }
    // const request: IPasswordPolicyRequest = { payload };
    const request: IPasswordPolicyRequest = { id: idDomain || idGroup || undefined, payload };
    // const request: IPasswordPolicyRequest = { id: id || idDomain, payload };
    updatePolicy(request)
      .then((res: any) => {
        if (!res) notification.error({ message: t('message.error500') });
        if (res.status === 'success') {
          setDataForm(res.data.value);
          setIsShowForm(true);
          notification.success({ message: t('message.updatePasswordPolicySuccessfully') });
        } else {
          notification.error({ message: res.message ? res.message : res[0].messages[0] });
        }
      })
      .catch((e: any) => {
        notification.error({ message: e.message });
      });
  };

  return isShowForm ? (
    <BaseButtonsForm
      {...formItemLayout}
      confirmSubmitProps={{ active: true }}
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      initialValues={dataForm}
      footer={<></>}
      onFinish={onFinish}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          {/* <Col span={12}> */}
          <BaseButtonsForm.Item
            name="google_authenticator"
            label={t('policy.googleAuthenticator')}
            valuePropName="checked"
          >
            <Switch disabled={!isEdit} />
          </BaseButtonsForm.Item>
        </Col>
        {/* <Col span={12}>
          {!isEdit && (
            <Button
              style={{ position: 'absolute', right: '5px' }}
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                if (!!idGroup && !!idDomain) {
                  navigate(`/domain/edit/${idGroup}/${idDomain}`);
                } else if (!!idGroup && !idDomain) {
                  navigate(`/groups/edit/${idGroup}`);
                } else {
                  navigate(`/policy-setting/edit`);
                }
              }}
            >
              {t('button.edit')}
            </Button>
          )}
        </Col> */}
        <Col span={12}>
          <BaseButtonsForm.Item
            name="pw_lock"
            label={t('policy.passwordLock')}
            hasFeedback
            validateTrigger={'onSubmit'}
            rules={[
              { required: true, message: `${t('policy.passwordLock')} ${t('policy.isRequired')}` },
              () => ({
                validator(rules, value) {
                  if (value >= 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error(t('validator.notAllowNegativeNumbers')));
                  }
                },
              }),
              { pattern: /^\d+$/, message: t('validator.onlyInteger') },
            ]}
          >
            <InputNumber placeholder={t('policy.passwordLock')} disabled={!isEdit} />
          </BaseButtonsForm.Item>
        </Col>
        <Col span={!typeValidatorMono(typeUnlock) && !typeValidatorDual(typeUnlock) ? 8 : 6}>
          <BaseButtonsForm.Item
            name="pw_unlock"
            label={t('policy.passwordUnlock')}
            hasFeedback
            validateTrigger={'onSubmit'}
            rules={[{ required: true, message: `${t('policy.passwordUnlock')} ${t('policy.isRequired')}` }]}
          >
            <Select
              placeholder={t('policy.passwordUnlock')}
              width={200}
              onChange={(data: any) => setTypeUnlock(data)}
              disabled={!isEdit}
            >
              <Option value={'Admin Manual'}>{t('policy.adminManual')}</Option>
              <Option value={'User Manual'}>{t('policy.userManual')}</Option>
              <Option value={'After {X} Minutes'}>{t('policy.after{X}minute(s)')}</Option>
              <Option value={'After {X} Minutes;Admin Manual'}>
                {t('policy.after{X}minute(s)')} + {t('policy.adminManual')}
              </Option>
            </Select>
          </BaseButtonsForm.Item>
        </Col>
        {(typeValidatorMono(typeUnlock) || typeValidatorDual(typeUnlock)) && (
          <Col span={2}>
            <BaseButtonsForm.Item
              name="minutes"
              label={t('policy.minutes')}
              hasFeedback
              validateTrigger={'onSubmit'}
              rules={[
                { required: true, message: `${t('policy.minutes')} ${t('policy.isRequired')}` },
                () => ({
                  validator(rules, value) {
                    if (value >= 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error(t('validator.notAllowNegativeNumbers')));
                    }
                  },
                }),
                { pattern: /^\d+$/, message: t('validator.onlyInteger') },
              ]}
            >
              <InputNumber
                placeholder={t('policy.minutes')}
                onChange={(data: any) => setMinutes(data)}
                disabled={!isEdit}
              />
            </BaseButtonsForm.Item>
          </Col>
        )}
        <Col span={4}>
          <BaseButtonsForm.Item
            name="pw_reset"
            label={t('policy.passwordReset')}
            hasFeedback
            validateTrigger={'onSubmit'}
            rules={[{ required: true, message: `${t('policy.passwordReset')} ${t('policy.isRequired')}` }]}
          >
            <Select placeholder={t('policy.passwordReset')} width={200} disabled={!isEdit}>
              <Option value={'Admin Manual'}>{t('policy.adminManual')}</Option>
              <Option value={'User Manual'}>{t('policy.userManual')}</Option>
            </Select>
          </BaseButtonsForm.Item>
        </Col>
        <Col span={6}>
          <BaseButtonsForm.Item
            name="mixed_numbers"
            label={t('policy.mixNumbers')}
            hasFeedback
            validateTrigger={'onSubmit'}
            rules={[
              { required: true, message: `${t('policy.mixNumbers')} ${t('policy.isRequired')}` },
              () => ({
                validator(rules, value) {
                  if (value >= 0) {
                    if (maximum && value > maximum) {
                      return Promise.reject(
                        new Error(`${t('validator.noBiggerThan')} ${t('policy.maximumNumberOfCharacters')}`),
                      );
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.reject(new Error(t('validator.notAllowNegativeNumbers')));
                  }
                },
              }),
              { pattern: /^\d+$/, message: t('validator.onlyInteger') },
            ]}
          >
            <InputNumber
              placeholder={t('policy.mixNumbers')}
              onChange={(data: any) => setMixNumber(data)}
              disabled={!isEdit}
            />
          </BaseButtonsForm.Item>
        </Col>
        <Col span={6}>
          <BaseButtonsForm.Item
            name="mixed_special_characters"
            label={t('policy.mixedSpecialCharacters')}
            hasFeedback
            validateTrigger={'onSubmit'}
            rules={[
              { required: true, message: `${t('policy.mixedSpecialCharacters')} ${t('policy.isRequired')}` },
              () => ({
                validator(rules, value) {
                  if (value >= 0) {
                    if (maximum && value > maximum) {
                      return Promise.reject(
                        new Error(`${t('validator.noBiggerThan')} ${t('policy.maximumNumberOfCharacters')}`),
                      );
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.reject(new Error(t('validator.notAllowNegativeNumbers')));
                  }
                },
              }),
              { pattern: /^\d+$/, message: t('validator.onlyInteger') },
            ]}
          >
            <InputNumber
              placeholder={t('policy.mixedSpecialCharacters')}
              onChange={(data: any) => setMixSpecial(data)}
              disabled={!isEdit}
            />
          </BaseButtonsForm.Item>
        </Col>
        <Col span={6}>
          <BaseButtonsForm.Item
            name="mixed_lowercase"
            label={t('policy.mixLowercase')}
            hasFeedback
            validateTrigger={'onSubmit'}
            rules={[
              { required: true, message: `${t('policy.mixLowercase')} ${t('policy.isRequired')}` },
              () => ({
                validator(rules, value) {
                  if (value >= 0) {
                    if (maximum && value > maximum) {
                      return Promise.reject(
                        new Error(`${t('validator.noBiggerThan')} ${t('policy.maximumNumberOfCharacters')}`),
                      );
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.reject(new Error(t('validator.notAllowNegativeNumbers')));
                  }
                },
              }),
              { pattern: /^\d+$/, message: t('validator.onlyInteger') },
            ]}
          >
            <InputNumber
              placeholder={t('policy.mixLowercase')}
              onChange={(data: any) => setMixLowercase(data)}
              disabled={!isEdit}
            />
          </BaseButtonsForm.Item>
        </Col>
        <Col span={6}>
          <BaseButtonsForm.Item
            name="mixed_uppercase"
            label={t('policy.mixUppercase')}
            hasFeedback
            validateTrigger={'onSubmit'}
            rules={[
              { required: true, message: `${t('policy.mixUppercase')} ${t('policy.isRequired')}` },
              () => ({
                validator(rules, value) {
                  if (value >= 0) {
                    if (maximum && value > maximum) {
                      return Promise.reject(
                        new Error(`${t('validator.noBiggerThan')} ${t('policy.maximumNumberOfCharacters')}`),
                      );
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.reject(new Error(t('validator.notAllowNegativeNumbers')));
                  }
                },
              }),
              { pattern: /^\d+$/, message: t('validator.onlyInteger') },
            ]}
          >
            <InputNumber
              placeholder={t('policy.mixUppercase')}
              onChange={(data: any) => setMixUppercase(data)}
              disabled={!isEdit}
            />
          </BaseButtonsForm.Item>
        </Col>
        <Col span={12}>
          <BaseButtonsForm.Item
            name="pw_validity_period"
            label={t('policy.passwordValidityPeriod')}
            hasFeedback
            validateTrigger={'onSubmit'}
            rules={[
              { required: true, message: `${t('policy.passwordValidityPeriod')} ${t('policy.isRequired')}` },
              () => ({
                validator(rules, value) {
                  if (value >= 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error(t('validator.notAllowNegativeNumbers')));
                  }
                },
              }),
              { pattern: /^\d+$/, message: t('validator.onlyInteger') },
            ]}
          >
            <InputNumber placeholder={t('policy.passwordValidityPeriod')} disabled={!isEdit} />
          </BaseButtonsForm.Item>
        </Col>
        <Col span={12}>
          <BaseButtonsForm.Item
            name="prohibition_of_reuse_of_pw"
            label={t('policy.prohibitionOfReuseOfPassword')}
            hasFeedback
            validateTrigger={'onSubmit'}
            rules={[
              { required: true, message: `${t('policy.prohibitionOfReuseOfPassword')} ${t('policy.isRequired')}` },
              () => ({
                validator(_, value: any) {
                  if (value >= 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error(t('validator.notAllowNegativeNumbers')));
                  }
                },
              }),
              () => ({
                validator(_, value: any) {
                  if (4 <= value && value <= 10) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t('validator.numberMustInRange', {
                        from: '4',
                        to: '10',
                      }),
                    ),
                  );
                },
              }),
              { pattern: /^\d+$/, message: t('validator.onlyInteger') },
            ]}
          >
            <InputNumber placeholder={t('policy.prohibitionOfReuseOfPassword')} disabled={!isEdit} />
          </BaseButtonsForm.Item>
        </Col>
        <Col span={12}>
          <BaseButtonsForm.Item
            name="maximum_number_of_characters"
            label={t('policy.maximumNumberOfCharacters')}
            hasFeedback
            validateTrigger={'onSubmit'}
            rules={[
              { required: true, message: `${t('policy.maximumNumberOfCharacters')} ${t('policy.isRequired')}` },
              () => ({
                validator(_, value) {
                  if (value < 0) {
                    return Promise.reject(new Error(t('validator.notAllowNegativeNumbers')));
                  }
                  if (value < Number(mixNumber) + Number(mixSpecial) + Number(mixLowercase) + Number(mixUppercase)) {
                    return Promise.reject(new Error(t('validator.sumMixNotLessThanMaximum')));
                  }
                  if (minimum && value < minimum) {
                    return Promise.reject(new Error(`${t('validator.numberNoLessThan', { number: minimum })}`));
                  }
                  return Promise.resolve();
                },
              }),
              { pattern: /^\d+$/, message: t('validator.onlyInteger') },
            ]}
          >
            <InputNumber
              placeholder={t('policy.maximumNumberOfCharacters')}
              onChange={(data: any) => setMaximum(data)}
              disabled={!isEdit}
            />
          </BaseButtonsForm.Item>
        </Col>
        <Col span={12}>
          <BaseButtonsForm.Item
            name="minimum_number_of_characters"
            label={t('policy.minimumNumberOfCharacters')}
            hasFeedback
            validateTrigger={'onSubmit'}
            rules={[
              { required: true, message: `${t('policy.minimumNumberOfCharacters')} ${t('policy.isRequired')}` },
              () => ({
                validator(_, value) {
                  if (value < 0) {
                    return Promise.reject(new Error(t('validator.notAllowNegativeNumbers')));
                  }
                  if (maximum && value > Number(maximum)) {
                    return Promise.reject(
                      new Error(`${t('validator.noBiggerThan')} ${t('policy.maximumNumberOfCharacters')}`),
                    );
                  }
                  if (value < 8) {
                    return Promise.reject(new Error(`${t('validator.numberNoLessThan', { number: 8 })}`));
                  }
                  return Promise.resolve();
                },
              }),
              { pattern: /^\d+$/, message: t('validator.onlyInteger') },
            ]}
          >
            <InputNumber
              placeholder={t('policy.minimumNumberOfCharacters')}
              onChange={(data: any) => setMinimum(data)}
              disabled={!isEdit}
            />
          </BaseButtonsForm.Item>
        </Col>
        <Col span={24}>
          {isEdit && (
            <BaseButtonsForm.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {t('button.save')} <SaveOutlined />
              </Button>
            </BaseButtonsForm.Item>
          )}
        </Col>
      </Row>
    </BaseButtonsForm>
  ) : (
    <div></div>
  );
};
