/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@app/components/common/buttons/Button/Button';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table } from 'components/common/Table/Table';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { ColumnsType } from 'antd/es/table';
import { Empty, notification } from 'antd';
import { getExternalLink, postExternalLink, patchExternalLink, deleteExternalLink } from '@app/api/dashboard.api';
import { Modal, ConfirmModal } from '@app/components/common/Modal/Modal';
import { useParams, useLocation } from 'react-router-dom';
import { readProfile } from '@app/services/localStorage.service';

import { Col, Row } from 'antd';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Pagination } from 'api/table.api';

export const initValues: any = {
  name: '',
  url: '',
  description: '',
};

export const ExternalLinkPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [exLinkForm] = BaseForm.useForm();
  const idUser = id || JSON.parse(readProfile())?.user?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [initData, setInitData] = useState(initValues);
  const location = useLocation();

  const [page, setPage] = useState(1);
  const limit = location.pathname.indexOf('/members') >= 0 || location.pathname.indexOf('/profile') >= 0 ? 10 : 3;

  const initialPagination: Pagination = {
    current: page,
    pageSize: limit,
    total: 0,
  };

  const [tableData, setTableData] = useState<{ data: any[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const handleTableChange = (pagination: any) => {
    setPage(pagination.current);
  };

  const onEdit = (data: any) => {
    setInitData(data);
    setIsVisible(true);
  };

  const onDelete = (data: any) => {
    ConfirmModal({
      title: t('common.titleConfirmation'),
      content: (
        <>
          {t('domain.areYouSureWantToDeleteExternalLink')} <strong>{data?.name}</strong>?
        </>
      ),
      onOk: () => handleDelete(data),
      okText: t('button.yes'),
      cancelText: t('button.no'),
    });
  };

  const handleDelete = (data: any) => {
    deleteExternalLink(idUser, data.id)
      .then((res: any) => {
        if (res?.status == 'success') {
          notification.success({ message: res.data });
          handleAfterSubmit();
        }
      })
      .catch((e: any) => notification.error({ message: e.message }));
  };

  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (user && !user?.isPasswordReset) {
      onGetExternalLink(page, limit);
    }
  }, [user?.isPasswordReset, page]);

  const columns: ColumnsType<any> = [
    {
      title: t('dashboard.name'),
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: false,
      render: (text, props) => {
        return <span>{props.name}</span>;
      },
    },
    {
      title: t('dashboard.url'),
      dataIndex: 'url',
      showSorterTooltip: false,
      render: (text, props) => (
        <>
          <a href={props.url} target="_blank">
            {props.url}
          </a>
        </>
      ),
    },
    {
      title: t('dashboard.action'),
      dataIndex: 'action',
      className: 'action-column',
      fixed: 'right',
      width: '120px',
      render: (text, props) => (
        <>
          <EditOutlined className="btn-icon-save" onClick={() => onEdit(props)} />
          <DeleteOutlined className="btn-icon-close ml-1" onClick={() => onDelete(props)} />
        </>
      ),
    },
  ];

  const onOpenFormModal = () => {
    setInitData(initValues);
    exLinkForm.setFieldsValue(initValues);
    setIsVisible(true);
  };

  const handleAfterSubmit = () => {
    onGetExternalLink(page, limit);
  };

  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    await (initData.id ? patchExternalLink(idUser, initData.id, data) : postExternalLink(idUser, data))
      .then((res: any) => {
        if (res?.status == 'success') {
          handleAfterSubmit();
          notification.success({ message: res.data });
        }
      })
      .catch((e: any) => notification.error({ message: e.message }));
    setIsVisible(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (initData) {
      exLinkForm.setFieldsValue(initData);
    }
  }, [initData]);

  const onGetExternalLink = (page: number, pageSize: number) => {
    getExternalLink(idUser, {
      page: page,
      limit: pageSize,
    })
      .then((res: any) => {
        if (res?.statusCode == 200) {
          setTableData({
            ...tableData,
            data: res.data.data,
            pagination: {
              ...tableData.pagination,
              current: page,
              total: res.data.total,
            },
          });
        }
      })
      .catch((e: any) => {});
  };

  return (
    <>
      <div className="mb-2">
        <Button type="primary" onClick={() => onOpenFormModal()}>
          {t('button.addExternalLink')} <PlusOutlined />
        </Button>
      </div>
      <Table
        columns={columns}
        scroll={{ x: 400 }}
        dataSource={tableData.data}
        pagination={{
          ...tableData.pagination,
          locale: { items_per_page: t('common.perPage') },
          position: ['topRight'],
        }}
        onChange={handleTableChange}
        loading={tableData.loading}
        bordered
      />
      {isVisible && (
        <Modal
          title={initData?.id ? t('dashboard.editExternalLink') : t('dashboard.createExternalLink')}
          visible={isVisible}
          cancelText={t('button.cancel')}
          okText={t('button.save')}
          onCancel={() => setIsVisible(false)}
          confirmLoading={isLoading}
          okButtonProps={{
            type: 'primary',
            onClick: () => {
              exLinkForm.submit();
            },
          }}
        >
          <Auth.FormWrapper className="p-05">
            <BaseForm
              confirmSubmitProps={{ active: true }}
              layout="vertical"
              form={exLinkForm}
              onFinish={handleSubmit}
              requiredMark="optional"
              initialValues={initData}
            >
              <Row gutter={[10, 10]}>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="name"
                    label={t('dashboard.name')}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      { max: 100, message: t('validator.maxLength', { count: 100 }) },
                      {
                        validator: (_, value) => {
                          return !(value.trim() === '')
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                    ]}
                  >
                    <Auth.FormInput placeholder={t('dashboard.name')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={24}>
                  <Auth.FormItem
                    name="url"
                    label={t('dashboard.url')}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      {
                        pattern:
                          /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
                        message: t('validator.allowOnlyHttps'),
                      },
                      { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      {
                        validator: (_, value) =>
                          !value.includes(' ')
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('validator.allowOnlyHttps'))),
                      },
                    ]}
                  >
                    <Auth.FormInput placeholder={t('dashboard.url')} />
                  </Auth.FormItem>
                </Col>
              </Row>
            </BaseForm>
          </Auth.FormWrapper>
        </Modal>
      )}
    </>
  );
};
