/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-rest-params */
import React, { useEffect } from 'react';
import { ConfigProvider, notification } from 'antd';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import { Helmet } from 'react-helmet';

if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  //@ts-ignore
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.warn('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
     //@ts-ignore
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
   //@ts-ignore
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.warn(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        );
      }
      return newNode;
    }
     //@ts-ignore
    return originalInsertBefore.apply(this, arguments);
  };
}


const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);
  usePWA();

  notification.config({
    maxCount: 3,
  });

  useAutoNightMode();
  // TODO: embed script chatbot
  // useEffect(() => {
  //   if (window && document) {
  //     const script = document.createElement('script');
  //     const body = document.head || document.getElementsByTagName('head')[0];
  //     script.src = 'https://web-chat.smoothandbot.com/';
  //     body.appendChild(script);
  //     script.addEventListener('load', () => {
  //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //       //@ts-ignore
  //       window?.WebChat?.default(
  //         {
  //           projectId: 'P7QnY4FDc4bvDcrJY',
  //           endpointGraphql: 'https://chatbot.smoothandbot.com/graphql',
  //           socketUrl: 'https://bot-p7qny4fdc4bvdcrjy.smoothandbot.com/',
  //           params: { storage: 'session' },
  //         },
  //         null,
  //       );
  //     });
  //   }
  // }, []);
  useThemeWatcher();

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <Helmet></Helmet>
      <GlobalStyle />
      <ConfigProvider locale={language === 'en' ? enUS : deDe}>
        <AppRouter />
      </ConfigProvider>
    </>
  );
};

export default App;
