import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicTable } from './BasicTable';
import { debounce } from 'lodash';
import { FileDoneOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as S from 'components/tables/Tables/Tables.styles';

interface RoleListProps {
  dataList: any;
  changePagination: (data: any) => void;
  changeKeyword: (keyword: any) => void;
  handleAfterSubmit: () => void;
}

export const RoleTable: React.FC<RoleListProps> = ({
  dataList,
  changePagination,
  changeKeyword,
  handleAfterSubmit,
}) => {
  const { t } = useTranslation();

  const debounceFn = useCallback(
    debounce((data: any) => {
      changeKeyword(data);
    }, 1000),
    [],
  );

  const onChangeSearch = (data: any) => {
    debounceFn(data.target.value);
  };

  return (
    <S.Card
      id="basic-table"
      title={
        <>
          <SafetyCertificateOutlined /> &nbsp; {t('role.listRoles')} &nbsp;
          <span className="count-record-table">
            ({dataList.total} {t('members.records')})
          </span>
        </>
      }
      padding="1.5rem"
    >
      <div className="d-flex-end mb-2">
        <SearchInput
          className="right-search-input"
          placeholder={t('role.searchRole')}
          allowClear
          onChange={(data) => onChangeSearch(data)}
        />
      </div>
      <BasicTable
        dataList={dataList}
        changePagination={(data) => changePagination(data)}
        handleAfterSubmit={handleAfterSubmit}
      />
    </S.Card>
  );
};
