import { Upload as AntdUpload, Button, notification } from 'antd';
import * as S from './Upload.styles';
import { UploadFile, UploadProps } from 'antd/lib/upload';
import { DeleteOutlined, DownloadOutlined, PaperClipOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { useRef, useState } from 'react';

const { Dragger } = AntdUpload;

interface IListItemProps {
  file: UploadFile;
  actions: {
    download(): void;
    preview(): void;
    remove(): void;
  };
  head: { label: string; key: string }[];
}

const CListItem: React.FC<IListItemProps> = ({ file, actions, head: headers }) => {
  const refCsvLink = useRef<any>();
  const [dataExport, setDataExport] = useState<any>([]);
  const downloadError = async () => {
    try {
      let data = file?.response?.data.extends;
      console.log(data);
      data =
        !!data && Array.isArray(data)
          ? data.map(({ errors, ...member }: any) => ({
              errors: errors?.join('; '),
              ...member,
            }))
          : [];
      if (data && Array.isArray(data)) {
        await setDataExport(data);
      }
      refCsvLink?.current?.link?.click();
    } catch (e: any) {
      notification.error({ message: e.message });
    }
  };

  return (
    <div className="ant-upload-list-item ant-upload-list-item-error ant-upload-list-item-list-type-text">
      <div className="ant-upload-list-item-info">
        <span className="ant-upload-span">
          <div className="ant-upload-text-icon">
            <PaperClipOutlined />
          </div>
          <span className="ant-upload-list-item-name" title={file.name}>
            {file.name}
          </span>
          {file.type === 'text/csv' && (
            <span className="ant-upload-list-item-card-actions">
              <Button
                type="text"
                size="small"
                title="Download file"
                icon={<DownloadOutlined />}
                className="ant-btn-icon-only ant-upload-list-item-card-actions-btn"
                onClick={() => downloadError()}
              />
              <CSVLink
                data={dataExport}
                headers={headers}
                target="_blank"
                filename={`import_error_${Date.now()}.csv`}
                ref={refCsvLink}
              />
            </span>
          )}
          <span className="ant-upload-list-item-card-actions">
            <Button
              type="text"
              size="small"
              title="Remove file"
              icon={<DeleteOutlined />}
              className="ant-btn-icon-only ant-upload-list-item-card-actions-btn"
              onClick={() => actions.remove()}
            />
          </span>
        </span>
      </div>
    </div>
  );
};

interface IUploadProps extends UploadProps {
  head?: { label: string; key: string }[];
  typeFileAccept?: string[];
  deniedMessage?: string;
}

export const UploadDragger: React.FC<IUploadProps> = (props) => {
  return (
    <div
      onDrop={(event) => {
        if (!props.accept || !props.deniedMessage || !event.dataTransfer.files[0]) return;
        if (!props.accept.includes(event.dataTransfer.files[0].type) && props.deniedMessage) {
          notification.error({ message: props.deniedMessage });
        }
      }}
    >
      <Dragger
        {...props}
        itemRender={(originNode, file, fileList, actions) =>
          file.status === 'error' && props.head ? (
            <CListItem file={file} actions={actions} head={props.head} />
          ) : (
            originNode
          )
        }
      ></Dragger>
    </div>
  );
};

export const Upload: React.FC<UploadProps> = (props) => {
  return <S.Upload {...props} />;
};
