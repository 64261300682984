import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Typography, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { getConversation } from '@app/api/member.api';
import { Loading } from '@app/components/common/Loading';
import { CheckCircleTwoTone, StopTwoTone } from '@ant-design/icons';
import { logout } from '@app/services/localStorage.service';

export const LockForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const onLogout = async () => {
    await logout().finally(() => setTimeout(() => navigate('/login')));
  };

  const onGetConversation = (projectId: any, conversationId: any) => {
    if (projectId && conversationId) {
      localStorage.removeItem('fallback');
      console.log('remove fallback')
      setIsLoading(true);
      getConversation({ projectId, conversationId })
        .then((res: any) => {
          if (res?.statusCode === 200) {
            setReady(true);
            setUrl(res.data.url);
            localStorage.removeItem('fallback');
          }
        })
        .catch((e: any) => {
          setErrorMsg(e.message);
          notification.error({ message: e.message });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!!ready && !!url) {
      window.location.href = url;
    }
  }, [ready, url]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const projectId = query.get('projectId');
    const conversationId = query.get('conversationId');
    onGetConversation(projectId, conversationId);
  }, []);

  return (
    <Auth.FormWrapper>
      <Auth.FormTitle>{t('common.goToConversation')}</Auth.FormTitle>
      {isLoading ? (
        <Loading />
      ) : (
        <Col className="mb-2">
          <Row justify={'center'} className="mb-2">
            <Col>
              {!ready ? (
                <StopTwoTone style={{ fontSize: 150 }} twoToneColor="#EE4B2B" />
              ) : (
                <CheckCircleTwoTone style={{ fontSize: 150 }} twoToneColor="#52c41a" />
              )}
            </Col>
          </Row>
          <Row justify={'center'}>
            <Col>
              {!ready ? (
                <Typography.Text>{errorMsg}</Typography.Text>
              ) : (
                <Typography.Text>{t('common.redirecting')}...</Typography.Text>
              )}
            </Col>
          </Row>
        </Col>
      )}
      <Row>
        <a onClick={onLogout}>{t('common.logout')}</a>
      </Row>
    </Auth.FormWrapper>
  );
};
