import { callApi, METHOD, IApiResponse, ApiErrorData } from '@app/api/http.api';
import { persistToken, persistExpiresIn, persistExpiresDate, persistProfile } from '@app/services/localStorage.service';
import { setUser } from '@app/store/slices/userSlice';
import { store } from '@app/store/store';
import { notification } from 'antd';
import i18n from 'i18next';

export interface LoginRequest {
  email?: string;
  password?: string;
  passcode?: string;
}

export interface IForgotPasswordRequest {
  email: string;
}
export interface IForgotPasswordChangeRequest extends IForgotPasswordRequest {
  otp: number;
  password: string;
  confirmPassword: string;
}

export type IUnlockAccountRequest = IForgotPasswordRequest;

export interface IUnlockAccountConfirmRequest {
  email: string | null;
  otp: string | number | null;
}

export const postLogin = async (payload: LoginRequest, action: any): Promise<any> => {
  try {
    let response: any = await callApi(METHOD.POST, `auth/login/${action}`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    if (response?.data?.expiresIn) {
      persistExpiresIn(response.data.expiresIn);
      const now = new Date(new Date().getTime() + response.data.expiresIn);
      persistExpiresDate(now.toLocaleString());
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};
export const getNewHmac = async (payload: any): Promise<any> => {
  try {
    let response: any = await callApi(METHOD.POST, 'auth/get-new-HMAC', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const submitForgotHMAC = async (payload: any): Promise<any> => {
  try {
    let response: any = await callApi(METHOD.POST, 'auth/submit-forgot-HMAC', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const postLogout = async () => {
  try {
    const response: any = await callApi(METHOD.POST, 'auth/logout', null);
    if (response?.data?.status !== 'success') notification.error({ message: i18n.t('message.error500') });
    else notification.success({ message: i18n.t('message.logoutSuccess') });
    return response?.data?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getProfile = async () => {
  try {
    const response: any = await callApi(METHOD.GET, 'me/profile', null);
    persistProfile(response?.data?.data);
    store.dispatch(setUser(response?.data?.data?.user));
    return response?.data?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};

export const postChangePassword = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.PUT, 'members/password', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success')
      notification.error({
        message: response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'),
      });
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};

export const putVerifyEmail = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.PATCH, 'members/verify', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success')
      notification.error({
        message: response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'),
      });
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getVerifyEmail = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.GET, 'members/verify/resend', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success')
      notification.error({
        message: response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'),
      });
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};

export const postForgotPassword = async (payload: IForgotPasswordRequest): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.POST, 'auth/forgot-password', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
  } catch (e: any) {
    throw e;
  }
};
export const postForgotHMAC = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.POST, 'auth/forgot-HMAC', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const patchForgotPasswordChange = async (
  payload: IForgotPasswordChangeRequest,
): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.PATCH, 'auth/forgot-password/change', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const postUnlockAccount = async (payload: IUnlockAccountRequest): Promise<{ data: any } | undefined> => {
  try {
    let response: any = await callApi(METHOD.POST, 'auth/unlock', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const patchUnlockAccountConfirm = async (
  payload: IUnlockAccountConfirmRequest,
): Promise<{ data: any } | undefined> => {
  try {
    let response: any = await callApi(METHOD.PATCH, 'auth/unlock/confirm', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
  } catch (e: any) {
    throw e;
  }
};
