import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';
import { setDirty } from '@app/store/dirty/dirty';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Menu, Tooltip } from 'antd';
import history from '@app/components/router/history';
import { ConfirmModal } from '@app/components/common/Modal/Modal';

interface SiderContentProps {
  toggleSider: () => void;
  isCollapsed: boolean;
}
const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

const SiderMenu: React.FC<SiderContentProps> = ({ toggleSider, isCollapsed }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const dirty = useAppSelector((state) => state.dirty.dirty);

  const [selectedKeys, setSelectedKeys] = React.useState<any>([]);

  React.useEffect(() => {
    const currentMenuItem = sidebarNavFlat.find(({ url }) => url && url !== '/' && location.pathname.includes(url));
    const defaultSelectedKeys = currentMenuItem
      ? [currentMenuItem.key]
      : location.pathname === '/'
      ? ['dashboard']
      : [];
    setSelectedKeys(defaultSelectedKeys);
  }, [location.pathname]);

  React.useEffect(() => {
    const backListeners = history.listen(({ action, location }) => {
      if (action === 'POP') {
        dispatch(setDirty(false));
      }
    });
    return () => {
      backListeners();
    };
  }, []);

  const openedSubmenu = sidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname),
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  const checkDirtyBeforeRedirect = (url: string) => {
    if (dirty) {
      const handleDirty = () => {
        navigate(url);
        dispatch(setDirty(false));
      };
      ConfirmModal({
        title: t('common.titleConfirmation'),
        content: t('message.changesMayNotHaveBeenSaved'),
        onOk: handleDirty,
        cancelText: t('button.no'),
        okText: t('button.yes'),
      });
    } else {
      navigate(url);
    }
  };

  return (
    <S.Menu mode="inline" selectedKeys={selectedKeys} defaultOpenKeys={defaultOpenKeys}>
      {sidebarNavigation.map((nav) =>
        nav.children && nav.children.length > 0 ? (
          <Menu.SubMenu
            key={nav.key}
            title={t(nav.title)}
            icon={nav.icon}
            // onTitleClick={() => toggleSider()}
            popupClassName="d-none"
          >
            {nav.children.map((childNav) => (
              <Menu.Item key={childNav.key} title="" onClick={() => checkDirtyBeforeRedirect(childNav.url || '')}>
                <div>{t(childNav.title)}</div>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        ) : (
          <Menu.Item
            key={nav.key}
            title=""
            icon={
              isCollapsed ? (
                <Tooltip placement="right" title={t(nav.title)}>
                  {nav.icon}
                </Tooltip>
              ) : (
                nav.icon
              )
            }
            onClick={() => checkDirtyBeforeRedirect(nav.url || '')}
          >
            <div>{t(nav.title)}</div>
          </Menu.Item>
        ),
      )}
    </S.Menu>
  );
};

export default SiderMenu;
