import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChooseMethod } from '@app/components/members/chooseMethod/ChooseMethod';
import { AddSingleUserForm } from '@app/components/members/chooseMethod/AddSingleUserForm';
import { AddMultiUserForm } from '@app/components/members/chooseMethod/AddMultiUserForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Steps, Step } from '@app/components/common/Steps/Steps';
import * as S from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import styled from 'styled-components';
import { postMember } from '@app/api/member.api';
import { message, notification } from 'antd';

const AddMemberPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const submitForm = (data: any) => {
    setIsLoading(true);
    const payload = {
      ...data.data,
      email: data.data.email,
      password: data.data.password,
      fullName: data.data.fullName,
      roleIds: data.roleIds,
    };
    postMember(payload)
      .then((res: any) => {
        if (res && res?.status == 'success') {
          notification.success({ message: res.data });
          navigate('/members');
        } else {
          setIsLoading(false);
        }
      })
      .catch((e: any) => {
        notification.error({ message: e.message });
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageTitle>{t('members.registration')}</PageTitle>
      <>
        {location.pathname == '/members/add-user' ? (
          <AddSingleUserForm
            submit={(data: any) => {
              submitForm(data);
            }}
            isLoading={isLoading}
          />
        ) : (
          <AddMultiUserForm
            submit={(data: any) => {
              submitForm(data);
            }}
          />
        )}
      </>
    </>
  );
};

export default AddMemberPage;
