import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token);
  const location = useLocation();

  useEffect(() => {
    const fallback = location.pathname.includes('get-conversation');
    if (!!fallback) {
      localStorage.setItem('fallback', encodeURIComponent(location.pathname + location.search));
    }else{
      localStorage.removeItem('fallback');
    }
  }, [location.pathname]);
  return token ? <>{children}</> : <Navigate to={`/login`} replace />;
};

export default RequireAuth;
