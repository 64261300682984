import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { RoleTable } from './roleTable/RoleTable';
import { Card } from 'components/common/Card/Card';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Button } from '@app/components/common/buttons/Button/Button';
import { getRoleList } from '@app/api/role.api';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';

const RoleListPage: React.FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [dataList, setDataList] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    onSearchRoles(1, limit, search);
  }, []);

  const onSearchRoles = (_page: number, _limit: number, _search: string) => {
    const payload = {
      page: _page,
      limit: _limit,
      search: _search,
    };
    getRoleList(payload)
      .then((res: any) => {
        if (res?.status == 'success') {
          setDataList(res.data);
        } else {
          setDataList({});
        }
      })
      .catch((e) => notification.error({ message: e.message }));
  };

  const changePagination = (paging: any) => {
    setPage(paging.current);
    setLimit(paging.pageSize);
    onSearchRoles(paging.current, paging.pageSize, search);
  };

  const changeKeyword = (search: any) => {
    setPage(1);
    setSearch(search);
    onSearchRoles(1, limit, search);
  };

  return (
    <>
      <PageTitle>{t('common.role')}</PageTitle>
      <Row gutter={[30, 30]}>
        <Col xs={24} lg={24}>
          <Card padding="1rem">
            <Button
              className="d-flex btn-add-role"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                navigate('/role/create');
              }}
            >
              {t('button.addRole')}
            </Button>
          </Card>
        </Col>
      </Row>
      <br />
      <RoleTable
        dataList={dataList}
        changePagination={(data) => changePagination(data)}
        changeKeyword={(data) => changeKeyword(data)}
        handleAfterSubmit={() => onSearchRoles(page, limit, search)}
      />
    </>
  );
};

export default RoleListPage;
