import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Radio, Tooltip, Space, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Button } from '@app/components/common/buttons/Button/Button';
import { RadioBtn } from '@app/components/header/components/settingsDropdown/settingsOverlay/SettingsOverlay/SettingsOverlay.styles';
import { KeyOutlined, CameraOutlined, SaveOutlined } from '@ant-design/icons';
import { blockMember, patchMember, uploadFile } from '@app/api/member.api';
import { getProfile } from '@app/api/login.api';
import { useLocation, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { Modal } from '@app/components/common/Modal/Modal';
import Cropper from './Cropper';
import * as S from './ProfileInfo.styles';
import defaultAvatar from '@app/assets/images/defaultAvatar.svg';

interface ProfileInfoProps {
  profileData: any;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const location = useLocation();
  const [isEditMember, setIsEditMember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState('');
  const [isActive, setIsActive] = useState(profileData?.isActive ? '1' : '0');
  const [imageUpload, setImageUpload] = useState('');
  const [imageCrop, setImageCrop] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    location.pathname.indexOf('/members') && setIsEditMember(true);
  }, [location.pathname]);

  useEffect(() => {
    profileData.avatarUrl && setImage(profileData.avatarUrl);
  }, [profileData]);

  const redirectToChangePassword = () => {
    navigate('/profile/new-password');
  };

  const onSwitchStatus = (data: any, payload: any) => {
    const active: boolean = data.target.value == '1';
    blockMember(payload)
      .then((res: any) => {
        if (res?.statusCode == 200) {
          setIsActive(active ? '1' : '0');
          notification.success({ message: t('message.updateSuccess') });
        }
      })
      .catch((e) => {
        setIsActive(active ? '0' : '1');
        notification.error({ message: e.message });
      });
  };

  const onSubmitCrop = async () => {
    setIsLoading(true);
    try {
      const base64Response = await fetch(`${imageCrop}`);
      const blob = await base64Response.blob();
      await uploadFile(id, blob).then(async (resultUploadFile: any) => {
        if (resultUploadFile?.status == 'success') {
          await patchMember(profileData.id, { ...profileData, avatarUrl: resultUploadFile.data.url })
            .then(async (resultUpdateMember: any) => {
              if (resultUpdateMember?.status == 'success') {
                notification.success({ message: resultUpdateMember.data });
                await getProfile();
                if (imageCrop) setImage(imageCrop);
              }
            })
            .catch((e) => notification.error({ message: e.message }));
        } else {
          notification.error({ message: resultUploadFile.message ? resultUploadFile.message : t('message.error500') });
        }
      });
    } catch (error: any) {
      notification.error({ message: error?.message ? error.message : t('message.error500') });
    } finally {
      setIsVisible(false);
      setIsLoading(false);
      setImageCrop('');
    }
  };

  const onCancelCrop = () => {
    setImageCrop('');
    setIsVisible(false);
  };

  const onCrop = (data: any) => {
    setImageCrop(data);
  };

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          const image = JSON.stringify(reader?.result).replaceAll('"', '') || '';
          setImageUpload(image);
          setImageCrop(image);
          setIsVisible(true);
        },
        false,
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = '';
    }
  };

  return (
    profileData && (
      <S.Wrapper>
        <div className="container-button-profile">
          {isEditMember && (
            <>
              <Button
                size="large"
                noStyle
                type="text"
                icon={<KeyOutlined />}
                onClick={() => {
                  redirectToChangePassword();
                }}
              >
                {t('button.changePassword')}
              </Button>
            </>
          )}
        </div>
        <S.ImgWrapper>
          <Avatar shape="circle" src={image || defaultAvatar} alt="Profile" />
          {/* {isEditMember && ( */}
          <>
            <Button className="btn-upload-avatar">
              <CameraOutlined />
            </Button>
            <input className="avatar-upload-area" type="file" accept="image/png, image/jpeg" onChange={onSelectFile} />
          </>
          {/* )} */}
        </S.ImgWrapper>
        <Modal title={t('dashboard.cropImage')} visible={isVisible} footer={null} onCancel={() => setIsVisible(false)}>
          <Cropper
            src={imageUpload}
            onCrop={(data: any) => {
              onCrop(data);
            }}
          />
          <Button className="d-inline-flex mt-2" loading={isLoading} type="primary" onClick={onSubmitCrop}>
            {t('button.save')} <SaveOutlined className="mt-05" />
          </Button>
          <Button type="default" className="d-inline-flex ml-2 mt-2" onClick={() => onCancelCrop()}>
            {t('button.cancel')}
          </Button>
        </Modal>
        <S.Title>{profileData?.fullName || profileData[0]?.fullName}</S.Title>
        <S.Subtitle>{profileData?.email || profileData[0]?.email}</S.Subtitle>
        {!isEditMember && (typeof profileData?.isActive == 'boolean' || typeof profileData[0]?.isActive == 'boolean') && (
          <>
            <S.Text>{t('profile.status')}</S.Text>
            <Radio.Group
              className="mt-2 text-left"
              value={isActive}
              onChange={(data) => onSwitchStatus(data, profileData)}
            >
              <RadioBtn value="1">{t('dashboard.active')}</RadioBtn>
              <RadioBtn value="0">{t('dashboard.suspend')}</RadioBtn>
            </Radio.Group>
          </>
        )}
      </S.Wrapper>
    )
  );
};
