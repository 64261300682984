import React from 'react';
import { Row, Col } from 'antd';
import { LogsTable } from '@app/components/logs/logsTable/LogsTable';
import { Card } from '@app/components/common/Card/Card';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Button } from '@app/components/common/buttons/Button/Button';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const LogsSettingPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.logs')}</PageTitle>
      <LogsTable />
    </>
  );
};

export default LogsSettingPage;
