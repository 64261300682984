import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notification, Input, Button, Image, Space, Typography, Row } from 'antd';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { postLogin, LoginRequest, getProfile, postForgotHMAC, getNewHmac, submitForgotHMAC } from '@app/api/login.api';
import { setDirty } from '@app/store/dirty/dirty';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { setToken } from '@app/store/slices/authSlice';
import { ModalComponent } from '@app/components/modal';
import { CheckCircleOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { Modal } from '@app/components/common/Modal/Modal';
import * as Z from '../SecurityCodeForm/SecurityCodeForm.styles';

interface ILoginFormData {
  email: string;
  password: string;
}

export const initValues: ILoginFormData = {
  email: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);
  const [labelCheck, setLabelCheck] = useState(false);
  const [isOpenGoogleAuthenticator, setIsOpenGoogleAuthenticator] = useState<boolean>(false);
  const [loginRequest, setLoginRequest] = useState<LoginRequest>({});
  const [isOpenGoogleAuthenticatorSuccess, setIsOpenGoogleAuthenticatorSuccess] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<string>();
  const [accessToken, setAccessToken] = useState<string>();

  const handleSubmit = async (values: LoginRequest, isConfirmForward = false, action: any) => {
    if (labelCheck && !!isConfirmForward) {
      try {
        const result = await submitForgotHMAC({
          key: new URLSearchParams(location.search).get('resetToken'),
          passCode: loginRequest?.passcode,
        });
        if (result.status === 'success') {
          setIsOpenGoogleAuthenticator(false);
          setIsOpenGoogleAuthenticatorSuccess(true);
        }
      } catch (error: any) {
        notification.error({ message: error.message });
      } finally {
      }
    } else {
      setLoading(true);
      try {
        const resultLogin = await postLogin(values, action);

        if (resultLogin?.statusCode === 200 && resultLogin.data) {
          const { accessToken, qrCode } = resultLogin.data;
          if (qrCode) {
            notification.error({ message: t('common.notRegisterHMAC') });
            setLoginRequest(values);
            setQrCode(qrCode);
            setIsOpenGoogleAuthenticator(true);
            return;
          }

          if (isConfirmForward) {
            setAccessToken(accessToken);
            setIsOpenGoogleAuthenticator(false);
            return setIsOpenGoogleAuthenticatorSuccess(true);
          }

          if (accessToken) {
            await forwardDashboard(accessToken);
            navigate('/');
          }
        }
      } catch (e: any) {
        notification.error({ message: e.message });
      } finally {
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    const params = new URLSearchParams(location.search).get('resetToken');
    if (params) {
      getResetHmac(params);
    }
  }, [location.search]);

  const getResetHmac = (params: any) => {
    getNewHmac({
      key: params,
    })
      .then((result) => {
        if (result) {
          setQrCode(result?.data.qrCode);
          setLabelCheck(true);
          setIsOpenGoogleAuthenticator(true);
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => {
        setLoading(false);
      });
  };

  const forwardDashboard = (accessToken: string) => {
    dispatch(setToken(accessToken));
    dispatch(setDirty(false));

    notification.success({ message: t('message.loginSuccess') });
    return getProfile();
  };

  const handleClose = () => {
    setIsOpenGoogleAuthenticator(false);
    setLabelCheck(false);
  };

  const handleConfirmForWard = async () => {
    if (labelCheck) {
      setLabelCheck(false);
      setIsOpenGoogleAuthenticatorSuccess(false);
      const loginRequestClone = { ...loginRequest };
      delete loginRequestClone.passcode;
      setLoginRequest(loginRequestClone);
      return;
    }
    if (!accessToken) return;
    await forwardDashboard(accessToken);

    navigate('/');
  };

  const closeModal = () => {
    setLabelCheck(false);
  };

  return (
    <>
      <ModalComponent
        title={
          <Row justify={'center'}>
            <span style={{ color: '#4284f5' }}>{t('common.googleAuthentication')}</span>
          </Row>
        }
        isOpen={isOpenGoogleAuthenticatorSuccess}
      >
        <S.WrapGoogleAuthencatorSuccess>
          <Space className="text-content">
            <CheckSquareOutlined className="check-circle-outlined-icon" />
            <span>{t('common.successfullyActived')}</span>
          </Space>
          <Button style={{ width: '200px' }} onClick={handleConfirmForWard}>
            {t('button.done')}
          </Button>
        </S.WrapGoogleAuthencatorSuccess>
      </ModalComponent>
      <Auth.FormWrapper>
        <BaseForm
          layout="vertical"
          onFinish={(values) => {
            handleSubmit(values, false, null);
          }}
          requiredMark="optional"
          initialValues={initValues}
        >
          <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
          <Auth.FormItem
            name="email"
            label={t('common.email')}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                    ? Promise.resolve()
                    : Promise.reject(new Error(t('common.requiredField')));
                },
              },
              { max: 255, message: t('validator.maxLength', { count: 255 }) },
              {
                type: 'email',
                message: t('validator.notValidEmail'),
              },
            ]}
          >
            <Auth.FormInput placeholder={t('common.email')} />
          </Auth.FormItem>
          <Auth.FormItem
            label={t('common.password')}
            name="password"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                    ? Promise.resolve()
                    : Promise.reject(new Error(t('common.requiredField')));
                },
              },
            ]}
          >
            <Auth.FormInputPassword placeholder={t('common.password')} />
          </Auth.FormItem>
          <Auth.FormItem label={t('common.authPasscode')} name="passcode">
            <Auth.FormInput placeholder={t('common.passcode')} />
          </Auth.FormItem>
          {/* <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{t('common.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item>
        </Auth.ActionsWrapper> */}

          <BaseForm.Item noStyle>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.login')}
            </Auth.SubmitButton>
          </BaseForm.Item>
          <Auth.ActionsWrapper>
            <Link to="/forgot-password">
              <S.ForgotPasswordText>{t('common.forgotPassword')}</S.ForgotPasswordText>
            </Link>
            <Link to="/forgot-hmac">
              <S.ForgotPasswordText>{t('common.forgotHMAC')}</S.ForgotPasswordText>
            </Link>
            <Link to="/unlock">
              <S.ForgotPasswordText>{t('common.unlockAccount')}</S.ForgotPasswordText>
            </Link>
          </Auth.ActionsWrapper>

          <ModalComponent
            title={t('common.googleAuthentication')}
            isOpen={isOpenGoogleAuthenticator}
            afterClose={closeModal}
          >
            <S.WrapGoogleAuthencator>
              {qrCode && (
                <div className="wrap-label">
                  <span>{t('common.scanQRCode')}</span>
                  <Image src={qrCode} />
                  <span>{t('common.scanQRCodeContinue')}</span>
                </div>
              )}
              <Input
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  loginRequest && setLoginRequest({ ...loginRequest, passcode: e.target.value })
                }
                placeholder={t('common.passCode')}
              />
              <Space>
                <Button onClick={handleClose}>{t('button.cancel')}</Button>
                <Button type="primary" onClick={() => loginRequest && handleSubmit(loginRequest, true, 'active')}>
                  {t('button.sendConfirmationCode')}
                </Button>
              </Space>
            </S.WrapGoogleAuthencator>
          </ModalComponent>
        </BaseForm>
      </Auth.FormWrapper>
    </>
  );
};
