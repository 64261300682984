import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@app/components/common/buttons/Button/Button';
import { LoadingOutlined, SaveOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { ColumnsType } from 'antd/es/table';
import { Form, notification } from 'antd';
import {
  getAIChatbot,
  postAIChatbot,
  patchAIChatbot,
  deleteAIChatbot,
  getAIChatbotToken,
  saveLogChatbot,
} from '@app/api/dashboard.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { readProfile } from '@app/services/localStorage.service';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { Switch } from '@app/components/common/Switch/Switch';
import { Col, Row } from 'antd';
import { CountriesItem } from './CountriesItem/CountriesItem';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface AIChatProps {
  isCallOpenAdd: any;
  reloadDetail?: () => void;
}

export const initValues: any = {
  name: '',
  url: '',
  description: '',
};

const AIChatPage: React.FC<AIChatProps> = ({ isCallOpenAdd, reloadDetail }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [initData, setInitData] = useState(initValues);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(false);
  const [recordSelected, setRecordSelected] = useState({
    id: 0,
  });
  const [isLoadingSelect, setIsLoadingSelect] = useState(false);
  const [domainList, setDomainList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const [page, setPage] = useState(1);
  const [isFirstOpen, setIsFirstOpen] = useState(true);

  const { idDomain } = useParams();

  useEffect(() => {
    if (!isFirstOpen) {
      if (isCallOpenAdd != null) onOpenFormModal();
    } else {
      setIsFirstOpen(false);
    }
  }, [isCallOpenAdd]);

  const onEdit = (data: any) => {
    setInitData(data);
    setIsVisible(true);
  };

  const onDelete = (data: any) => {
    setRecordSelected(data);
    setVisiblePopconfirm(true);
  };

  const handleDelete = () => {
    deleteAIChatbot(recordSelected.id, idDomain)
      .then((res: any) => {
        if (res?.status == 'success') {
          notification.success({ message: res.data });
          onCancel();
          reloadDetail && reloadDetail();
          onGetAIChatbot(page);
        }
      })
      .catch((e: any) => notification.error({ message: e.message }));
  };

  const user = useAppSelector((state) => state.user.user);

  React.useEffect(() => {
    if (user && !user?.isPasswordReset) {
      onGetAIChatbot(page);
    }
  }, [user?.isPasswordReset]);

  useEffect(() => {
    setDomainList(JSON.parse(readProfile()).domains);
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: t('dashboard.name'),
      dataIndex: 'name',
      showSorterTooltip: false,
    },
    {
      title: t('dashboard.isActive'),
      dataIndex: 'isActive',
      showSorterTooltip: false,
      render: (text, props) => (
        <>
          <div className={`badge-${props.isActive ? 'success' : 'danger'}`}>
            {props.isActive ? t('dashboard.active') : t('dashboard.suspended')}
          </div>
        </>
      ),
    },
    {
      title: t('dashboard.action'),
      dataIndex: 'action',
      className: 'action-column',
      fixed: 'right',
      width: '120px',
      render: (text, props) => (
        <>
          <EditOutlined className="btn-icon-save" onClick={() => onEdit(props)} />
          <DeleteOutlined className="btn-icon-close ml-1" onClick={() => onDelete(props)} />
          <Popconfirm
            title={t('dashboard.areYouSureWantDeleteChatbot')}
            visible={visiblePopconfirm && recordSelected && recordSelected.id == props.id}
            onConfirm={handleDelete}
            onCancel={() => setVisiblePopconfirm(false)}
            okText={t('button.ok')}
            cancelText={t('button.cancel')}
          />
        </>
      ),
    },
  ];

  const onCancel = () => {
    form.resetFields();
    setIsVisible(false);
  };

  const onOpenFormModal = () => {
    setInitData(initValues);
    setIsVisible(true);
  };

  const handleSubmit = async (_data: any) => {
    setIsLoading(true);
    const data = {
      ..._data,
      status: !!_data.status,
      domainId: parseInt(idDomain || ''),
      language: _data.language.toLowerCase(),
    };
    await (initData.id ? patchAIChatbot(initData.id, idDomain, data) : postAIChatbot(data))
      .then((res: any) => {
        if (res?.status == 'success') {
          notification.success({ message: res.data });
          reloadDetail && reloadDetail();
          onGetAIChatbot(page);
        }
      })
      .catch((e: any) => notification.error({ message: e.message }))
      .finally(() => {
        onCancel();
        setIsLoading(false);
      });
  };

  const onGetAIChatbot = async (page: number) => {
    setPage(page);
    await getAIChatbot({
      page: page,
      limit: 9999,
    })
      .then((res: any) => {
        if (res?.statusCode == 200) {
          setData(res.data.data);
        }
      })
      .catch((e: any) => {
        if (idDomain) notification.error({ message: e.message });
      });
  };

  const changeDomain = (data: any) => {
    setIsLoadingSelect(true);
    getAIChatbotToken(data)
      .then((res: any) => {
        if (res?.statusCode == 200) {
          saveLogChatbot({ id: data, name: res?.data?.name });
          window.open(res.data.url, '_blank');
        }
      })
      .catch((e: any) => notification.error({ message: e.message }))
      .finally(() => {
        setIsLoadingSelect(false);
      });
  };
  useEffect(() => {
    if (initData) {
      form.setFieldsValue(initData);
    }
  }, [initData]);
  return (
    <>
      {!idDomain && (
        <>
          <Select
            loading={isLoadingSelect}
            placeholder={t('dashboard.chooseAIChat')}
            width={200}
            onChange={(data) => changeDomain(data)}
          >
            {data
              .filter((item: any) => item.isActive)
              .map((field: any) => (
                <Option value={field.id}>{field.name}</Option>
              ))}
          </Select>
        </>
      )}
      <Modal
        title={initData.id ? t('dashboard.editAIChatbot') : t('dashboard.createAIChatbot')}
        visible={isVisible}
        confirmLoading={isLoading}
        okButtonProps={{
          type: 'primary',
          onClick: () => {
            form.submit();
          },
        }}
        okText={t('button.save')}
        cancelText={t('button.cancel')}
        onCancel={() => onCancel()}
      >
        <Auth.FormWrapper className="p-05">
          <BaseForm
            confirmSubmitProps={{ active: true }}
            id="AIChatbot"
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initData}
          >
            <Row gutter={[10, 10]}>
              <Col xs={24} lg={12}>
                <Auth.FormItem
                  name="name"
                  label={t('dashboard.name')}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('common.requiredField')));
                      },
                    },
                    {
                      pattern: /^[a-z0-9]+$/i,
                      message: t('validator.onlyDecimalAndNumeric'),
                    },
                    // {
                    //   validator: async (rule, value) => {
                    //     const { isChatbot } = form.getFieldsValue(['isChatbot']);
                    //     console.log('isChatbot', isChatbot, initData);
                    //     if (isChatbot && !/^[a-z0-9]+$/i.test(value))
                    //       throw new Error(t('validator.onlyDecimalAndNumeric'));
                    //   },
                    // },
                  ]}
                >
                  <Auth.FormInput placeholder={t('dashboard.name')} />
                </Auth.FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <CountriesItem />
              </Col>
              <Col xs={24} lg={12}>
                <Auth.FormItem name="status" label={t('dashboard.isActive')}>
                  <Switch />
                </Auth.FormItem>
              </Col>
            </Row>
          </BaseForm>
        </Auth.FormWrapper>
      </Modal>
    </>
  );
};

export default AIChatPage;
