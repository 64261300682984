import React, { useEffect, useState } from 'react';
import { TablePaginationConfig } from 'antd';
import { Pagination } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { getDomain, deleteDomain, IDomain, IDomainsResponse, blockDomain } from '@app/api/domain.api';
import { notification } from 'antd';
import { getAIChatbotToken, saveLogChatbot, saveLogSmoothApp } from '@app/api/dashboard.api';

import { ModalComponent } from '@app/components/modal';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 20,
};

interface TableListProps {
  dataList?: IDomainsResponse;
  groupId: any;
  changePagination: (data: any) => void;
  handleAfterSubmit: () => void;
  onRowClick?: (_data: any, _index: number | undefined) => void;
}

export const BasicTable: React.FC<TableListProps> = ({
  dataList,
  groupId,
  changePagination,
  handleAfterSubmit,
  onRowClick,
  ...props
}) => {
  const [tableData, setTableData] = useState<{ data?: any[]; pagination?: any; loading?: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isOpenModalBlock, setIsOpenModalBlock] = useState<boolean>(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [domainSelect, setDomainSelect] = useState<IDomain>();

  useEffect(() => {
    setTableData({
      data: dataList?.data,
      pagination: {
        showSizeChanger: true,
        current: dataList?.page,
        total: dataList?.total,
      },
      loading: false,
    });
  }, [dataList]);

  const onSelectChatbot = (data: any) => {
    getAIChatbotToken(data)
      .then((res: any) => {
        if (res?.statusCode == 200) {
          saveLogChatbot({ id: data, name: res?.data?.name });
          window.open(res.data.url, '_blank');
        }
      })
      .catch((e: any) => notification.error({ message: e.message }));
  };

  const onSelectSmooth = (data: any) => {
    getDomain(data)
      .then((res: any) => {
        if (res?.statusCode == 200) {
          const domain = res?.data;
          saveLogSmoothApp({ ...domain.smooth, domain });
          window.open(res.data.smooth.url, '_blank');
        }
      })
      .catch((e: any) => notification.error({ message: e.message }));
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    changePagination(pagination);
  };

  const onEdit = ({ group, id }: IDomain) => {
    navigate(`/domain/edit/${group.id}/${id}`);
  };

  const onView = ({ group, id }: IDomain) => {
    navigate(`/domain/view/${group.id}/${id}`);
  };

  const columns: ColumnsType<IDomain> = [
    {
      title: t('domain.domainName'),
      dataIndex: 'domainName',
      showSorterTooltip: false,
      render: (text) => <a className="cursor-pointer">{text}</a>,
    },
    {
      title: t('domain.group'),
      dataIndex: ['group', 'name'],
      showSorterTooltip: false,
    },
    {
      title: t('domain.representativeName'),
      dataIndex: 'representativeName',
      showSorterTooltip: false,
    },
    {
      title: t('domain.mailAccount'),
      dataIndex: 'mailAccount',
      showSorterTooltip: false,
    },
    {
      title: t('domain.smoothChatbot'),
      dataIndex: 'smoothChatbot',
      showSorterTooltip: false,
      render: (text, props: any) => (
        <>
          {props.smoothId && (
            <div>
              <a
                data-id={props.smoothId}
                className="cursor-pointer"
                onClick={(event) => {
                  event.stopPropagation();
                  if (props.smooth.isActive) {
                    onSelectSmooth({
                      groupId: props.groupId,
                      domainId: props.id,
                    });
                  }
                }}
              >
                <span className="type-smooth">[{t('dashboard.smooth')}]</span>
                {props.smooth?.name}
              </a>
            </div>
          )}
          {props.chatbotId && (
            <div>
              <a
                className="cursor-pointer"
                onClick={(event) => {
                  event.stopPropagation();
                  if (props.chatbot.isActive) {
                    onSelectChatbot(props.chatbotId);
                  }
                }}
              >
                <span className="type-chatbot">[{t('dashboard.chatbot')}]</span>
                {props.chatbot?.name}
              </a>
            </div>
          )}
        </>
      ),
    },
    {
      title: t('domain.status'),
      dataIndex: 'status',
      width: 130,
      render: (_, props) => (
        <>
          {props.isActive ? (
            <div className="badge-success">{t('members.active')}</div>
          ) : (
            <div className="badge-danger">{t('members.suspended')}</div>
          )}
        </>
      ),
      showSorterTooltip: false,
    },
    {
      title: t('domain.action'),
      dataIndex: 'action',
      className: 'action-column',
      fixed: 'right',
      width: 120,

      render: (_, { group, isActive, ...props }) => (
        <>
          <EditOutlined
            className="btn-icon-save"
            onClick={(event) => {
              event.stopPropagation();
              onEdit({ group, isActive, ...props });
            }}
          />
          <DeleteOutlined
            className="btn-icon-close ml-2"
            onClick={(event) => {
              event.stopPropagation();
              handleOpenModalDelete({ group, isActive, ...props });
            }}
          />
          {isActive ? (
            <StopOutlined
              className="btn-icon-block ml-2"
              onClick={(event) => {
                event.stopPropagation();
                handleOpenModalBlock({ group, isActive, ...props });
              }}
            />
          ) : (
            <CheckCircleOutlined
              className="btn-icon-active ml-2"
              onClick={(event) => {
                event.stopPropagation();
                handleOpenModalBlock({ group, isActive, ...props });
              }}
            />
          )}
        </>
      ),
      showSorterTooltip: false,
    },
  ];

  const handleOpenModalBlock = (domain: IDomain) => {
    setIsOpenModalBlock(true);
    setDomainSelect(domain);
  };
  const handleCancelBlock = () => {
    setIsOpenModalBlock(false);
  };
  const handleSubmitBlock = async (domain: any) => {
    await blockDomain(domain)
      .then((result: any) => {
        if (result && result.data) {
          handleAfterSubmit();
          notification.success({ message: t('message.updateSuccess') });
        }
      })
      .catch((e: any) => notification.error({ message: e.message }))
      .finally(() => {
        setIsOpenModalBlock(false);
      });
  };

  const handleOpenModalDelete = (domain: IDomain) => {
    setIsOpenModalDelete(true);
    setDomainSelect(domain);
  };
  const handleCancelDelete = () => {
    setIsOpenModalDelete(false);
  };
  const handleSubmitDelete = async (domain: IDomain) => {
    deleteDomain(domain)
      .then((result: any) => {
        if (result && result.data) {
          handleAfterSubmit();
          notification.success({ message: t('message.deleteSuccess') });
        }
      })
      .catch((e: any) => notification.error({ message: e.message }))
      .finally(() => {
        setIsOpenModalDelete(false);
      });
  };

  return (
    <>
      <Table
        className="event-table"
        columns={columns}
        dataSource={tableData.data}
        pagination={{ ...tableData.pagination, locale: { items_per_page: t('common.perPage') } }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        onRow={(_data: any, _index: number | undefined) => {
          return {
            onClick: () => {
              if (onRowClick) {
                // onRowClick(_data, _index);
              } else {
                onView(_data);
              }
            },
          };
        }}
        bordered
      />

      {domainSelect && (
        <ModalComponent
          title={t('domain.blockDomain')}
          handleClose={handleCancelBlock}
          handleOk={() => handleSubmitBlock(domainSelect)}
          isOpen={isOpenModalBlock}
        >
          {domainSelect.isActive ? t('domain.areYouSureWantToBlockDomain') : t('domain.areYouSureWantToUnlockDomain')}
          <strong>{domainSelect.domainName}</strong>?
        </ModalComponent>
      )}
      {domainSelect && (
        <ModalComponent
          title={t('domain.deleteDomain')}
          handleClose={handleCancelDelete}
          handleOk={() => domainSelect && handleSubmitDelete(domainSelect)}
          isOpen={isOpenModalDelete}
        >
          {t('domain.areYouSureWantToDeleteDomain')}
          <strong>{domainSelect.domainName}</strong>?
        </ModalComponent>
      )}
    </>
  );
};
