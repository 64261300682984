import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BasicTable } from './BasicTable';
import { TeamOutlined, PlusOutlined } from '@ant-design/icons';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import { Button } from '@app/components/common/buttons/Button/Button';
import { debounce } from 'lodash';
import { Typography } from 'antd';
import { getTemplateMailList } from '@app/api/mail.api';
import { notification } from 'antd';
import { Loading } from '@app/components/common/Loading';

export const initValues: any = {
  name: '',
};

const TemplateMailListPage: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [initData, setInitData] = useState(initValues);
  const [dataList, setDataList] = useState([]);
  const [totalRecords, setTotalRecords] = useState<any>(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const [isShowForm, setIsShowForm] = useState(false);

  const debounceFn = useCallback(
    debounce((data: any) => {
      changeKeyword(data);
    }, 1000),
    [],
  );

  useEffect(() => {
    onSearchTemplate(page, limit, search);
  }, []);

  const onSearchTemplate = (_page: number, _limit: number, _search: string) => {
    setPage(_page);
    setLimit(_limit);
    setIsLoading(true);
    setIsShowForm(false);
    const payload = {
      page: _page,
      limit: _limit,
      search: _search,
    };
    getTemplateMailList(payload)
      .then((res: any) => {
        if (res && res?.status && res?.status == 'success') {
          setDataList(res.data);
          setTotalRecords(res?.data?.total);
          setIsShowForm(true);
        } else {
          setDataList([]);
          notification.error({
            message: res ? (res[0] && res[0].messages ? res[0].messages[0] : res.message) : t('message.error500'),
          });
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => {
        setIsLoading(false);
        setIsShowForm(true);
      });
  };

  const changePagination = (paging: any) => {
    setPage(paging.current);
    setLimit(paging.pageSize);
    onSearchTemplate(paging.current, paging.pageSize, search);
  };

  const changeKeyword = (search: any) => {
    setPage(1);
    setSearch(search);
    onSearchTemplate(1, limit, search);
  };

  const onAddTemplate = () => {
    navigate('/mail-setting/add');
  };

  const onChangeSearch = (data: any) => {
    debounceFn(data.target.value);
  };

  return !isLoading ? (
    isShowForm ? (
      <>
        <Typography.Title level={4} className="mb-2">
          <>
            <TeamOutlined />
            &nbsp; {t('mail.templateMailList')} &nbsp;
            <span className="count-record-table">
              ({totalRecords || 0} {t('groups.records')})
            </span>
          </>
        </Typography.Title>
        <div className="d-flex-space-between mb-2">
          <Button
            type="primary"
            className="btn-add-template"
            onClick={() => {
              onAddTemplate();
            }}
          >
            {t('button.addTemplate')} <PlusOutlined />
          </Button>

          <SearchInput
            className="right-search-input"
            placeholder={t('mail.searchMailTemplate')}
            allowClear
            onChange={(data: any) => onChangeSearch(data)}
          />
        </div>
        <BasicTable
          dataList={dataList}
          changePagination={(data) => changePagination(data)}
          handleAfterSubmit={() => onSearchTemplate(page, limit, search)}
          page={page}
          size={limit}
        />
      </>
    ) : (
      <></>
    )
  ) : (
    <Loading />
  );
};

export default TemplateMailListPage;
