import React from 'react';
import { Popover } from 'antd';

interface PopupProps {
  dataList: any;
  title: any;
}

export const RolesPopup: React.FC<PopupProps> = ({dataList, title}) => {
  const style = {
    "max-width": "50em",
    "overflow": "hidden",
    "text-overflow": "ellipsis",
    "word-wrap": "break-word",
  }
  const contents = (<div style={style}>
    {dataList.join(', ')}
    </div>);
  return (
    <>
      <Popover 
        title={title}
        content={contents}
      >
        <a>{dataList[0]}{dataList.length>1?', ...':''}</a>
      </Popover>
    </>
  );
};
