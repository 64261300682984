import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicTable } from './BasicTable';
import { TeamOutlined } from '@ant-design/icons';
import * as S from 'components/tables/Tables/Tables.styles';
import { RoleAction } from '@app/pages/RoleSetting/RoleSettingPage';

interface roleProps {
  typeAction?: RoleAction;
  data?: any;
  roleSetting?: any;
  typeSelected: any;
  reloadDataTable: (data: any) => void;
}

export const RoleTable: React.FC<roleProps> = ({
  typeAction = RoleAction.VIEW,
  data,
  roleSetting,
  typeSelected,
  reloadDataTable,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <S.Card id="basic-table" className="height-auto clear-shadow" padding="1.25rem 1.25rem 0">
        <BasicTable
          typeAction={typeAction}
          typeSelected={typeSelected}
          dataTable={data}
          roleSetting={roleSetting}
          isPermissionPage={false}
          reloadDataTable={reloadDataTable}
        />
      </S.Card>
    </>
  );
};
