import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@app/components/common/buttons/Button/Button';
import {
  PlusOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  SaveOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Table } from 'components/common/Table/Table';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { ColumnsType } from 'antd/es/table';
import { notification } from 'antd';
import { getExternalApp, postExternalApp, patchExternalApp, deleteExternalApp } from '@app/api/dashboard.api';
import { Modal, ConfirmModal } from '@app/components/common/Modal/Modal';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { useParams, useLocation } from 'react-router-dom';
import { FONT_SIZE, media } from '@app/styles/themes/constants';
import { readProfile } from '@app/services/localStorage.service';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { Pagination } from 'api/table.api';

export const initValues: any = {
  name: '',
  url: '',
  description: '',
};
const AsyncButton = styled(Button)`
  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.xs};
  }
  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.md};
  }
`;
interface ExternalAppProps {
  isCallOpenAdd?: any;
  reload?: () => void;
}
const ExternalAppPage: React.FC<ExternalAppProps> = ({ isCallOpenAdd, reload }) => {
  const { t } = useTranslation();
  const { idDomain, idGroup } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [initData, setInitData] = useState(initValues);
  const [pageCount, setPageCount] = useState(0);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(false);
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [recordSelected, setRecordSelected] = useState({
    id: 0,
  });
  const location = useLocation();
  const limit = location.pathname.indexOf('/members') >= 0 || location.pathname.indexOf('/profile') >= 0 ? 10 : 3;
  const [exAppForm] = BaseButtonsForm.useForm();
  const [page, setPage] = useState(1);

  const initialPagination: Pagination = {
    current: page,
    pageSize: limit,
    total: 0,
  };

  const [tableData, setTableData] = useState<{ data: any[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const handleTableChange = (pagination: any) => {
    console.log(pagination);
    setPage(pagination.current);
    console.log(pagination);
    console.log(tableData);
  };

  const onEdit = (data: any) => {
    setInitData(data);
    exAppForm.setFieldsValue(data);
  };

  const handleDelete = (props: any) => {
    deleteExternalApp(idGroup, props.domainId || idDomain, props.id)
      .then((res: any) => {
        if (res?.status == 'success') {
          notification.success({ message: res.data });
          onCancel();
          onGetExternalApp(page, limit);
        }
      })
      .catch((e: any) => notification.error({ message: e.message }));
  };

  const onDelete = (data: any) => {
    ConfirmModal({
      title: t('common.titleConfirmation'),
      content: (
        <>
          {t('domain.areYouSureWantToDeleteExternalApp')} <strong>{data?.name}</strong>?
        </>
      ),
      onOk: () => handleDelete(data),
      okText: t('button.yes'),
      cancelText: t('button.no'),
    });
  };

  useEffect(() => {
    onGetExternalApp(page, limit);
  }, [page]);
  useEffect(() => {
    if (!isFirstOpen) {
      if (isCallOpenAdd != null) onOpenFormModal();
    } else {
      setIsFirstOpen(false);
    }
  }, [isCallOpenAdd]);
  const columns: ColumnsType<any> = [
    {
      title: t('dashboard.name'),
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: false,
      render: (text, props) => {
        return <span>{props.name}</span>;
      },
    },
    {
      title: t('dashboard.url'),
      dataIndex: 'url',
      key: 'url',
      showSorterTooltip: false,
      render: (text, props) => (
        <>
          <a href={props.url} target="_blank">
            {props.url}
          </a>
        </>
      ),
    },
    {
      title: t('dashboard.action'),
      dataIndex: 'action',
      className: 'action-column',
      fixed: 'right',
      width: '120px',
      render: (text, props) => (
        <>
          <EditOutlined
            className="btn-icon-save"
            onClick={() => {
              onEdit(props);
              setIsVisible(true);
            }}
          />
          <DeleteOutlined className="btn-icon-close ml-1" onClick={() => onDelete(props)} />
        </>
      ),
    },
  ];

  const onCancel = () => {
    exAppForm.resetFields();
    setIsVisible(false);
  };
  const onOpenFormModal = () => {
    setInitData(initValues);
    setIsVisible(true);
  };
  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    await (initData.id
      ? patchExternalApp(idGroup, initData.domainId ? initData.domainId : idDomain, initData.id, data)
      : postExternalApp(idGroup, idDomain, data)
    )
      .then((res: any) => {
        if (res?.status == 'success') {
          notification.success({ message: res.data });
          !!reload && reload();
          onGetExternalApp(page, limit);
        }
      })
      .catch((e: any) => notification.error({ message: e.message }))
      .finally(() => {
        onCancel();
        setIsLoading(false);
      });
  };
  const onGetExternalApp = (page: number, limit: number) => {
    setPage(page);
    getExternalApp(idGroup, idDomain, {
      page: page,
      limit: limit,
    })
      .then((res: any) => {
        if (res?.statusCode == 200) {
          setTableData({
            ...tableData,
            data: res.data.data,
            pagination: {
              ...tableData.pagination,
              current: page,
              total: res.data.total,
            },
          });
        }
      })
      .catch((e: any) => {
        if (idDomain) notification.error({ message: e.message });
      });
  };
  return (
    <>
      {!idDomain && (
        <>
          <div>
            <Table
              columns={columns}
              scroll={{ x: 400 }}
              dataSource={tableData.data}
              pagination={{
                ...tableData.pagination,
                locale: { items_per_page: t('common.perPage') },
                position: ['topRight'],
              }}
              onChange={handleTableChange}
              loading={tableData.loading}
              bordered
            />
          </div>
        </>
      )}

      {isVisible && (
        <Modal
          title={initData.id ? t('dashboard.editExternalApp') : t('dashboard.createExternalApp')}
          visible={isVisible}
          onCancel={() => onCancel()}
          cancelText={t('button.cancel')}
          okText={t('button.save')}
          confirmLoading={isLoading}
          okButtonProps={{
            type: 'primary',
            onClick: () => {
              exAppForm.submit();
            },
          }}
        >
          <Auth.FormWrapper className="p-05">
            <BaseForm
              confirmSubmitProps={{ active: true }}
              layout="vertical"
              form={exAppForm}
              onFinish={handleSubmit}
              requiredMark="optional"
              initialValues={initData}
            >
              <Row gutter={[10, 10]}>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="name"
                    label={t('dashboard.name')}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      { max: 100, message: t('validator.maxLength', { count: 100 }) },
                    ]}
                  >
                    <Auth.FormInput placeholder={t('dashboard.name')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={24}>
                  <Auth.FormItem
                    name="url"
                    label={t('dashboard.url')}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      {
                        pattern:
                          /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
                        message: t('validator.allowOnlyHttps'),
                      },
                      { max: 255, message: t('validator.maxLength', { count: 255 }) },
                    ]}
                  >
                    <Auth.FormInput placeholder={t('dashboard.url')} />
                  </Auth.FormItem>
                </Col>
              </Row>
            </BaseForm>
          </Auth.FormWrapper>
        </Modal>
      )}
    </>
  );
};
export default ExternalAppPage;
