import { ArrowRightOutlined, SaveOutlined } from '@ant-design/icons';
import { getReferenceInScope } from '@app/api/member.api';
import { getMemberRoleList } from '@app/api/role.api';
import { Card } from '@app/components/common/Card/Card';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Option, Select } from '@app/components/common/selects/Select/Select';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Col, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Auth from './AddSingleUser.styles';
import RoleAssignTable from './RoleAssignTable';

interface AddUserFormProps {
  submit: (data: any) => void;
  isLoading: boolean;
}

export enum TypeUser {
  SYSTEM = 'system',
  GROUP = 'group',
  DOMAIN = 'domain',
}

interface AddSingleUserFormData {
  fullName: any;
  email: any;
  group: any;
  department: any;
  telephone: any;
  permission: Array<string>;
}
export const initValues: AddSingleUserFormData = {
  fullName: null,
  email: null,
  group: null,
  department: null,
  telephone: null,
  permission: [],
};
export const AddSingleUserForm: React.FC<AddUserFormProps> = React.memo(({ submit, isLoading }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const [listRole, setListRole] = useState([]);
  const user = useAppSelector((state) => state.user.user);
  const [listDomain, setListDomain] = useState([]);
  const [listGroup, setListGroup] = useState([]);
  const [listGroupOfDomain, setListGroupOfDomain] = useState([]);
  const [listSelected, setListSelected] = useState([]);
  const [listRoleSelect, setListRoleSelect] = useState<number[]>([]);
  const [typeRoleList, setTypeRoleList] = useState<any[]>([]);
  const [typeUser, setTypeUser] = React.useState<TypeUser | null>(null);
  const [refIdDomain, setRefIdDomain] = useState<number | string | null>(null);
  const [refIdGroup, setRefIdGroup] = useState<number | string | null>(null);

  useEffect(() => {
    if (
      typeUser === TypeUser.SYSTEM ||
      (typeUser === TypeUser.GROUP && refIdGroup) ||
      (typeUser === TypeUser.DOMAIN && refIdDomain)
    )
      onSearchRoles();
  }, [typeUser, refIdGroup, refIdDomain]);

  useEffect(() => {
    form.resetFields(['refIdGroup', 'refIdDomain']);
  }, [typeUser]);

  const changeSelect = (data: any) => {
    setListRoleSelect(data);
  };

  const onSearchRoles = () => {
    const payload = {
      referenceId: typeUser === TypeUser.DOMAIN ? refIdDomain : refIdGroup,
      type: typeUser,
    };
    getMemberRoleList(payload)
      .then((res: any) => {
        if (res && res?.status && res?.status == 'success') {
          setListRole(res?.data);
        } else {
          setListRole([]);
          throw new Error(res?.data?.message || t('message.error500'));
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => setListRoleSelect([]));
  };

  const handleSubmit = (data: any) => {
    const referenceId = typeUser === TypeUser.DOMAIN ? refIdDomain : refIdGroup;
    submit({
      data: {
        ...data,
        referenceId,
      },
      roleIds: listRoleSelect,
    });
  };

  const onChangeTypeValue = (data: TypeUser) => {
    setTypeUser(data);
    setRefIdDomain(null);
    setRefIdGroup(null);
  };

  const onChangeRefIdGroup = (data: TypeUser) => {
    form.resetFields(['refIdDomain']);
    setRefIdDomain(null);
    setRefIdGroup(data);
  };
  const onChangeRefIdDomain = (data: TypeUser) => {
    setRefIdDomain(data);
  };

  React.useEffect(() => {
    fetchOrgsUnit();
  }, []);

  const fetchOrgsUnit = async () => {
    await getReferenceInScope()
      .then((res: any) => {
        if (res?.status === 'success') {
          setTypeRoleList(res?.data?.type);
          const { domains, groups } = res?.data?.reference;
          setListDomain(domains);
          setListGroup(groups);
          if (domains?.length) {
            setListGroupOfDomain(
              domains
                .map((domain: any) => domain.group)
                .filter((value: any, index: any, self: any) => self.findIndex((m: any) => m.id === value.id) === index),
            );
          }
        }
      })
      .catch((e) => {
        notification.error({ message: e.message });
      });
  };

  return (
    <>
      <Card padding="0">
        <Row gutter={[30, 30]} className="w-100">
          <Col span={12}>
            <Auth.FormWrapper>
              <BaseForm
                confirmSubmitProps={{ active: true }}
                layout="vertical"
                onFinish={handleSubmit}
                requiredMark="optional"
                initialValues={initValues}
                form={form}
              >
                <Row gutter={[30, 30]} className="w-100 row-gap-0">
                  <Col span={12}>
                    <Auth.FormItem
                      name="fullName"
                      label={t('members.fullName')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      ]}
                    >
                      <Auth.FormInput placeholder={t('members.fullName')} />
                    </Auth.FormItem>
                  </Col>
                  <Col span={12}>
                    <Auth.FormItem
                      name="telephone"
                      label={t('members.telephone')}
                      rules={[{ pattern: /^0[0-9]{9,12}$/, message: t('validator.telephoneFormatIncorrect') }]}
                    >
                      <Auth.FormInput placeholder={t('members.telephone')} />
                    </Auth.FormItem>
                  </Col>
                  <Col span={12}>
                    <Auth.FormItem
                      name="email"
                      label={t('members.email')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        {
                          type: 'email',
                          message: t('validator.notValidEmail'),
                        },
                      ]}
                    >
                      <Auth.FormInput placeholder={t('members.email')} />
                    </Auth.FormItem>
                  </Col>
                  <Col span={12}>
                    <Auth.FormItem
                      name="department"
                      label={t('members.department')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            console.log(value);
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      ]}
                    >
                      <Auth.FormInput placeholder={t('members.department')} />
                    </Auth.FormItem>
                  </Col>
                  <Col span={12}>
                    <Auth.FormItem
                      name="type"
                      label={t('members.type')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                      ]}
                    >
                      <Select placeholder={t('members.type')} onChange={(value: any) => onChangeTypeValue(value)}>
                        {typeRoleList?.map((item, _i) => {
                          return (
                            <Option key={_i} value={item.value}>
                              {item.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Auth.FormItem>
                  </Col>
                  <Col span={12}>
                    {!!typeUser && typeUser !== TypeUser.SYSTEM && (
                      <Auth.FormItem
                        name="refIdGroup"
                        label={t('member:group').toUpperCase()}
                        rules={[
                          {
                            required: true,
                            validator: (_, value) => {
                              return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('common.requiredField')));
                            },
                          },
                        ]}
                      >
                        <Select
                          onChange={(value: any) => onChangeRefIdGroup(value)}
                          showSearch
                          filterOption={(input, option) => {
                            return (option?.children?.toLowerCase() ?? '').includes(input.toLowerCase());
                          }}
                        >
                          {(typeUser === TypeUser.GROUP ? listGroup : listGroupOfDomain)
                            .filter((item: any) => item.isActive)
                            .map((item: any, _i) => {
                              return (
                                <Option key={'GROUP_' + _i} value={item.id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Auth.FormItem>
                    )}
                  </Col>
                  <Col span={12}>
                    {!!typeUser && typeUser === TypeUser.DOMAIN && (
                      <Auth.FormItem
                        name="refIdDomain"
                        label={t('member:domain').toUpperCase()}
                        rules={[
                          {
                            required: true,
                            validator: (_, value) => {
                              return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('common.requiredField')));
                            },
                          },
                        ]}
                      >
                        <Select
                          onChange={(value: any) => onChangeRefIdDomain(value)}
                          showSearch
                          filterOption={(input, option) => {
                            return (option?.children?.toLowerCase() ?? '').includes(input.toLowerCase());
                          }}
                        >
                          {listDomain
                            .filter((item: any) => item.isActive)
                            .filter((item: any) => item?.group?.id === refIdGroup)
                            .map((item: any, _i) => {
                              return (
                                <Option key={'DOMAIN_' + _i} value={item.id}>
                                  {item.domainName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Auth.FormItem>
                    )}
                  </Col>
                </Row>
                <BaseForm.Item noStyle>
                  <Auth.SubmitButton disabled={isLoading} type="primary" htmlType="submit">
                    {t('button.save')} <SaveOutlined />
                  </Auth.SubmitButton>
                </BaseForm.Item>
              </BaseForm>
            </Auth.FormWrapper>
          </Col>
          <Col span={12}>
            {((typeUser === TypeUser.GROUP && refIdGroup) ||
              (typeUser === TypeUser.DOMAIN && refIdDomain) ||
              typeUser === TypeUser.SYSTEM) && (
              <RoleAssignTable
                listCantRole={[]}
                listCanRole={listRole}
                listIdSelected={listRoleSelect}
                changeSelect={changeSelect}
              />
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
});
