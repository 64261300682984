import { CheckCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { getMailSetting, postMailSetting, postVerifyEmail } from '@app/api/mail.api';
import { Loading } from '@app/components/common/Loading';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { Col, Form, Row, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EditMailSetting: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [mail, setMail] = useState('');
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false);
  const [requestId, setRequestId] = useState(null);
  const [baseData, setBaseData] = useState({
    email: '',
    name: '',
    replyTo: '',
    status: '',
  });
  const [form] = useForm();
  const currentEmail = Form.useWatch('email', form);

  interface IEmailSetting {
    email?: string;
    name?: string;
    replyTo?: string;
    status?: string;
  }
  const [initData, setInitData] = useState<IEmailSetting>({
    email: '',
    name: '',
    replyTo: '',
    status: '',
  });

  useEffect(() => {
    onGetMailSetting();
  }, []);

  const handleSubmit = (data: any) => {
    setIsLoading(true);
    const payload = {
      ...data,
    };
    if (requestId) {
      payload.requestId = requestId;
    }
    postMailSetting(payload)
      .then((res: any) => {
        if (res?.status == 'success') {
          setRequestId(null);
          notification.success({ message: res.data });
        } else {
          notification.error({ message: res?.message || t('message.error500') });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeMail = (data: any) => {
    setMail(data.target.value);
    setInitData({
      ...initData,
      status: `${data.target.value != initData?.email ? 'Error' : baseData.status}`,
    });
  };

  const verifyEmail = () => {
    setIsLoadingVerify(true);
    postVerifyEmail({
      email: currentEmail,
    })
      .then((res: any) => {
        if (res?.status == 'success') {
          setRequestId(res.data.requestId);
          setInitData({
            ...initData,
            status: `Pending`,
          });
          notification.success({ message: t('message.verifyEmailSuccessfully') });
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => {
        setIsLoadingVerify(false);
      });
  };

  const onGetMailSetting = () => {
    setIsLoading(true);
    setIsShowForm(false);
    getMailSetting()
      .then((res: any) => {
        if (res?.status == 'success') {
          setInitData(res.data);
          setBaseData(res.data);
          setRequestId(null);
          setIsShowForm(true);
        } else {
          notification.error({
            message: res?.data?.message || t('message.error500'),
          });
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => setIsLoading(false));
  };

  return !isLoading ? (
    <>
      {isShowForm && (
        <Auth.FormWrapper className="p-0 overflow-hidden">
          <BaseForm
            form={form}
            confirmSubmitProps={{ active: true }}
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initData}
          >
            {(_, formInstance) => {
              return (
                <Row gutter={[30, 0]}>
                  <Col xs={24} md={12}>
                    <Auth.FormItem
                      name="name"
                      label={t('mail.name')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      ]}
                    >
                      <Auth.FormInput
                        placeholder={t('mail.name')}
                        onBlur={(e) => {
                          formInstance.setFieldValue('name', e.target.value.trim());
                        }}
                      />
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} md={12}></Col>
                  <Col xs={24} md={12}>
                    <Auth.FormItem
                      name="email"
                      label={t('mail.mail')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        {
                          pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/,
                          message: t('validator.notValidEmail'),
                        },
                        { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      ]}
                    >
                      <Auth.FormInput placeholder={t('mail.mail')} onChange={onChangeMail} />
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} md={6}>
                    <Auth.FormItem className="btn-verify-mail">
                      {initData?.status != 'Success' ? (
                        <>
                          {initData?.status == 'Failed' && <span>{t('button.failed')}</span>}
                          {initData?.status == 'Pending' && <span>{t('button.pending')}</span>}
                          <Button
                            disabled={!!form.getFieldError('email').length}
                            type={'primary'}
                            loading={isLoadingVerify}
                            onClick={verifyEmail}
                          >
                            {initData?.status == 'Pending' ? t('button.verifyAgain') : t('button.verifyMail')}
                          </Button>
                        </>
                      ) : (
                        <CheckCircleOutlined className="verify-done-icon" />
                      )}
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} md={12}>
                    <Auth.FormItem
                      name="replyTo"
                      label={t('mail.replyTo')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        {
                          pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/,
                          message: t('validator.notValidEmail'),
                        },
                        { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      ]}
                    >
                      <Auth.FormInput placeholder={t('mail.replyTo')} />
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} md={24}>
                    <BaseForm.Item noStyle>
                      <Button type="primary" htmlType="submit" loading={isLoading}>
                        {t('button.save')} <SaveOutlined />
                      </Button>
                    </BaseForm.Item>
                  </Col>
                </Row>
              );
            }}
          </BaseForm>
        </Auth.FormWrapper>
      )}
    </>
  ) : (
    <Loading />
  );
};
