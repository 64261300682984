import React from 'react';
import { Avatar, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { H6 } from '@app/components/common/typography/H6/H6';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import defaultAvatar from '@app/assets/images/defaultAvatar.svg';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user.user);

  if (user && user?.isPasswordReset) {
    navigate('/reset-password');
  }

  return user ? (
    <Dropdown overlay={<ProfileOverlay />} trigger={['click']}>
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>
          <Avatar src={user.avatarUrl || defaultAvatar} alt="User" shape="circle" size={40} />
        </Col>
        {isTablet && (
          <Col>
            {' '}
            <H6>{`${user?.fullName?.slice(0, 10)}`}</H6>
          </Col>
        )}
      </S.ProfileDropdownHeader>
    </Dropdown>
  ) : null;
};
