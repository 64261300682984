import { patchForgotPasswordChange } from '@app/api/login.api';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './ForgotPasswordChange.styles';

interface ForgotPasswordChangeData {
  email: string;
  otp: number;
  password: string;
  confirmPassword: string;
}

const initValues = {
  email: null,
  otp: null,
  password: null,
  confirmPassword: null,
};

export const ForgotPasswordChange: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');
  const code = new URLSearchParams(search).get('code');

  useEffect(() => {
    if (!email) {
      navigate('/forgot-password');
    }
  }, []);
  const handleSubmit = (values: ForgotPasswordChangeData) => {
    setLoading(true);
    patchForgotPasswordChange(values)
      .then((result) => {
        if (result) {
          navigate('/login');
          notification.success({ message: t('message.updateSuccess') });
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={{ ...initValues, email, otp: code }}
      >
        {/* <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('button.back')}
        </Auth.BackWrapper> */}
        <Auth.FormTitle>{t('profile.changePassword')}</Auth.FormTitle>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('common.requiredField')));
              },
            },
            { type: 'email', message: t('validator.notValidEmail') },
            { max: 255, message: t('validator.maxLength', { count: 255 }) },
          ]}
        >
          <Auth.FormInput disabled placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="otp"
          label={t('common.otp')}
          rules={[
            { required: true, message: t('common.otpError') },
            { pattern: /^\d+$/, message: t('validator.onlyInteger') },
          ]}
        >
          <Auth.FormInput autoComplete="off" placeholder={t('common.otp')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="password"
          label={t('common.password')}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('common.requiredField')));
              },
            },
          ]}
          dependencies={['password']}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} type={'password'} />
        </Auth.FormItem>
        <Auth.FormItem
          name="confirmPassword"
          label={t('common.confirmPassword')}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('common.requiredField')));
              },
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('validator.confirmPasswordError')));
              },
            }),
          ]}
        >
          <Auth.FormInputPassword placeholder={t('common.confirmPassword')} type={'password'} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('forgotPassword.sendChangePassword')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
