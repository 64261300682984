import { CopyOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { exportLogs, getLogsList } from '@app/api/log.api';
import { Card } from '@app/components/common/Card/Card';
import { Button } from '@app/components/common/buttons/Button/Button';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { FORMAT, formatDate } from '@app/utils/utils';
import { Col, Row, notification } from 'antd';
import * as S from 'components/tables/Tables/Tables.styles';
import { debounce } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { BasicTable } from './BasicTable';

export const LogsTable: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const logsCsvLink = useRef<any>();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [dateRange, setDateRange] = useState([null, null]);
  const [search, setSearch] = useState('');
  const [logList, setLogList] = useState({
    total: 0,
  });

  const [headers, setHeaders] = useState<{ label: string; key: string }[]>([]);
  const [dataExport, setDataExport] = useState<any>([]);
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user.user);

  React.useEffect(() => {
    if (user && !user?.isPasswordReset) {
      onSearchLogs(page, limit, search, dateRange);
    }
  }, [user?.isPasswordReset]);

  const redirectToAddDomain = () => {
    navigate('/domains/add');
  };

  const changePagination = (paging: any) => {
    setPage(paging.current);
    setLimit(paging.pageSize);
    onSearchLogs(paging.current, paging.pageSize, search, dateRange);
  };

  const onChangeDate = (_date: any) => {
    const date: any = _date ? [_date[0]?.$d?.toISOString() || null, _date[1]?.$d?.toISOString() || null] : '';
    setDateRange(date);
    onSearchLogs(page, limit, search, date);
  };

  const changeInput = (data: any) => {
    setPage(1);
    setSearch(data.target.value);
    debounceFn(data.target.value);
  };

  const debounceFn = useCallback(
    debounce((data: any) => {
      onSearchLogs(1, limit, data, dateRange);
    }, 1000),
    [],
  );

  const exportLogsList = async () => {
    const payload: any = {};
    if (dateRange[0]) payload.startDate = dateRange[0];
    if (dateRange[1]) payload.endDate = dateRange[1];
    payload.search = search;
    await exportLogs(payload)
      .then((res: any) => {
        if (res?.status == 'success') {
          const { data } = res;
          const headers = [
            { label: t('log.date'), key: 'createdAt' },
            { label: t('log.fullName'), key: 'fullName' },
            { label: t('log.status'), key: 'status' },
            { label: t('log.action'), key: 'action' },
            { label: t('log.data'), key: 'data' },
            { label: t('log.email'), key: 'email' },
          ];
          setHeaders(headers);
          if (data && Array.isArray(data)) {
            const formatedData = data.map((item: any) => ({
              ...item,
              createdAt: item.createdAt ? formatDate(item.createdAt) : '',
            }));
            setDataExport(formatedData);
          }
          logsCsvLink?.current?.link?.click();
        }
      })
      .catch((e: any) => notification.error({ message: e.message }));
  };

  const onSearchLogs = (_page: number, _limit: number, _search: string, _date: any) => {
    const payload = {
      page: _page,
      limit: _limit,
      search: _search,
      startDate: _date[0],
      endDate: _date[1],
    };
    !_date[0] && delete payload.startDate;
    !_date[1] && delete payload.endDate;
    getLogsList(payload)
      .then((res: any) => {
        if (res?.status == 'success') {
          setLogList(res.data);
        }
      })
      .catch((e) => {
        if (location.pathname == '/logs') {
          notification.error({ message: e.message });
        }
      });
  };

  return (
    <>
      <Row gutter={[30, 30]}>
        <Col xs={24} lg={24}>
          <Card padding="1rem">
            <Button className="d-flex" type="primary" onClick={exportLogsList}>
              {t('button.downloadLogs')} <VerticalAlignBottomOutlined />
            </Button>
          </Card>
        </Col>
      </Row>
      <br />
      <S.Card
        id="basic-table"
        title={
          <>
            <CopyOutlined /> &nbsp; {t('log.logsList')} &nbsp;
            <span className="count-record-table">
              ({logList.total} {t('groups.records')})
            </span>
          </>
        }
        padding="1.5rem"
      >
        {location.pathname == '/logs' && (
          <div className="d-flex-space-between mb-2">
            <DayjsDatePicker.RangePicker
              placeholder={[t('common.startDate'), t('common.endDate')]}
              allowEmpty={[true, true]}
              onChange={onChangeDate}
              format={FORMAT.DATE}
              // locale={localStorage.getItem('lng') == 'jp' ? locale_jp : locale_en}
            />
            <SearchInput
              className="right-search-input"
              placeholder={t('log.searchLog')}
              allowClear
              onChange={changeInput}
            />
          </div>
        )}
        <BasicTable dataList={logList} changePagination={(data) => changePagination(data)} />
      </S.Card>
      <CSVLink
        data={dataExport}
        headers={headers}
        target="_blank"
        filename={`export_logs_${Date.now()}.csv`}
        ref={logsCsvLink}
      />
    </>
  );
};
