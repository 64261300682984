import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { MemberPieChart } from '@app/components/dashboard/memberPieChart/MemberPieChart';
import { AppPreview } from '@app/components/dashboard/appPreview/AppPreview';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { LogsTable } from '@app/components/logs/logsTable/LogsTable';

const DashboardPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.dashboard')}</PageTitle>
      <Row gutter={[30, 30]}>
        <Col xs={24} lg={8}>
          <MemberPieChart />
        </Col>
        <Col xs={24} lg={16}>
          <AppPreview />
        </Col>
      </Row>
      <br />
      <LogsTable />
    </>
  );
};

export default DashboardPage;
