import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { RadioChangeEvent } from '@app/components/common/Radio/Radio';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'antd';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { getGroupInScope, postDomain } from '@app/api/domain.api';
import { readProfile } from '@app/services/localStorage.service';
import { notification } from 'antd';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import dayjs from 'dayjs';
import locale_jp from 'antd/es/date-picker/locale/ja_JP';
import locale_en from 'antd/es/date-picker/locale/en_US';
import { SaveOutlined } from '@ant-design/icons';
interface AddDomainData {
  groupId: any;
  domainName: string;
  mailAccount: string;
  subMailAccount: string;
  telephone: string;
  department: string;
  numberPostOffice: string;
  addressCity: string;
  addressWardStreet: string;
  companyName: string;
  // representativeName: string;
}
export const initValues: AddDomainData = {
  groupId: null,
  domainName: '',
  mailAccount: '',
  subMailAccount: '',
  telephone: '',
  department: '',
  numberPostOffice: '',
  addressCity: '',
  addressWardStreet: '',
  companyName: '',
  // representativeName: '',
};
const AddDomainPage: React.FC = () => {
  const { id } = useParams<string>();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState([]);

  React.useEffect(() => {
    if (!!id && groups?.some((g: any) => g.id == id)) {
      form.setFieldValue('groupId', +id);
    }
  }, [groups]);

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = async () => {
    await getGroupInScope()
      .then((res: any) => {
        if (res?.status === 'success') setGroups(res.data);
      })
      .catch((e: any) => notification.error({ message: e.message }));
  };

  const handleSubmit = (data: any) => {
    setIsLoading(true);
    const group: any = groups.find((gr: any) => gr.id === data.groupId);
    postDomain({
      ...data,
      settingDate: data.settingDate,
      groupName: group?.name,
    })
      .then((res: any) => {
        if (res && res?.status && res?.status == 'success') {
          navigate('/domain');
          notification.success({ message: res.data && t('message.createSuccessfully') });
        }
      })
      .catch((e: any) => notification.error({ message: e.message }))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Auth.FormWrapper>
        <BaseForm
          form={form}
          confirmSubmitProps={{ active: true }}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
        >
          <Row gutter={[10, 10]}>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="groupId"
                label={t('domain.group')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Select
                  placeholder={t('domain.pleaseChooseGroup')}
                  disabled={!!id && groups?.some((g: any) => g.id == id)}
                >
                  {!!groups &&
                    groups
                      .filter((group: any) => (!id ? group.isActive : true))
                      .map((field: any) => (
                        <Option key={field.id} value={field.id}>
                          {field.name}
                        </Option>
                      ))}
                </Select>
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="domainName"
                label={t('domain.domainName')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('domain.domainName')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="mailAccount"
                label={t('domain.mailAccount')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { type: 'email', message: t('validator.notValidEmail') },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('domain.mailAccount')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="subMailAccount"
                label={t('domain.subMailAccount')}
                rules={[
                  { type: 'email', message: t('validator.notValidEmail') },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('domain.subMailAccount')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="department"
                label={t('domain.department')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('domain.department')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="representativeName"
                label={t('domain.representativeName')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('domain.representativeName')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="telephone"
                label={t('domain.telephone')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { pattern: /^0[0-9]{9,12}$/, message: t('validator.telephoneFormatIncorrect') },
                ]}
              >
                <Auth.FormInput placeholder={t('domain.telephone')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="subTelephone"
                label={t('domain.subTelephone')}
                rules={[{ pattern: /^0[0-9]{9,12}$/, message: t('validator.telephoneFormatIncorrect') }]}
              >
                <Auth.FormInput placeholder={t('domain.subTelephone')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="numberPostOffice"
                label={t('domain.numberPostOffice')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { pattern: /^〒?[0-9]{3}-?[0-9]{4}$/g, message: t('validator.numberPostOfficeFormatIncorrect') },
                ]}
              >
                <Auth.FormInput placeholder={t('domain.numberPostOffice')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="companyName"
                label={t('domain.companyName')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('domain.companyName')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="addressCity"
                label={t('domain.addressCity')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('domain.addressCity')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="addressWardStreet"
                label={t('domain.addressWardStreet')}
                rules={[{ max: 255, message: t('validator.maxLength', { count: 255 }) }]}
              >
                <Auth.FormInput placeholder={t('domain.addressWardStreet')} />
              </Auth.FormItem>
            </Col>

            <Col xs={24} lg={12}>
              <Auth.FormItem name="settingDate" label={t('dashboard.settingDate')}>
                <DatePicker
                  className="w-100"
                  defaultValue={dayjs()}
                  allowClear={false}
                  format="YYYY-MM-DD"
                  locale={localStorage.getItem('x-language-code') == 'jp' ? locale_jp : locale_en}
                />
              </Auth.FormItem>
            </Col>
          </Row>
          <BaseForm.Item noStyle>
            <Auth.SubmitButton disabled={isLoading} type="primary" htmlType="submit">
              {t('button.save')} <SaveOutlined className="mt-05" />
            </Auth.SubmitButton>
          </BaseForm.Item>
        </BaseForm>
      </Auth.FormWrapper>
    </>
  );
};
export default AddDomainPage;
