import React, { useState, useEffect } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Table } from 'components/common/Table/Table';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import * as Auth from './AddSingleUser.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';

interface AddRoleProps {
  listCantRole: any[];
  listCanRole: any[];
  listIdSelected: any[];
  changeSelect: (data: any) => void;
}

const RoleAssignTable: React.FC<AddRoleProps> = ({ listCantRole, listCanRole, listIdSelected, changeSelect }) => {
  const [initTable, setInitTable] = useState<any[]>([]);
  const { t } = useTranslation();

  const columns: ColumnsType<any> = [
    {
      title: t('role.roleType'),
      dataIndex: 'type',
      showSorterTooltip: false,
    },
    {
      title: t('role.roleName'),
      dataIndex: 'name',
      showSorterTooltip: false,
    },
  ];

  const onChangeSelect = (data: any) => {
    changeSelect(data);
  };

  useEffect(() => {
    const arr = [...listCantRole, ...listCanRole];
    setInitTable(arr.filter(({ id }: any) => listIdSelected.includes(id)));
  }, [listCantRole, listCanRole, listIdSelected]);

  return (
    <>
      <Auth.FormWrapper>
        <BaseForm>
          <Auth.FormItem label={t('members.assignRole')}>
            <Select
              key={JSON.stringify(listIdSelected)}
              mode="multiple"
              value={listIdSelected}
              placeholder={t('members.noRoleAssigned')}
              onChange={onChangeSelect}
            >
              {listCantRole.map(({ id, name }: any) => (
                <Option value={id} disabled>
                  {name}
                </Option>
              ))}
              {listCanRole.map(({ id, name }: any) => (
                <Option value={id}>{name}</Option>
              ))}
            </Select>
          </Auth.FormItem>
        </BaseForm>
        <Table
          className="min-height-300"
          columns={columns}
          dataSource={initTable}
          scroll={{ x: 400 }}
          pagination={false}
          bordered
        />
      </Auth.FormWrapper>
    </>
  );
};
export default React.memo(RoleAssignTable);
