import React from 'react';
import { Modal as AntModal, ModalProps as AntModalProps, ModalFuncProps } from 'antd';
import { modalSizes } from 'constants/modalSizes';
import * as S from './Modal.styles';
import { useTranslation } from 'react-i18next';

export const {
  info: InfoModal,
  success: SuccessModal,
  error: ErrorModal,
  warning: WarningModal,
  confirm: ConfirmModal,
} = AntModal;

export interface IConfirmProps extends ModalFuncProps {
  active: boolean;
}

interface ModalProps extends AntModalProps {
  size?: 'small' | 'medium' | 'large';
  onCancel?: (values: any) => void;
  confirmCancelProps?: IConfirmProps;
  onOk?: (values: any) => void;
  confirmOkProps?: IConfirmProps;
}

export const Modal: React.FC<ModalProps> = ({
  size = 'medium',
  children,
  confirmCancelProps,
  confirmOkProps,
  onCancel: antOnCancel,
  onOk: antOnOk,
  ...props
}) => {
  const { t } = useTranslation();
  const modalSize = Object.entries(modalSizes).find((sz) => sz[0] === size)?.[1];

  const onCancel = (e: any) => {
    const props = {
      active: confirmCancelProps?.active || false,
      title: confirmCancelProps?.title || t('common.titleConfirmation'),
      content: confirmCancelProps?.content || t('common.contentCancelConfirm'),
      okText: confirmCancelProps?.okText || t('button.yes'),
      cancelText: confirmCancelProps?.cancelText || t('button.no'),
      onOk: antOnCancel,
    };
    if (props?.active) ConfirmModal(props);
    else antOnCancel && antOnCancel(e);
  };

  const onOk = (e: any) => {
    const props = {
      active: confirmCancelProps?.active || false,
      title: confirmCancelProps?.title || t('common.titleConfirmation'),
      content: confirmCancelProps?.content || t('common.contentOkConfirm'),
      okText: confirmCancelProps?.okText || t('button.yes'),
      cancelText: confirmCancelProps?.cancelText || t('button.no'),
      onOk: antOnOk,
    };
    if (props?.active) ConfirmModal(props);
    else antOnOk && antOnOk(e);
  };

  return (
    <S.Modal
      getContainer={false}
      width={modalSize}
      onCancel={onCancel}
      onOk={onOk}
      okText={props.okText || t('button.yes')}
      cancelText={props.cancelText || t('button.no')}
      {...props}
    >
      {children}
    </S.Modal>
  );
};
