import { callApi, METHOD } from '@app/api/http.api';
import i18n from '@app/i18n';
import { notification } from 'antd';

export const getLogsList = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = {
      ...payload,
    };
    let response: any = await callApi(METHOD.GET, 'logs', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};
export const exportLogs = async (search: any): Promise<any> => {
  try {
    const payload = {
      ...search,
    };
    let response: any = await callApi(METHOD.GET, `logs/export`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};
