import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Tabs, TabPane } from '@app/components/common/Tabs/Tabs';
import { AuditOutlined, ReloadOutlined } from '@ant-design/icons';
import { PasswordPolicy } from '@app/components/policySetting';
import { Card } from '@app/components/common/Card/Card';

const PolicySettingPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('policy.policySetting')}</PageTitle>
      <Card className='align-baseline' padding="20px" title={t('policy.policySetting')}>
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={
                <span>
                  <AuditOutlined />
                  {t('policy.passwordPolicy')}
                </span>
              }
              key="1"
            >
              <PasswordPolicy />
            </TabPane>
            {/* <TabPane
              tab={
                <span>
                  <ReloadOutlined />
                  {t('policy.passwordReset')}
                </span>
              }
              key="2"
            >
            </TabPane> */}
          </Tabs>
        </Card>
    </>
  );
};

export default PolicySettingPage;
