import React, { useRef, useState } from 'react';
import { Card } from 'components/common/Card/Card';
import { useTranslation } from 'react-i18next';
import { Button } from '@app/components/common/buttons/Button/Button';
import {
  FileExcelOutlined,
  VerticalAlignTopOutlined,
  PlusOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { exportMembers } from '@app/api/member.api';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import notification from 'antd/lib/notification';
import { CSVLink } from 'react-csv';

interface MemberListProps {
  search: string;
}

interface IRoles {
  id: number;
  name: string;
}

interface IMembers {
  fullName?: string;
  isActive?: boolean;
  email?: string;
  telephone?: string;
  department?: string;
  roles?: IRoles[];
  nameOfGroup?: string[];
  nameOfDomain?: string[];
  type: string;
}

export const MemberHeader: React.FC<MemberListProps> = ({ search }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const refCsvLink = useRef<any>();
  const [headers, setHeaders] = useState<{ label: string; key: string }[]>([]);
  const [dataExport, setDataExport] = useState<any>([]);

  const basicMenu = (
    <Menu>
      <MenuItem icon={<VerticalAlignBottomOutlined />} onClick={() => exportUserList()}>
        <Button type="text" target="_blank" rel="noopener noreferrer">
          {t('groups.export')}
        </Button>
      </MenuItem>
      <MenuItem
        icon={<VerticalAlignTopOutlined />}
        onClick={() => {
          importUserList();
        }}
      >
        <Button type="text" target="_blank" rel="noopener noreferrer">
          {t('groups.import')}
        </Button>
      </MenuItem>
    </Menu>
  );

  const redirectToAddUser = () => {
    navigate('/members/add-user');
  };

  const importUserList = () => {
    navigate('/members/add-multi-user');
  };

  const exportUserList = async () => {
    const result = await exportMembers(search);
    if (result?.statusCode === 200) {
      const head = [
        { label: t('csvFile.fullName'), key: 'fullName' },
        { label: t('csvFile.status'), key: 'isActive' },
        { label: t('csvFile.type'), key: 'type' },
        { label: t('csvFile.groupName'), key: 'group' },
        { label: t('csvFile.domainName'), key: 'domain' },
        { label: t('csvFile.emailAddress'), key: 'email' },
        { label: t('csvFile.department'), key: 'department' },
        { label: t('csvFile.telephone'), key: 'telephone' },
        { label: t('csvFile.roles'), key: 'roles' },
      ];
      const data = result?.data?.map(({ type, ...user }: IMembers) => ({
        fullName: user.fullName,
        isActive: user.isActive ? t('csvFile.active') : t('csvFile.suspended'),
        type,
        group: user.nameOfGroup?.join(';'),
        domain: user.nameOfDomain?.join(';'),
        email: user.email,
        department: user.department?.replace(/(?:\\[rn]|[\r\n]+)+/g, ''),
        telephone: user.telephone ? `'${user.telephone}` : null,
        roles: user.roles?.map((role: IRoles) => role.name).join(';'),
      }));
      setHeaders(head);
      if (data && Array.isArray(data)) {
        setDataExport(data);
      }
      refCsvLink?.current?.link?.click();
    } else {
      notification.error({ message: result?.message ?? t('message.error500') });
    }
  };

  return (
    <Card padding="1rem">
      <Button className="d-flex" type="primary" onClick={redirectToAddUser}>
        {t('button.addUser')} <PlusOutlined />
      </Button>
      <Dropdown overlay={basicMenu} className="d-flex ml-2">
        <Button type="primary" onClick={(e) => e.preventDefault()}>
          {t('button.CSVUserManagement')} <FileExcelOutlined />
        </Button>
      </Dropdown>
      <CSVLink
        data={dataExport}
        headers={headers}
        target="_blank"
        filename={`export_member_${Date.now()}.csv`}
        ref={refCsvLink}
      />
    </Card>
  );
};
