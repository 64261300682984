import axios, { AxiosError } from 'axios';
import { readToken, readExpiresDate, readLanguage } from '@app/services/localStorage.service';
import history from '../components/router/history';
import { objToQueryString } from '@app/utils/utils';

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const LIST_AUTH_PATH = [
  'auth/login',
  'auth/logout',
  'auth/password',
  'me/profile',
  'auth/forgot-password',
  'auth/forgot-HMAC',
];

export const httpApi = axios.create({
  baseURL: process.env.NX_CHAT_BOT_BASE_URL,
});

export const callApi = (method: string, path: string, data: any) => {
  const httpApi = axios.create({});
  const authPath = LIST_AUTH_PATH.includes(path) || LIST_AUTH_PATH.includes(path.split('/')[0]);
  const requestToken = readToken();
  httpApi.interceptors.request.use((config) => {
    config.headers = {
      ...config.headers,
      'x-language-code': readLanguage() == 'jp' ? 'ja' : 'en',
    };
    if (!!requestToken) {
      config.headers.Authorization = `Bearer ${requestToken}`;
    }
    return config;
  });

  httpApi.interceptors.response.use(data, (error: AxiosError) => {
    if (error.response?.data?.statusCode == 401 && !authPath) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('expiresDate');
      localStorage.removeItem('expiresIn');
      history.push('/login');
    }
    return error.response?.data || error.message, error.response?.data;
  });

  if (new Date(readExpiresDate()) < new Date() && !authPath) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expiresDate');
    localStorage.removeItem('expiresIn');
    history.push('/login');
  }

  const baseUrlPath = authPath ? process.env.REACT_APP_NX_AUTH_BASE_URL : process.env.REACT_APP_NX_CHAT_BOT_BASE_URL;

  switch (method) {
    case 'POST': {
      return httpApi.post<undefined>(`${baseUrlPath}/${path}`, data);
    }
    case 'PUT': {
      return httpApi.put<undefined>(`${baseUrlPath}/${path}`, data);
    }
    case 'PATCH': {
      return httpApi.patch<undefined>(`${baseUrlPath}/${path}`, data);
    }
    case 'DELETE': {
      return httpApi.delete<undefined>(`${baseUrlPath}/${path}`, { data });
    }
    case 'GET': {
      const query = data ? objToQueryString(data) : '';
      return httpApi.get<undefined>(`${baseUrlPath}/${path}${query}`);
    }
  }
};

export interface ApiErrorData {
  message: string;
}

export interface IPageQuery {
  limit: number;
  search?: string;
  page: number;
}

export interface IApiResponse<T> {
  status: string;
  statusCode: number;
  data: T;
}

export interface IPageResponse {
  count: number;
  page: number;
  pageCount: number;
  total: number;
}
