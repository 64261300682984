import React from 'react';
import { unstable_HistoryRouter as HistoryRouter, Routes, Route, Navigate } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import VerifyPage from '@app/pages/VerifyPage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import GetConversationPage from '@app/pages/GetConversation';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import DashboardPage from '@app/pages/DashboardPages/DashboardPage';
import MemberListPage from '@app/pages/MemberPages/MemberListPage';
import AddMemberPage from '@app/pages/MemberPages/AddMemberPage';
import EditMemberPage from '@app/pages/MemberPages/EditMemberPage';
import RoleListPage from '@app/pages/RoleSetting/RoleListPage';
import RoleSettingPage from '@app/pages/RoleSetting/RoleSettingPage';
import MailSettingPage from '@app/pages/MailSetting/MailSettingPage';
import ViewMailTemplatePage from '@app/pages/MailSetting/ViewMailTemplatePage';
import AddMailTemplatePage from '@app/pages/MailSetting/AddMailTemplatePage';
import LogsSettingPage from '@app/pages/LogsSetting/LogsSettingPage';
import GroupListPage from '@app/pages/GroupPages/GroupListPage';
import AddGroupPage from '@app/pages/GroupPages/AddGroupPage';
import AddMultiGroupPage from '@app/pages/GroupPages/AddMultiGroupPage';
import EditGroupPage from '@app/pages/GroupPages/EditGroupPage';
import DomainListPage from '@app/pages/DomainPages/DomainListPage';
import AddDomainPage from '@app/pages/DomainPages/AddDomainPage';
import AddMultiDomainPage from '@app/pages/DomainPages/AddMultiDomainPage';
import EditDomainPage from '@app/pages/DomainPages/EditDomainPage';
import PolicySettingPage from '@app/pages/PolicySetting/PolicySettingPage';
import PolicySettingGroupPage from '@app/pages/GroupPages/PolicySettingGroupPage';
import PolicySettingDomainPage from '@app/pages/DomainPages/PolicySettingDomainPage';

import ForgotPasswordChangePage from '@app/pages/ForgotPasswordChangePage';
import UnlockAccountConfirmPage from '@app/pages/UnlockAccountConfirm';
import UnlockAccountPage from '@app/pages/UnlockAccount';
import ForgotHMACPage from '@app/pages/ForgotHMACPage';
import history from './history';
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error403Page = React.lazy(() => import('@app/pages/Error403Page'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const ExternalLinkPage = React.lazy(() => import('@app/pages/ExternalLinkPage'));
const PermissionInfoPage = React.lazy(() => import('@app/pages/PermissionInfoPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const Logout = React.lazy(() => import('./Logout'));

const Dashboard = withLoading(DashboardPage);
const MemberList = withLoading(MemberListPage);
const EditMember = withLoading(EditMemberPage);
const AddMember = withLoading(AddMemberPage);
const RoleList = withLoading(RoleListPage);
const RoleSetting = withLoading(RoleSettingPage);
const MailSetting = withLoading(MailSettingPage);
const AddMailTemplate = withLoading(AddMailTemplatePage);
const ViewMailTemplate = withLoading(ViewMailTemplatePage);
const LogsSetting = withLoading(LogsSettingPage);
const GroupList = withLoading(GroupListPage);
const AddDomain = withLoading(AddDomainPage);
const EditDomain = withLoading(EditDomainPage);
const DomainList = withLoading(DomainListPage);
const AddGroup = withLoading(AddGroupPage);
const AddMultiGroup = withLoading(AddMultiGroupPage);
const AddMultiDomain = withLoading(AddMultiDomainPage);
const EditGroup = withLoading(EditGroupPage);
const PolicySetting = withLoading(PolicySettingPage);

const ServerError = withLoading(ServerErrorPage);
const Error403 = withLoading(Error403Page);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const ExternalLink = withLoading(ExternalLinkPage);
const PermissionInfo = withLoading(PermissionInfoPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path={'/'} element={protectedLayout}>
          <Route index element={<Navigate to="/dashboard" replace={true} />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="members" element={<MemberList />} />
          <Route path="members/add-user" element={<AddMember />} />
          <Route path="members/add-multi-user" element={<AddMember />} />
          <Route path="members/:action/:id" element={<EditMember />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="external-link" element={<ExternalLink />} />
            <Route path="permission" element={<PermissionInfo />} />
          </Route>
          <Route path="role" element={<RoleList />} />
          <Route path="role/:action" element={<RoleSetting />} />
          <Route path="role/:action/:id" element={<RoleSetting />} />
          <Route path="mail-setting" element={<MailSetting />} />
          <Route path="mail-setting/add" element={<AddMailTemplate />} />
          <Route path="mail-setting/:action/:id" element={<ViewMailTemplate />} />
          <Route path="logs" element={<LogsSetting />} />
          <Route path="groups" element={<GroupList />} />
          <Route path="groups/add" element={<AddGroup />} />
          <Route path="groups/add-multi" element={<AddMultiGroup />} />
          <Route path="groups/:action/:idGroup" element={<EditGroup />} />
          <Route path="groups/:action/:idGroup/policy-setting" element={<PolicySettingGroupPage />} />
          <Route path="policy-setting" element={<PolicySetting />} />
          <Route path="policy-setting/:action" element={<PolicySetting />} />
          <Route path="domain" element={<DomainList />} />
          <Route path="domain/add/:id" element={<AddDomain />} />
          <Route path="domain/add" element={<AddDomain />} />
          <Route path="domain/add-multi" element={<AddMultiDomain />} />
          <Route path="domain/:action/:idGroup/:idDomain" element={<EditDomain />} />
          <Route path="domain/:action/:idGroup/:idDomain/policy-setting" element={<PolicySettingDomainPage />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="*" element={<Error404 />} />
          <Route path="error403" element={<Error403 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="external-link" element={<ExternalLink />} />
            <Route path="new-password" element={<NewPasswordPage />} />
          </Route>
          <Route path="get-conversation" element={<GetConversationPage />} />
        </Route>
        <Route path="/" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="verify-email" element={<VerifyPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="forgot-hmac" element={<ForgotHMACPage />} />
          <Route path="forgot-password/change" element={<ForgotPasswordChangePage />} />
          <Route path="unlock" element={<UnlockAccountPage />} />
          <Route path="unlock/confirm" element={<UnlockAccountConfirmPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route
            path="reset-password"
            element={
              <RequireAuth>
                <NewPasswordPage />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </HistoryRouter>
  );
};
