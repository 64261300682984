import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Card } from '@app/components/common/Card/Card';
import { RadioChangeEvent } from '@app/components/common/Radio/Radio';
import * as S from '@app/components/common/Upload//Upload.styles';
import { UploadDragger } from '@app/components/common/Upload/Upload';
import { Button } from '@app/components/common/buttons/Button/Button';
import { readToken } from '@app/services/localStorage.service';
import { TypeUser } from '@app/utils/utils';
import { UploadProps, notification } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface AddUserFormProps {
  submit: (data: any) => void;
}
interface AddMultiUserFormData {
  firstName: string;
  lastName: string;
  email: string;
  group: string;
  permission: Array<string>;
}
export const initValues: AddMultiUserFormData = {
  firstName: '',
  lastName: '',
  email: '',
  group: '',
  permission: [],
};
export const AddMultiUserForm: React.FC<AddUserFormProps> = ({ submit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const headers: { label: string; key: string }[] = [
    { label: t('csvFile.fullName'), key: 'fullName' },
    { label: t('csvFile.type'), key: 'type' },
    { label: t('csvFile.groupName'), key: 'group' },
    { label: t('csvFile.domainName'), key: 'domain' },
    { label: t('csvFile.emailAddress'), key: 'email' },
    { label: t('csvFile.department'), key: 'department' },
    { label: t('csvFile.telephone'), key: 'telephone' },
    { label: t('csvFile.roles'), key: 'roles' },
    { label: t('csvFile.errors'), key: 'errors' },
  ];
  const dataTemplate = [
    [
      t('csvFile.fullName'),
      t('csvFile.type'),
      t('csvFile.groupName'),
      t('csvFile.domainName'),
      t('csvFile.emailAddress'),
      t('csvFile.department'),
      t('csvFile.telephone'),
      t('csvFile.roles'),
    ],
  ];

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_NX_CHAT_BOT_BASE_URL}/members/import?whereObject=${TypeUser.DOMAIN}`,
    headers: {
      Authorization: `Bearer ${readToken()}`,
    },
    onChange: (info: any) => {
      const { status } = info.file;
      if (status === 'done') {
        notification.success({ message: t('message.importSuccess') });
      }
      if (status === 'error') {
        const { response } = info.file;
        if (response?.statusCode == 401) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('expiresDate');
          localStorage.removeItem('expiresIn');
          navigate('/login');
        }
        notification.error({ message: info.file.response?.data?.message || t('message.error500') });
        return;
      }
    },
    beforeUpload(file: RcFile) {
      const isJpgOrPng =
        file.type === 'text/csv' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel';
      if (!isJpgOrPng) {
        notification.error({ message: 'You can only upload CSV file!' });
      }
      return isJpgOrPng;
    },
  };

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <>
      <div className="upload-members-container">
        <label>{t('members.titleUploadStep')}</label>
        <Card title={t('members.downloadCSVTemplate')} className="w-upload-container mt-2">
          <Button type="primary" onClick={() => document.getElementById('downloadTemplateBtn')?.click()}>
            {t('button.download')} <DownloadOutlined />
          </Button>
          <CSVLink
            id={'downloadTemplateBtn'}
            data={dataTemplate}
            filename="import_users_template.csv"
            target="_blank"
          />
        </Card>
        <Card padding="1.875rem" className="upload-multi-container mt-2" title={t('members.uploadCSVData')}>
          <UploadDragger
            accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            deniedMessage={t('message.csvOnly')}
            {...uploadProps}
            head={headers}
          >
            <S.DraggerIconWrapper>
              <InboxOutlined />
            </S.DraggerIconWrapper>
            <S.DraggerTitle>{t('button.selectFile')}</S.DraggerTitle>
            <S.DraggerDescription>{t('members.youMayUploadMoreRecords')}</S.DraggerDescription>
          </UploadDragger>
        </Card>
      </div>
    </>
  );
};
