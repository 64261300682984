import { Input as AntInput, InputProps as AntInputProps, InputRef } from 'antd';
import { InputNumber as AntInputNumber, InputNumberProps as AntInputNumberProps } from 'antd';

import React from 'react';
import * as S from './Input.styles';

export interface InputProps extends AntInputProps {
  className?: string;
}


export interface InputNumberProps extends AntInputNumberProps {
  className?: string;
}

export const { TextArea } = AntInput;

export const Input = React.forwardRef<InputRef, InputProps>(({ className, children, ...props }, ref) => {
  return (
    <S.Input
      ref={ref}
      className={className}
      {...props}
    >
      {children}
    </S.Input>
  );
},
);

export const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(({ className, children, ...props }, ref) => {
  return (
    <S.InputNumber
      ref={ref}
      className={className}
      {...props}
      onKeyDown={(evt) => {
        if (((evt.which > 64 && evt.which < 91) || evt.which > 105 || evt.which === 32) && !evt.nativeEvent.ctrlKey && !evt.nativeEvent.metaKey) {
          evt.preventDefault();
        }
      }}
    >
      {children}
    </S.InputNumber>
  );
},
);