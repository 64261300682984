import React, { useEffect, useState } from 'react';
import { TablePaginationConfig } from 'antd';
import { Modal } from '@app/components/common/Modal/Modal';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { EditOutlined, DeleteOutlined, StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { blockMember, deleteMember } from '@app/api/member.api';
import { Pagination } from '@app/api/table.api';
import { notification } from 'antd';
import { RolesPopup } from './Popup';
import { PaginationLocale } from 'antd/lib/pagination/Pagination';

interface TableListProps {
  dataList: any;
  changePagination: (data: any) => void;
  handleAfterSubmit: () => void;
  onRowClick?: (_data: any, _index: number | undefined) => void;
}

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
};

export const BasicTable: React.FC<TableListProps> = ({
  dataList,
  changePagination,
  handleAfterSubmit,
  onRowClick,
  ...props
}) => {
  const [tableData, setTableData] = useState<{ data: any; pagination?: Pagination; loading: boolean }>({
    data: null,
    pagination: initialPagination,
    loading: false,
  });
  const [isVisible, setIsVisible] = useState(false);
  const [itemDelete, setItemDelete] = useState({
    fullName: '',
    id: null,
  });
  const [isVisibleBlock, setIsVisibleBlock] = useState(false);
  const [itemBlock, setItemBlock] = useState({
    fullName: '',
    id: null,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setTableData({
      data: dataList.data,
      pagination: {
        showSizeChanger: true,
        current: dataList.page,
        total: dataList.total,
      },
      loading: false,
    });
  }, [dataList]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    changePagination(pagination);
  };

  const onSubmitSwitchStatus = (payload: any) => {
    blockMember(payload)
      .then((res: any) => {
        if (res?.statusCode == 200) {
          handleAfterSubmit();
          notification.success({ message: t('message.updateSuccess') });
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => onCancel());
  };

  const onSubmitDelete = (payload: any) => {
    deleteMember(payload)
      .then((res: any) => {
        if (res?.status === 'success') {
          handleAfterSubmit();
          notification.success({ message: t('message.deleteSuccess') });
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => onCancel());
  };

  const onCancel = () => {
    setIsVisible(false);
    setIsVisibleBlock(false);
  };

  const onDelete = (data: any) => {
    setIsVisible(true);
    setItemDelete(data);
  };

  const onActive = (data: any) => {
    setItemBlock(data);
    onSubmitSwitchStatus(data);
  };

  const onBlock = (data: any) => {
    setIsVisibleBlock(true);
    setItemBlock(data);
  };

  const columns: ColumnsType<any> = [
    {
      title: t('members.fullName'),
      dataIndex: 'fullName',
      showSorterTooltip: false,
      render: (text, props) => <a className="cursor-pointer">{text}</a>,
    },
    {
      title: t('members.emailAddress'),
      dataIndex: 'email',
      showSorterTooltip: false,
    },
    {
      title: t('members.type'),
      dataIndex: 'type',
      showSorterTooltip: false,
    },
    {
      title: t('members.group'),
      dataIndex: 'group',
      showSorterTooltip: false,
      render: (text, props) => (
        <>{props.nameOfGroup && <RolesPopup title={t('members.group')} dataList={props.nameOfGroup} />}</>
      ),
    },
    {
      title: t('members.domain'),
      dataIndex: 'domain',
      showSorterTooltip: false,
      render: (text, props) => (
        <>{props.nameOfDomain && <RolesPopup title={t('members.domain')} dataList={props.nameOfDomain} />}</>
      ),
    },
    {
      title: t('members.role'),
      dataIndex: 'role',
      showSorterTooltip: false,
      render: (text, props) => (
        <>{props.roles && <RolesPopup title={t('members.role')} dataList={props.roles.map((u: any) => u.name)} />}</>
      ),
    },
    {
      title: t('members.status'),
      width: 130,
      dataIndex: 'status',
      render: (text, props) => (
        <>
          {props.isActive ? (
            <div className="badge-success">{t('members.active')}</div>
          ) : (
            <div className="badge-danger">{t('members.suspended')}</div>
          )}
        </>
      ),
      showSorterTooltip: false,
    },
    {
      title: t('domain.action'),
      className: 'action-column',
      fixed: 'right',
      dataIndex: 'contents',
      width: '120px',
      render: (text, props) => (
        <>
          <EditOutlined
            className="btn-icon-save"
            onClick={(event) => {
              event.stopPropagation();
              navigate(`/members/edit/${props.id}/personal-info`);
            }}
          />
          <DeleteOutlined
            className="btn-icon-close ml-2"
            onClick={(event) => {
              event.stopPropagation();
              onDelete(props);
            }}
          />
          {props.isActive ? (
            <StopOutlined
              className="btn-icon-block ml-2"
              onClick={(event) => {
                event.stopPropagation();
                onBlock(props);
              }}
            />
          ) : (
            <CheckCircleOutlined
              className="btn-icon-active ml-2"
              onClick={(event) => {
                event.stopPropagation();
                onActive(props);
              }}
            />
          )}
        </>
      ),
      showSorterTooltip: false,
    },
  ];

  return (
    <>
      <Modal
        title={t('members.deleteMember')}
        visible={isVisible}
        onOk={() => onSubmitDelete(itemDelete)}
        onCancel={() => onCancel()}
        // cancelText={t('button.cancel')}
        // okText={t('button.ok')}
      >
        <p>
          {t('members.areYouSureWantToDeleteMember')} <strong>{itemDelete?.fullName}</strong>?
        </p>
      </Modal>
      <Modal
        title={t('members.blockMember')}
        visible={isVisibleBlock}
        onOk={() => onSubmitSwitchStatus(itemBlock)}
        onCancel={() => onCancel()}
        // cancelText={t('button.cancel')}
        // okText={t('button.ok')}
      >
        <p>
          {t('members.areYouSureWantToBlockMember')} <strong>{itemBlock?.fullName}</strong>?
        </p>
      </Modal>
      <Table
        className="event-table"
        columns={columns}
        onRow={(_data: any, _index: number | undefined) => {
          return {
            onClick: () => {
              if (onRowClick) {
                onRowClick(_data, _index);
              } else {
                navigate(`/members/view/${_data.id}/personal-info`);
              }
            },
          };
        }}
        dataSource={tableData.data}
        pagination={{ ...tableData.pagination, locale: { items_per_page: t('common.perPage') } }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
    </>
  );
};
