import { FC, ReactNode } from 'react';
import * as ReactDOM from 'react-dom';
import { Modal } from '@app/components/common/Modal/Modal';

interface IPropsModalComponent {
  isOpen: boolean;
  title?: ReactNode;
  children?: ReactNode;
  handleClose?: () => void;
  handleOk?: () => void;
  afterClose?: () => void;
}

export const ModalComponent: FC<IPropsModalComponent> = ({ children, isOpen, title, handleClose, handleOk }) => {
  return ReactDOM.createPortal(
    <Modal
      closable={false}
      title={title}
      cancelButtonProps={{ style: { display: !handleClose ? 'none' : 'inline-block' } }}
      okButtonProps={{ style: { display: !handleOk ? 'none' : 'inline-block' } }}
      visible={isOpen}
      onCancel={handleClose}
      onOk={handleOk}
    >
      {children}
    </Modal>,
    document.body,
  );
};
