/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Empty, TableProps } from 'antd';
import * as S from './Table.styles';
import './Table.less';
import { useTranslation } from 'react-i18next';

// TODO make generic!
export const Table: React.FC<TableProps<any>> = (props) => {
  const { t } = useTranslation();
  return (
    <S.Table
      {...props}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{t('common.noData')}</span>}></Empty>,
        ...props.locale,
      }}
    />
  );
};
