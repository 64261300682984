import { httpApiMock } from '@app/api/mocks/http.api.mock';
import { AuthData } from '@app/api/auth.api';
import { initValues } from '@app/components/auth/LoginForm/LoginForm';

const avatarImg = 'https://cliply.co/wp-content/uploads/2020/09/442008571_ARTIST_AVATAR_3D_400px.gif';

httpApiMock.onPost('login').reply((config) => {
  const data: AuthData = JSON.parse(config.data || '');
  return [
    200,
    {
      token: 'bearerToken',
      user: {
        id: 1,
        firstName: 'umematsu',
        lastName: 'jp',
        imgUrl: avatarImg,
        userName: '@john1989',
        email: {
          name: 'umematsu.jp@umematsu.com',
          verified: false,
        },
        phone: {
          number: '+18143519459',
          verified: true,
        },
        sex: 'male',
        birthday: '01/26/2022',
        lang: 'en',
        country: 'GB',
        city: 'London',
        address1: '14 London Road',
        zipcode: 5211,
        website: 'umematsu.com',
        socials: {
          twitter: '@umematsu_team',
          facebook: 'https://facebook.com/groups/1076577369582221',
          linkedin: 'https://linkedin.com/company/umematsu',
        },
      },
    },
  ];
});

httpApiMock.onPost('signUp').reply(200);

httpApiMock.onPost('forgotPassword').reply(200);

httpApiMock.onPost('verifySecurityCode').reply(200);

httpApiMock.onPost('setNewPassword').reply(200);
