import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import {
  getFrequently,
  getPeriod,
  getTemplateMailDetail,
  getTemplateMailType,
  patchTemplateMail,
} from '@app/api/mail.api';
import { Card } from '@app/components/common/Card/Card';
import { Loading } from '@app/components/common/Loading';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Option, Select } from '@app/components/common/selects/Select/Select';
import { RadioBtn } from '@app/components/header/components/settingsDropdown/settingsOverlay/SettingsOverlay/SettingsOverlay.styles';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { Col, Radio, Row, notification } from 'antd';
import { CKEditor } from 'ckeditor4-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const ViewMailTemplatePage: React.FC = () => {
  const { t } = useTranslation();
  const { id, action } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isAlert1, setIsAlert1] = useState(false);
  const [listFrequently, setListFrequently] = useState<any[]>([]);
  const [listPeriod, setListPeriod] = useState<any[]>([]);
  const [typeList, setTypeList] = useState([]);
  const [data, setData] = useState({
    type: '1',
  });
  const [content, setContent] = useState('');
  const changeContent = (data: any) => {
    setContent(data.editor.getData());
  };
  useEffect(() => {
    setIsEdit(action === 'edit');
  }, [action]);

  useEffect(() => {
    initLoad();
  }, []);

  const initLoad = async () => {
    await getFrequentlyPeriod();
    await getTemplateType();
    onGetTemplate();
  };

  const getTemplateType = () => {
    getTemplateMailType()
      .then((res: any) => {
        if (res?.status == 'success') {
          setTypeList(res.data);
        } else notification.error({ message: res.message });
      })
      .catch(() => notification.error({ message: t('message.error500') }));
  };

  const changeTemplateType = (data: any) => {
    setIsAlert1(data.target.value == 'alert1');
  };

  const onGetTemplate = () => {
    setIsLoading(true);
    setIsShow(false);
    getTemplateMailDetail(id)
      .then((res: any) => {
        if (res?.status == 'success') {
          setIsAlert1(res.data.type == 'alert1');
          setData(res.data);
          setContent(res.data.text);
        } else notification.error({ message: res.message });
      })
      .catch(() => notification.error({ message: t('message.error500') }))
      .finally(() => {
        setIsLoading(false);
        setIsShow(true);
      });
  };

  const handleSubmit = (_data: any) => {
    setIsLoading(true);
    const data = {
      name: _data.name,
      type: _data.templateType,
      text: content,
      subject: _data.subject,
      frequently: _data.frequently,
      period: _data.period,
    };
    patchTemplateMail(id, data)
      .then((res: any) => {
        if (res && res?.status == 'success') {
          notification.success({ message: res.data });
          navigate(`/mail-setting/view/${id}`);
        } else {
          notification.error({ message: res?.message || t('message.error500') });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFrequentlyPeriod = () => {
    getFrequently().then((res) => {
      const list = res?.data || [];
      setListFrequently(list);
    });
    getPeriod().then((res) => {
      const list = res?.data || [];
      setListPeriod(list);
    });
  };

  return (
    <>
      <PageTitle>{t('common.mailSetting')}</PageTitle>
      <Card padding={20} title={t('common.mailTemplate')}>
        {!isEdit ? (
          <Button
            className="edit-mail-template-btn z-index-10"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              // setIsEdit(true);
              navigate(`/mail-setting/edit/${id}`);
            }}
          >
            {t('button.edit')}
          </Button>
        ) : (
          ''
        )}
        {isShow ? (
          <Auth.FormWrapper className="p-0 overflow-hidden">
            <BaseForm
              confirmSubmitProps={{ active: true }}
              layout="vertical"
              onFinish={handleSubmit}
              requiredMark="optional"
              initialValues={data}
            >
              <Row gutter={[30, 0]}>
                <Col xs={24} md={12}>
                  <Auth.FormItem name="templateType" label={t('mail.templateType')}>
                    <Radio.Group defaultValue={data?.type} disabled className="text-left">
                      {typeList.map((field) => (
                        <RadioBtn value={field}>{field}</RadioBtn>
                      ))}
                    </Radio.Group>
                  </Auth.FormItem>
                </Col>
                <Col xs={24} md={12}></Col>
                <Col xs={24} md={12}>
                  <Auth.FormItem
                    name="name"
                    label={t('mail.name')}
                    rules={[
                      {
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      { max: 255, message: t('validator.maxLength', { count: 255 }) },
                    ]}
                  >
                    <Auth.FormInput disabled={!isEdit} placeholder={t('mail.name')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} md={12}>
                  <Auth.FormItem
                    name="subject"
                    label={t('mail.subject')}
                    rules={[
                      {
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      { max: 255, message: t('validator.maxLength', { count: 255 }) },
                    ]}
                  >
                    <Auth.FormInput disabled={!isEdit} placeholder={t('mail.subject')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} md={24}>
                  <Auth.FormItem
                    name="content"
                    label={t('mail.content')}
                    rules={[
                      () => ({
                        validator() {
                          if (!content || content === '') {
                            return Promise.reject(new Error(t('common.requiredField')));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <CKEditor
                      debug={true}
                      name="contentMail"
                      initData={content}
                      readOnly={!isEdit}
                      type={'classic'}
                      onChange={(data) => {
                        changeContent(data);
                      }}
                      style={{ borderColor: 'none' }}
                    />
                  </Auth.FormItem>
                </Col>
                {isAlert1 && (
                  <>
                    <Col xs={24} md={12}>
                      <Auth.FormItem
                        name="frequently"
                        label={t('mail.frequently')}
                        rules={[
                          {
                            validator: (_, value) => {
                              return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('common.requiredField')));
                            },
                          },
                          {
                            required: true,
                            validator: (_, value) => {
                              return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('common.requiredField')));
                            },
                          },
                        ]}
                      >
                        <Select disabled={!isEdit} placeholder={t('mail.pleaseChooseFrequently')}>
                          {listFrequently.map((field: any) => (
                            <Option value={field}>{field}</Option>
                          ))}
                        </Select>
                      </Auth.FormItem>
                    </Col>
                    <Col xs={24} md={12}>
                      <Auth.FormItem
                        name="period"
                        label={t('mail.period')}
                        rules={[
                          {
                            validator: (_, value) => {
                              return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('common.requiredField')));
                            },
                          },
                          {
                            required: true,
                            validator: (_, value) => {
                              return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('common.requiredField')));
                            },
                          },
                        ]}
                      >
                        <Select disabled={!isEdit} placeholder={t('mail.pleaseChoosePeriod')}>
                          {listPeriod.map((field: any) => (
                            <Option value={field}>{field}</Option>
                          ))}
                        </Select>
                      </Auth.FormItem>
                    </Col>
                  </>
                )}
                {isEdit && (
                  <Col xs={24} md={24}>
                    <BaseForm.Item noStyle>
                      <Button type="primary" htmlType="submit" loading={isLoading}>
                        {t('button.save')} <SaveOutlined />
                      </Button>
                    </BaseForm.Item>
                  </Col>
                )}
              </Row>
            </BaseForm>
          </Auth.FormWrapper>
        ) : (
          <Loading />
        )}
      </Card>
    </>
  );
};

export default ViewMailTemplatePage;
