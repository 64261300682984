import React, { useEffect, useState, useCallback } from 'react';
import { TablePaginationConfig } from 'antd';
import { GroupTableRow, Pagination } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Modal } from '@app/components/common/Modal/Modal';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { deleteTemplateMail } from '@app/api/mail.api';
import { notification } from 'antd';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
};

interface TableListProps {
  dataList: any;
  changePagination: (data: any) => void;
  handleAfterSubmit: () => void;
  onRowClick?: (_data: any, _index: number | undefined) => void;
  page: number;
  size: number;
}

export const BasicTable: React.FC<TableListProps> = ({
  dataList,
  changePagination,
  onRowClick,
  handleAfterSubmit,
  page,
  size,
}) => {
  const [tableData, setTableData] = useState<{ data: GroupTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: {
      showSizeChanger: true,
      current: page,
      pageSize: size,
    },
    loading: false,
  });
  const [isVisible, setIsVisible] = useState(false);
  const [itemDelete, setItemDelete] = useState({
    name: '',
    id: null,
  });
  const [isVisibleBlock, setIsVisibleBlock] = useState(false);
  const [itemBlock, setItemBlock] = useState({
    name: '',
    id: null,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setTableData({
      data: dataList.data,
      pagination: {
        showSizeChanger: true,
        current: dataList.page,
        total: dataList.total,
        pageSize: size,
      },
      loading: false,
    });
  }, [dataList, page, size]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    changePagination(pagination);
  };

  const onSubmitDelete = () => {
    const payload = {
      userId: itemDelete.id,
    };
    deleteTemplateMail(payload).then((res: any) => {
      if (res?.status == 'success') {
        notification.success({ message: res.data || t('message.deleteSuccess') });
        setIsVisible(false);
        handleAfterSubmit();
      } else {
        notification.error({ message: res?.message || t('message.error500') });
      }
    });
  };

  const onEdit = (data: any) => {
    navigate(`edit/${data.id}`);
  };

  const onView = (data: any) => {
    navigate(`view/${data.id}`);
  };

  const onCancel = () => {
    setIsVisible(false);
    setIsVisibleBlock(false);
  };

  const onDelete = (data: any) => {
    setIsVisible(true);
    setItemDelete(data);
  };

  const columns: ColumnsType<any> = [
    {
      title: t('mail.templateName'),
      dataIndex: 'name',
      showSorterTooltip: false,
      render: (text, props) => (
        <a className="cursor-pointer" onClick={() => onEdit(props)}>
          {text}
        </a>
      ),
    },
    {
      title: t('mail.templateType'),
      dataIndex: 'type',
      showSorterTooltip: false,
      width: '20%',
    },
    {
      title: t('groups.action'),
      className: 'action-column',
      dataIndex: 'contents',
      fixed: 'right',
      width: 120,
      render: (text: any, props: any) => (
        <>
          <EditOutlined
            className="btn-icon-save"
            onClick={(event) => {
              event.stopPropagation();
              onEdit(props);
            }}
          />
          <DeleteOutlined
            className="btn-icon-close ml-2"
            onClick={(event) => {
              event.stopPropagation();
              onDelete(props);
            }}
          />
        </>
      ),
      showSorterTooltip: false,
    },
  ];

  return (
    <>
      <Modal
        title={t('mail.deleteMailTemplate')}
        visible={isVisible}
        onOk={() => onSubmitDelete()}
        onCancel={() => onCancel()}
        cancelText={t('button.cancel')}
        okText={t('button.ok')}
      >
        <p>
          {t('mail.areYouSureWantToDeleteMailTemplate')} <strong>{itemDelete?.name}</strong> ?
        </p>
      </Modal>
      <Table
        className="event-table"
        columns={columns}
        dataSource={tableData.data}
        pagination={{ ...tableData.pagination, locale: { items_per_page: t('common.perPage') } }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
        onRow={(_data: any, _index: number | undefined) => {
          return {
            onClick: () => {
              if (onRowClick) {
                onRowClick(_data, _index);
              } else {
                onView(_data);
              }
            },
          };
        }}
      />
    </>
  );
};
