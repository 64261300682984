import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
// import { References } from '@app/components/common/References/References';
import { readToken, readExpiresDate, logout } from '@app/services/localStorage.service';
import { useTranslation } from 'react-i18next';
import { setDirty } from '@app/store/dirty/dirty';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { ConfirmModal } from '@app/components/common/Modal/Modal';

const MainLayout: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const location = useLocation();

  useLayoutEffect(() => {
    const fb = decodeURIComponent(localStorage.getItem('fallback') || '');
    if (user && user?.isPasswordReset) {
      navigate('/reset-password');
    } else if (!!fb && fb.includes(location.pathname)) {
      localStorage.removeItem('fallback');
      setTimeout(() => navigate(fb), 1000);
    }
  }, [user?.isPasswordReset, location.pathname]);

  const params: any = useParams();
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const [isTwoColumnsLayout] = useState(false);
  const [lengthRoute, setLengthRoute] = useState(0);
  const dirty = useAppSelector((state) => state.dirty.dirty);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  const pathNameRoute = [
    // {
    //   path: '/',
    //   name: t('common.dashboard'),
    // },
    {
      path: '/members',
      name: t('common.membersList'),
    },
    {
      path: '/members/add-user',
      name: t('common.addMember'),
    },
    {
      path: '/members/add-multi-user',
      name: t('common.importMember'),
    },
    {
      path: '/members/edit',
      name: t('common.editMember'),
    },
    {
      path: '/members/view',
      name: t('common.viewMember'),
    },
    {
      path: '/profile',
      name: t('common.profile'),
    },
    {
      path: '/profile/view',
      name: t('common.profile'),
    },
    {
      path: '/groups',
      name: t('common.groupsList'),
    },
    {
      path: '/groups/add',
      name: t('groups.addGroup'),
    },
    {
      path: '/groups/edit',
      name: t('common.editGroup'),
    },
    {
      path: '/groups/view',
      name: t('common.viewGroup'),
    },
    {
      path: '/groups/add-multi',
      name: t('groups.addMultiGroup'),
    },
    {
      path: '/domain',
      name: t('groups.domainList'),
    },
    {
      path: '/domain/add',
      name: t('common.addDomain'),
    },
    {
      path: '/domain/add-multi',
      name: t('common.importDomain'),
    },
    {
      path: '/domain/edit',
      name: t('common.editDomain'),
    },
    {
      path: '/domain/view',
      name: t('common.viewDomain'),
    },
    {
      path: '/logs',
      name: t('common.logsList'),
    },
    {
      path: '/role',
      name: t('common.roleList'),
    },
    {
      path: '/role/create',
      name: t('common.addRole'),
    },
    {
      path: '/role/edit',
      name: t('role.editRole'),
    },
    {
      path: '/role/view',
      name: t('role.viewRole'),
    },
    {
      path: '/mail-setting',
      name: t('common.mailSetting'),
    },
    {
      path: '/mail-setting/edit',
      name: t('common.editMailTemplate'),
    },
    {
      path: '/mail-setting/view',
      name: t('common.viewMailTemplate'),
    },
    {
      path: '/mail-setting/add',
      name: t('common.addMailTemplate'),
    },
    {
      path: '/policy-setting',
      name: t('common.policySetting'),
    },
    {
      path: '/policy-setting/edit',
      name: t('common.editPolicySetting'),
    },
  ];

  useEffect(() => {
    setLengthRoute(pathNameRoute.filter((item) => location.pathname.includes(item.path)).length);
    if (new Date(readExpiresDate()) < new Date() || !readToken() || readToken() == 'bearerToken') {
      setTimeout(() => {
        navigate('/login');
      });
    }
  }, [location.pathname]);

  const checkUrlBeforeRedirect = (url: string): string => {
    const { idGroup, idDomain } = params;
    if (url.includes('groups/edit')) {
      return url + '/' + idGroup;
    }
    if (url.includes('domain/edit')) {
      return url + '/' + idGroup + '/' + idDomain;
    }
    return url;
  };

  const checkDirtyBeforeRedirect = (url: string) => {
    const redirectUrl = checkUrlBeforeRedirect(url);
    if (dirty) {
      const handleDirty = () => {
        navigate(redirectUrl);
        dispatch(setDirty(false));
      };
      ConfirmModal({
        title: t('common.titleConfirmation'),
        content: t('message.changesMayNotHaveBeenSaved'),
        onOk: handleDirty,
        cancelText: t('button.no'),
        okText: t('button.yes'),
      });
    } else {
      navigate(redirectUrl);
    }
  };

  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} toggleSider={toggleSider} />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <div className="breadcrumb-container">
              <Breadcrumb>
                <BreadcrumbItem onClick={() => checkDirtyBeforeRedirect('/')}>{t('common.dashboard')}</BreadcrumbItem>
                {pathNameRoute
                  .filter((item) => location.pathname.includes(item.path))
                  .map((item, index) => (
                    <BreadcrumbItem onClick={() => lengthRoute != index + 1 && checkDirtyBeforeRedirect(item.path)}>
                      {item.name}
                    </BreadcrumbItem>
                  ))}
              </Breadcrumb>
            </div>
            <Outlet />
          </div>
          {/* {!isTwoColumnsLayout && <References />} */}
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
