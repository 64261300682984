import React, { ComponentProps } from 'react';
import { Form, FormItemProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { BaseFormTitle } from '@app/components/common/forms/components/BaseFormTitle/BaseFormTitle';
import { BaseFormItem } from '@app/components/common/forms/components/BaseFormItem/BaseFormItem';
import { BaseFormList } from '@app/components/common/forms/components/BaseFormList/BaseFormList';
import { IConfirmProps, ConfirmModal } from '@app/components/common/Modal/Modal';
import { setDirty } from '@app/store/dirty/dirty';
import { useAppDispatch } from '@app/hooks/reduxHooks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BaseFormProps = Omit<ComponentProps<typeof Form>, 'onFinish'> & {
  onFinish?: (values: any) => void;
  confirmSubmitProps?: IConfirmProps;
};

export interface BaseFormInterface<T> extends React.FC<T> {
  Title: typeof BaseFormTitle;
  Item: typeof BaseFormItem;
  List: typeof BaseFormList;
  useForm: typeof Form.useForm;
  Provider: typeof Form.Provider;
}

export const BaseForm: BaseFormInterface<BaseFormProps> = ({
  onFinishFailed,
  onFinish,
  confirmSubmitProps,
  layout = 'vertical',
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onFinishFailedDefault = (error: ValidateErrorEntity<unknown>) => {
    dispatch(setDirty(true));
  };

  const setFieldsChanged = (data: any) => {
    data && dispatch(setDirty(true));
  };

  const eventFinish = (e: any) => {
    const handleFinish = () => {
      dispatch(setDirty(false));
      onFinish && onFinish(e);
    };
    const props = {
      active: confirmSubmitProps?.active || false,
      title: confirmSubmitProps?.title || t('common.titleConfirmation'),
      content: confirmSubmitProps?.content || t('common.contentOkConfirm'),
      okText: confirmSubmitProps?.okText || t('button.yes'),
      cancelText: confirmSubmitProps?.cancelText || t('button.no'),
      onOk: handleFinish,
    };
    if (props?.active) ConfirmModal(props);
    else handleFinish();
  };

  return (
    <Form
      onFinish={eventFinish}
      scrollToFirstError
      onFinishFailed={onFinishFailed || onFinishFailedDefault}
      onValuesChange={(data) => setFieldsChanged(data)}
      layout={layout}
      {...props}
      requiredMark={true}
    />
  );
};

BaseForm.Title = BaseFormTitle;
BaseForm.Item = BaseFormItem;
BaseForm.List = BaseFormList;
BaseForm.useForm = Form.useForm;
BaseForm.Provider = Form.Provider;
