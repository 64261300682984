import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Card } from '@app/components/common/Card/Card';
import * as S from '@app/components/common/Upload//Upload.styles';
import { UploadDragger } from '@app/components/common/Upload/Upload';
import { Button } from '@app/components/common/buttons/Button/Button';
import { readToken } from '@app/services/localStorage.service';
import { TypeUser } from '@app/utils/utils';
import { notification } from 'antd';
import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AddMultiDomainPage: React.FC<any> = ({}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const refCsvLink = useRef<any>();
  const [dataExport, setDataExport] = useState<any>([]);
  const headers: { label: string; key: string }[] = [
    { label: t('csvFile.domainName'), key: 'domainName' },
    { label: t('csvFile.companyName'), key: 'companyName' },
    { label: t('csvFile.groupName'), key: 'groupName' },
    { label: t('csvFile.emailAddress'), key: 'mailAccount' },
    { label: t('csvFile.subEmailAddress'), key: 'subMailAccount' },
    { label: t('csvFile.department'), key: 'department' },
    { label: t('csvFile.nameOfDirector'), key: 'representativeName' },
    { label: t('csvFile.telephone'), key: 'telephone' },
    { label: t('csvFile.subTelephone'), key: 'subTelephone' },
    { label: t('csvFile.numberPostOffice'), key: 'numberPostOffice' },
    { label: t('csvFile.addressCity'), key: 'addressCity' },
    { label: t('csvFile.addressWardStreet'), key: 'addressWardStreet' },
    { label: t('csvFile.settingDate'), key: 'settingDate' },
    { label: t('csvFile.errors'), key: 'errors' },
  ];
  const dataTemplate = [
    [
      t('csvFile.domainName'),
      t('csvFile.companyName'),
      t('csvFile.groupName'),
      t('csvFile.emailAddress'),
      t('csvFile.subEmailAddress'),
      t('csvFile.department'),
      t('csvFile.nameOfDirector'),
      t('csvFile.telephone'),
      t('csvFile.subTelephone'),
      t('csvFile.numberPostOffice'),
      t('csvFile.addressCity'),
      t('csvFile.addressWardStreet'),
      t('csvFile.settingDate'),
    ],
  ];

  const uploadProps = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_NX_CHAT_BOT_BASE_URL}/domains/import?whereObject=${TypeUser.DOMAIN}`,
    headers: {
      Authorization: `Bearer ${readToken()}`,
    },
    onChange: (info: any) => {
      const { status } = info.file;
      if (status === 'error') {
        if (info.file.response?.statusCode == 401) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('expiresDate');
          localStorage.removeItem('expiresIn');
          navigate('/login');
        }
        notification.error({ message: info.file.response?.data?.message || t('message.error500') });
        return;
      }
      if (status === 'done') {
        notification.success({ message: t('message.importSuccess') });
      }
    },
  };

  return (
    <>
      <div className="upload-members-container">
        <div className="upload-group-container">
          <label>{t('domain.titleUploadStep')}</label>
          <Card title={t('members.downloadCSVTemplate')} className="w-upload-container mt-2">
            <Button type="primary" onClick={() => document.getElementById('downloadTemplateBtn')?.click()}>
              {t('button.download')} <DownloadOutlined />
            </Button>
            <CSVLink
              id={'downloadTemplateBtn'}
              data={dataTemplate}
              filename="import_domains_template.csv"
              target="_blank"
            />
          </Card>
          <Card padding="1.875rem" className="upload-multi-container mt-2" title={t('members.uploadCSVData')}>
            <UploadDragger
              accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              deniedMessage={t('message.csvOnly')}
              {...uploadProps}
              head={headers}
            >
              <S.DraggerIconWrapper>
                <InboxOutlined />
              </S.DraggerIconWrapper>
              <S.DraggerTitle>{t('button.selectFile')}</S.DraggerTitle>
              <S.DraggerDescription>{t('members.youMayUploadMoreRecords')}</S.DraggerDescription>
            </UploadDragger>
          </Card>
          <CSVLink
            data={dataExport}
            headers={headers}
            target="_blank"
            filename={`import_domains_error_${Date.now()}.csv`}
            ref={refCsvLink}
          />
        </div>
      </div>
    </>
  );
};
export default AddMultiDomainPage;
