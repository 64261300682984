import { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DomainTable } from '@app/components/domains/domainTable/DomainTable';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { getDomainsRequest, IDomain, IDomainsRequest, IDomainsResponse } from '@app/api/domain.api';
import { Pagination } from '@app/api/table.api';
import notification from 'antd/lib/notification';

interface IPropsDomainsPage {
  groupId?: number;
  hiddenGroupSearched?: boolean;
}

const DomainListPage: FC<IPropsDomainsPage> = ({ groupId: groupIdProps, hiddenGroupSearched }) => {
  const { t } = useTranslation();
  const [domains, setDomains] = useState<IDomainsResponse>();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [groupId, setGroupId] = useState(groupIdProps);
  const [search, setSearch] = useState('');

  useEffect(() => {
    onSearchDomains({
      limit,
      page,
      groupId,
      search,
    });
  }, [limit, page, groupId, search]);

  const onSearchDomains = async ({ page, limit, search, groupId }: IDomainsRequest) => {
    await getDomainsRequest({ page, limit, search, groupId })
      .then((res: any) => {
        if (res && res.data && res.statusCode === 200) {
          setDomains(res.data);
        }
      })
      .catch((e) => notification.error({ message: e.message }));
  };

  const changePagination = ({ current, pageSize }: Pagination) => {
    if (current && pageSize) {
      setPage(current);
      setLimit(pageSize);
    }
  };

  const changeKeyword = (_search: string) => {
    setSearch(_search);
    setPage(1);
  };

  const changeGroup = (_groupId: number) => {
    setGroupId(_groupId);
  };

  return (
    <>
      <PageTitle>{t('common.domain')}</PageTitle>
      <DomainTable
        handleAfterSubmit={() => onSearchDomains({
          limit,
          page,
          groupId,
          search,
        })}
        groupId={groupId}
        dataList={domains}
        changePagination={(data) => changePagination(data)}
        changeKeyword={(data) => changeKeyword(data)}
        changeGroup={(data) => changeGroup(data)}
        hiddenGroupSearched={hiddenGroupSearched}
      />
    </>
  );
};

export default DomainListPage;
