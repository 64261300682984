import React, { useEffect, useState, useCallback } from 'react';
import { TablePaginationConfig } from 'antd';
import { Pagination } from 'api/table.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { deleteRole, IDeleteRole } from '@app/api/role.api';
import { notification } from 'antd';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
};

interface TableListProps {
  dataList: any;
  changePagination: (data: any) => void;
  onRowClick?: (_data: any, _index: number | undefined) => void;
  handleAfterSubmit: () => void;
}

export const BasicTable: React.FC<TableListProps> = ({ dataList, changePagination, onRowClick, handleAfterSubmit }) => {
  const [tableData, setTableData] = useState<{ data: any; pagination: any; loading: boolean }>({
    data: null,
    pagination: initialPagination,
    loading: false,
  });
  const [isVisible, setIsVisible] = useState(false);
  const [itemDelete, setItemDelete] = useState<IDeleteRole>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setTableData({
      data: dataList.data,
      pagination: {
        showSizeChanger: true,
        current: dataList.page,
        total: dataList.total,
      },
      loading: false,
    });
  }, [dataList]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    changePagination(pagination);
  };

  const onSubmitDelete = () => {
    itemDelete &&
      deleteRole(itemDelete)
        .then((res: any) => {
          if (res?.status == 'success') {
            handleAfterSubmit();
            notification.success({ message: t('message.deleteSuccess') });
          }
        })
        .catch((e: any) => notification.error({ message: e.message }))
        .finally(() => {
          setIsVisible(false);
        });
  };

  const onCancel = () => {
    setIsVisible(false);
  };

  const onDelete = (data: any) => {
    setIsVisible(true);
    setItemDelete(data);
  };

  const columns: ColumnsType<any> = [
    {
      title: t('role.roleType'),
      dataIndex: 'type',
      width: '20%',
      render: (text, props) => <span>{props.type}</span>,
      // sorter: (a: MemberTableRow, b: MemberTableRow) => a.fullName - b.fullName,
      showSorterTooltip: false,
    },
    {
      title: t('role.roleName'),
      dataIndex: 'name',
      // sorter: (a: MemberTableRow, b: MemberTableRow) => a.emailAddress - b.emailAddress,
      showSorterTooltip: false,
      render: (text) => <a className="cursor-pointer">{text}</a>,
    },
    {
      title: t('role.action'),
      className: 'action-column',
      fixed: 'right',
      dataIndex: 'contents',
      width: '120px',
      render: (text, props) =>
        !props.isDefault && (
          <>
            <EditOutlined
              className="btn-icon-save"
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/role/edit/${props.id}`);
              }}
            />
            <DeleteOutlined
              className="btn-icon-close ml-2"
              onClick={(event) => {
                event.stopPropagation();
                onDelete(props);
              }}
            />
          </>
        ),
      showSorterTooltip: false,
    },
  ];

  return (
    <>
      <Modal
        title={t('role.deleteRole')}
        visible={isVisible}
        onOk={() => onSubmitDelete()}
        onCancel={() => onCancel()}
        cancelText={t('button.cancel')}
        okText={t('button.ok')}
      >
        <p>
          {t('role.areYouSureWantToDeleteRole')} <strong>{itemDelete?.name}</strong> ?
        </p>
      </Modal>
      <Table
        columns={columns}
        onRow={(_data: any, _index: number | undefined) => {
          return {
            onClick: () => {
              if (onRowClick) {
                onRowClick(_data, _index);
              } else {
                navigate(`/role/view/${_data.id}`);
              }
            },
          };
        }}
        className="mt-2 event-table"
        dataSource={tableData.data}
        pagination={{ ...tableData.pagination, locale: { items_per_page: t('common.perPage') } }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
    </>
  );
};
