import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { PasswordPolicyGroup } from '@app/components/policySetting';
import { Card } from '@app/components/common/Card/Card';

const PolicySettingGroupPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('policy.policySetting')}</PageTitle>
      <Card padding="1.25rem" title={t('policy.policySetting')}>
        <PasswordPolicyGroup />
      </Card>
    </>
  );
};

export default PolicySettingGroupPage;
