import { UserModel } from '@app/domain/UserModel';
import { setGroup } from '@app/hooks/useGroup';
import { setDomain } from '@app/hooks/useDomain';
import { notification } from 'antd';
import { postLogout } from '@app/api/login.api';

const avatarImg = 'https://cliply.co/wp-content/uploads/2020/09/442008571_ARTIST_AVATAR_3D_400px.gif';

const testUser = {
  id: 1,
  firstName: 'umematsu',
  lastName: 'jp',
  imgUrl: avatarImg,
  userName: '@john1989',
  email: {
    name: 'umematsu.jp@umematsu.com',
    verified: true,
  },
  phone: {
    number: '+18143519459',
    verified: true,
  },
  sex: 'male',
  birthday: '01/26/2022',
  lang: 'en',
  country: 'GB',
  city: 'London',
  address1: '14 London Road',
  zipcode: 5211,
  website: 'umematsu.com',
  socials: {
    twitter: '@umematsu_team',
    facebook: 'https://facebook.com/groups/1076577369582221',
    linkedin: 'https://linkedin.com/company/umematsu',
  },
};

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const readToken = (): string | null => {
  return localStorage.getItem('accessToken');
};

export const persistExpiresIn = (date: string): void => {
  localStorage.setItem('expiresIn', date);
};

export const readExpiresIn = (): string => {
  return localStorage.getItem('expiresIn') || '';
};

export const readLanguage = (): string => {
  return localStorage.getItem('x-language-code') || 'en';
};

export const persistExpiresDate = (date: string): void => {
  localStorage.setItem('expiresDate', '11/24/2024, 12:56:02 PM');
};

export const persistProfile = (data: any): void => {
  localStorage.setItem('profile', JSON.stringify(data));
  setGroup(data.groups[0]?.id);
  setDomain(data.domains[0]?.id);
};

export const readProfile = (): string => {
  return localStorage.getItem('profile') || '{"domains": [], "groups": [], "permission": []}';
};

export const isAdmin = (): boolean => {
  return JSON.parse(readProfile()).user?.isAdmin || false;
};

export const readPermission = (): string => {
  return JSON.parse(readProfile()).user?.permissions || [];
};

export const readExpiresDate = (): string => {
  return localStorage.getItem('expiresDate') || '';
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');
  return userStr ? JSON.parse(userStr) : testUser;
};

export const logout = async () => {
  return await postLogout().then((res) => {
    localStorage.removeItem('expiresDate');
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('accessToken');
  });
};

export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteUser = (): void => localStorage.removeItem('user');
