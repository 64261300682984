import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { SendOutlined } from '@ant-design/icons';
import { Tabs, TabPane } from '@app/components/common/Tabs/Tabs';
import { Card } from '@app/components/common/Card/Card';
import TemplateMailListPage from './TemplateMailListPage';
import SendMailPage from './SendMailPage';
import { EditMailSetting } from './EditMailSettingPage';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { RootState } from '@app/store/store';
import { ConfirmModal } from '@app/components/common/Modal/Modal';
import { setDirty } from '@app/store/dirty/dirty';

const MailSettingPage: React.FC = () => {
  const { t } = useTranslation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const dirty = useAppSelector((state: RootState) => state.dirty.dirty);
  const [tab, setTab] = useState(urlParams.get('tab') || '1');
  const dispatch = useAppDispatch();

  const checkDirtyBeforeRedirect = (activeKey: string) => {
    if (dirty) {
      const handleDirty = () => {
        dispatch(setDirty(false));
        setTab(activeKey);
      };
      ConfirmModal({
        title: t('common.titleConfirmation'),
        content: t('message.changesMayNotHaveBeenSaved'),
        onOk: handleDirty,
        cancelText: t('button.no'),
        okText: t('button.yes'),
      });
    } else {
      setTab(activeKey);
    }
  };

  return (
    <>
      <PageTitle>{t('common.mailSetting')}</PageTitle>
      <Card padding={20}>
        <Tabs
          onTabClick={(activeKey: string) => {
            checkDirtyBeforeRedirect(activeKey);
          }}
          activeKey={tab}
        >
          <TabPane tab={<span>{t('mail.mailSetting')}</span>} key="1">
            {tab === '1' && <EditMailSetting />}
          </TabPane>
          <TabPane tab={<span>{t('mail.templateMailList')}</span>} key="2">
            {tab === '2' && <TemplateMailListPage />}
          </TabPane>
          <TabPane
            tab={
              <span>
                {t('mail.sendMail')} <SendOutlined />
              </span>
            }
            key="3"
          >
            {tab === '3' && <SendMailPage />}
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default MailSettingPage;
