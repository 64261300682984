import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import * as S from './CountriesItem.styles';

const countries = require('language-list')().getData();

const selectOptions = countries
  .filter((c: any) => c.code === 'ja')
  .map((country: any) => (
    <Option key={country.name} value={country.code}>
      <S.CountriesSpace align="center">{country.language}</S.CountriesSpace>
    </Option>
  ));

export const CountriesItem: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BaseButtonsForm.Item
      name="language"
      label={t('dashboard.language')}
      rules={[
        {
          required: true,
          validator: (_, value) => {
            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
              ? Promise.resolve()
              : Promise.reject(new Error(t('common.requiredField')));
          },
        },
      ]}
    >
      <Select
        showSearch
        filterOption={(input, option) => {
          return (option?.children?.props?.children.toLowerCase() ?? '').includes(input.toLowerCase());
        }}
      >
        {selectOptions}
      </Select>
    </BaseButtonsForm.Item>
  );
};
