import { callApi, METHOD } from '@app/api/http.api';
import i18n from '@app/i18n';
import { ILogObject, objToQueryString, toTypeUser, TypeUser } from '@app/utils/utils';

export interface IDeleteRole {
  id: number;
  name: string;
}

export const getRoleList = async (data: any) => {
  try {
    let response: any = await callApi(METHOD.GET, 'roles', data);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getMemberRoleList = async (data: any) => {
  try {
    let response: any = await callApi(METHOD.POST, 'members/get-role-assign', data);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getRolesPermissions = async (data: any) => {
  try {
    let response: any = await callApi(METHOD.POST, 'roles/permissions', data);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getRolesSetting = async (typeAction: string) => {
  try {
    let response: any = await callApi(METHOD.GET, `roles/setting?typeAction=${typeAction}`, null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const patchRole = async (id: string, data: any) => {
  try {
    const { resourceId, whereName, name, type } = data;
    const query: ILogObject = {
      objectName: name,
      whereId: resourceId || 0,
      whereObject: toTypeUser(type) as TypeUser,
      whereName: whereName,
    };
    let response: any = await callApi(METHOD.PATCH, `roles/${id}` + `${objToQueryString(query)}`, data);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const postRole = async (data: any) => {
  try {
    const { resourceId, name, whereName, type } = data;
    const query: ILogObject = {
      objectName: name,
      whereId: resourceId || 0,
      whereObject: toTypeUser(type) as TypeUser,
      whereName,
    };
    let response: any = await callApi(METHOD.POST, `roles` + `${objToQueryString(query)}`, data);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getRole = async (data: any) => {
  try {
    let response: any = await callApi(METHOD.GET, `roles/${data.id}`, null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const deleteRole = async (data: any): Promise<{ data: any[] } | undefined> => {
  try {
    const { id, resourceId, name, type } = data;
    const query: ILogObject = {
      objectName: name,
      whereId: resourceId || 0,
      whereObject: toTypeUser(type) as TypeUser,
      whereName: name,
    };
    let response: any = await callApi(METHOD.DELETE, `roles/${id}` + `${objToQueryString(query)}`, { name });
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getUsersByUser = async () => {
  try {
    let response: any = await callApi(METHOD.GET, 'members/management', null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getRolesByUser = async () => {
  try {
    let response: any = await callApi(METHOD.GET, 'roles/management', null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};
