import React, { useEffect, useState } from 'react';
import { TablePaginationConfig } from 'antd';
import { Pagination } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { formatDate } from '@app/utils/utils';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
};

interface LogProps {
  dataList: any;
  changePagination: (data: any) => void;
}

export const BasicTable: React.FC<LogProps> = ({ dataList, changePagination }) => {
  const [tableData, setTableData] = useState<{ data: any[]; pagination: any; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();

  useEffect(() => {
    setTableData({
      data: dataList.data,
      pagination: {
        showSizeChanger: true,
        current: dataList.page,
        total: dataList.total,
      },
      loading: false,
    });
  }, [dataList]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    changePagination(pagination);
  };

  const columns: ColumnsType<any> = [
    {
      title: t('log.date'),
      dataIndex: 'createdAt',
      render: (_, props) => <>{formatDate(props.createdAt)}</>,
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: t('log.fullName'),
      dataIndex: 'fullName',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: t('log.status'),
      dataIndex: 'status',
      showSorterTooltip: false,
      width: '10%',
    },
    {
      title: t('log.action'),
      dataIndex: 'action',
      showSorterTooltip: false,
      width: '10%',
    },
    {
      title: t('log.data'),
      dataIndex: 'data',
      showSorterTooltip: false,
      width: '30%',
    },
    {
      title: t('log.email'),
      dataIndex: 'email',
      showSorterTooltip: false,
      width: '20%',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={tableData.data}
      pagination={{ ...tableData.pagination, locale: { items_per_page: t('common.perPage') } }}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: 800 }}
      bordered
    />
  );
};
