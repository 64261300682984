import { callApi, METHOD } from '@app/api/http.api';
import i18n from '@app/i18n';
import { ILogObject, objToQueryString, TypeUser } from '@app/utils/utils';
import { notification } from 'antd';

export const getChartData = async (): Promise<any> => {
  try {
    let response: any = await callApi(METHOD.GET, `members/status`, null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getExternalLink = async (idUser: any, payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = {
      ...payload,
    };
    let response: any = await callApi(METHOD.GET, `external-link/members/${idUser}`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const postExternalLink = async (idUser: any, payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = {
      ...payload,
    };
    let response: any = await callApi(METHOD.POST, `external-link/members/${idUser}`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const patchExternalLink = async (
  idUser: any,
  id: number,
  payload: any,
): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.PATCH, `external-link/${id}/members/${idUser}`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const deleteExternalLink = async (idUser: any, id: number): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.DELETE, `external-link/${id}/members/${idUser}`, null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getAIChatbot = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = {
      ...payload,
    };
    let response: any = await callApi(METHOD.GET, 'chatbots', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getSmooth = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = {
      ...payload,
    };
    let response: any = await callApi(METHOD.GET, 'smooth', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const postAIChatbot = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = {
      ...payload,
    };
    let response: any = await callApi(METHOD.POST, 'chatbots', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const saveLogChatbot = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    const { id, name, whereId, whereName } = payload;
    const query: ILogObject = {
      objectName: name,
      whereId,
      whereObject: TypeUser.DOMAIN,
      whereName,
    };
    const response: any = await callApi(METHOD.POST, `chatbots/${id}/click` + `${objToQueryString(query)}`, null);
    if (response && response.status && response.status == 'error') {
      if (Object.prototype.toString.call(response.data) === '[object Array]') {
        response.data.forEach((item: any) => {
          notification.error({ message: item.messages[0] });
        });
      } else {
        notification.error({ message: response.data.message });
      }
    }
    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    //console.log(e)
  }
};

export const blockAIChatbot = async (id: number, domainId: any): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.PUT, `chatbots/${id}/domains/${domainId}/block`, null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

//---------------------********************------------------------
export const patchAIChatbot = async (id: number, domainId: any, payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.PATCH, `chatbots/${id}/domains/${domainId}`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

//---------------------********************------------------------
export const deleteAIChatbot = async (id: number, domainId: any): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.DELETE, `chatbots/${id}/domains/${domainId}`, null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getAIChatbotToken = async (idChatbot: any): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.GET, `chatbots/${idChatbot}/access-token`, null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getExternalApp = async (domainId: any, idApp: any, payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = {
      ...payload,
    };
    let response: any = await callApi(METHOD.GET, `domains/null/app/list`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const postExternalApp = async (
  groupId: any,
  domainId: any,
  payload: any,
): Promise<{ data: any[] } | undefined> => {
  try {
    payload = {
      ...payload,
    };
    let response: any = await callApi(METHOD.POST, `domains/${domainId}/app`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const saveLogExternalApp = async (
  domainId: any,
  id: any,
  objectName: any,
  whereName: any,
): Promise<{ data: any[] } | undefined> => {
  try {
    const query: ILogObject = {
      objectName,
      whereId: domainId,
      whereObject: TypeUser.DOMAIN,
      whereName,
    };
    let response: any = await callApi(
      METHOD.POST,
      `domains/${domainId}/app/${id}/click` + `${objToQueryString(query)}`,
      null,
    );
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const patchExternalApp = async (
  groupId: any,
  domainId: any,
  appId: number,
  payload: any,
): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.PATCH, `domains/${domainId}/app/${appId}`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const deleteExternalApp = async (
  groupId: any,
  domainId: any,
  appId: any,
): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.DELETE, `domains/${domainId}/app/${appId}`, null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const postSmoothApp = async (domainId: any, payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = {
      ...payload,
      domainId,
    };
    let response: any = await callApi(METHOD.POST, `smooth`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const saveLogSmoothApp = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    const { id, name, domain } = payload;
    const query: ILogObject = {
      objectName: name,
      whereId: domain.id,
      whereObject: TypeUser.DOMAIN,
      whereName: domain.domainName,
    };
    const response: any = await callApi(METHOD.POST, `smooth/${id}/click` + `${objToQueryString(query)}`, null);
    if (response && response.status && response.status == 'error') {
      if (Object.prototype.toString.call(response.data) === '[object Array]') {
        response.data.forEach((item: any) => {
          notification.error({ message: item.messages[0] });
        });
      } else {
        notification.error({ message: response.data.message });
      }
    }
    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    //console.log(e)
  }
};

export const blockSmoothApp = async (domainId: any, appId: number): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.PUT, `smooth/${appId}/domains/${domainId}/block`, null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const patchSmoothApp = async (
  domainId: any,
  appId: number,
  payload: any,
): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.PATCH, `smooth/${appId}/domains/${domainId}`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const deleteSmoothApp = async (domainId: any, appId: any): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.DELETE, `smooth/${appId}/domains/${domainId}`, null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};
