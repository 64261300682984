import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { deleteSmoothApp, getSmooth, patchSmoothApp, postSmoothApp, saveLogSmoothApp } from '@app/api/dashboard.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Modal } from '@app/components/common/Modal/Modal';
import { Option, Select } from '@app/components/common/selects/Select/Select';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { FONT_SIZE, media } from '@app/styles/themes/constants';
import { Col, notification, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
export const initValues: any = {
  name: '',
  url: '',
  description: '',
};
const AsyncButton = styled(Button)`
  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.xs};
  }
  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.md};
  }
`;
interface SmoothAppProps {
  isCallOpenAdd: any;
  reloadDetail?: () => void;
}
const SmoothAppPage: React.FC<SmoothAppProps> = ({ isCallOpenAdd, reloadDetail }) => {
  const { t } = useTranslation();
  const [formSmApp] = BaseForm.useForm();
  const { idDomain, idGroup } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [initData, setInitData] = useState(initValues);
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [recordSelected, setRecordSelected] = useState({
    id: 0,
  });
  const location = useLocation();
  const pageSize = location.pathname.indexOf('/members') >= 0 || location.pathname.indexOf('/profile') >= 0 ? 10 : 3;
  const [form] = BaseButtonsForm.useForm();

  const [page, setPage] = useState(1);

  const onEdit = (data: any) => {
    setInitData(data);
    setIsVisible(true);
  };

  const onDelete = (data: any) => {
    setRecordSelected(data);
  };

  const handleDelete = () => {
    deleteSmoothApp(idDomain, recordSelected.id)
      .then((res: any) => {
        if (res?.status == 'success') {
          notification.success({ message: res.data });
          onCancel();
        }
      })
      .catch((e: any) => notification.error({ message: e.message }));
  };

  useEffect(() => {
    //onGetSmoothApp(page)
  }, []);

  useEffect(() => {
    if (!isFirstOpen) {
      if (isCallOpenAdd != null) onOpenFormModal();
    } else {
      setIsFirstOpen(false);
    }
  }, [isCallOpenAdd]);

  const columns: ColumnsType<any> = [
    {
      title: t('dashboard.name'),
      dataIndex: 'name',
      showSorterTooltip: false,
    },
    {
      title: t('dashboard.url'),
      dataIndex: 'url',
      showSorterTooltip: false,
      render: (text, props) => (
        <>
          <a href={props.url} target="_blank">
            {props.url}
          </a>
        </>
      ),
    },
    {
      title: t('dashboard.action'),
      dataIndex: 'action',
      className: 'action-column',
      fixed: 'right',
      width: '120px',
      render: (text, props) => (
        <>
          <EditOutlined className="btn-icon-save" onClick={() => onEdit(props)} />
        </>
      ),
    },
  ];

  const onCancel = () => {
    formSmApp.resetFields();
    setIsVisible(false);
  };
  const onOpenFormModal = () => {
    setInitData(initValues);
    setIsVisible(true);
  };
  const changeDomain = (_data: any) => {
    const current: any = data.find((d: any) => d.id == _data);
    if (!current) return;
    saveLogSmoothApp({ ...current, whereId: current.domain.id, whereName: current.domain.domainName });
    window.open(current.url, '_blank');
  };
  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    await (initData.id ? patchSmoothApp(idDomain, initData.id, data) : postSmoothApp(idDomain, data))
      .then((res: any) => {
        if (res && res?.status == 'success') {
          notification.success({ message: res.data });
          reloadDetail && reloadDetail();
        }
      })
      .catch((e: any) => notification.error({ message: e.message }))
      .finally(() => {
        onCancel();
        setIsLoading(false);
      });
  };

  const onGetSmooth = async (page: number) => {
    setPage(page);
    await getSmooth({
      page: page,
      limit: 9999,
    })
      .then((res: any) => {
        if (res?.statusCode == 200) {
          setData(res.data.data);
        }
      })
      .catch((e: any) => {
        if (idDomain) notification.error({ message: e.message });
      });
  };

  const user = useAppSelector((state) => state.user.user);

  React.useEffect(() => {
    if (user && !user.isPasswordReset) {
      onGetSmooth(page);
    }
  }, [user?.isPasswordReset]);

  useEffect(() => {
    if (initData) {
      formSmApp.setFieldsValue(initData);
    }
  }, [initData]);

  return (
    <>
      {!idDomain && (
        <>
          <Select placeholder={t('dashboard.chooseSmooth')} width={200} onChange={(data) => changeDomain(data)}>
            {data.map((field: any) => (
              <Option value={field.id}>{field.name}</Option>
            ))}
          </Select>
        </>
      )}

      <Modal
        title={initData.id ? t('dashboard.editSmoothApp') : t('dashboard.createSmoothApp')}
        visible={isVisible}
        cancelText={t('button.cancel')}
        okText={t('button.save')}
        onCancel={() => setIsVisible(false)}
        confirmLoading={isLoading}
        okButtonProps={{
          type: 'primary',
          onClick: () => {
            formSmApp.submit();
          },
        }}
      >
        <Auth.FormWrapper className="p-05">
          <BaseForm
            confirmSubmitProps={{ active: true }}
            layout="vertical"
            form={formSmApp}
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initData}
          >
            <Row gutter={[10, 10]}>
              <Col xs={24} lg={12}>
                <Auth.FormItem
                  name="name"
                  label={t('dashboard.name')}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('common.requiredField')));
                      },
                    },
                    { max: 100, message: t('validator.maxLength', { count: 100 }) },
                  ]}
                >
                  <Auth.FormInput placeholder={t('dashboard.name')} />
                </Auth.FormItem>
              </Col>
              <Col xs={24} lg={24}>
                <Auth.FormItem
                  name="url"
                  label={t('dashboard.url')}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('common.requiredField')));
                      },
                    },
                    {
                      pattern:
                        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
                      message: t('validator.allowOnlyHttps'),
                    },
                    { max: 255, message: t('validator.maxLength', { count: 255 }) },
                  ]}
                >
                  <Auth.FormInput placeholder={t('dashboard.url')} />
                </Auth.FormItem>
              </Col>
            </Row>
          </BaseForm>
        </Auth.FormWrapper>
      </Modal>
    </>
  );
};
export default SmoothAppPage;
