import { EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { getDomain, patchDomain } from '@app/api/domain.api';
import { Card } from '@app/components/common/Card/Card';
import { Loading } from '@app/components/common/Loading';
import { TabPane, Tabs } from '@app/components/common/Tabs/Tabs';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import AIChatPage from '@app/components/dashboard/appPreview/AIChatPage';
import SmoothAppPage from '@app/components/dashboard/appPreview/SmoothAppPage';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { Col, Row, notification } from 'antd';
import locale_en from 'antd/es/date-picker/locale/en_US';
import locale_jp from 'antd/es/date-picker/locale/ja_JP';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import DomainAppSettings from './DomainAppSettings';

import dayjs from 'dayjs';
interface EditDomainData {
  name: string;
  mailAccount: string;
  subMailAccount: string;
  telephone: string;
  department: string;
  numberPostOffice: string;
  addressCity: string;
  addressWardStreet: string;
  companyName: string;
  chatbot: any;
  smooth: any;
  settingDate: any;
}
export const initValues: EditDomainData = {
  name: '',
  mailAccount: '',
  subMailAccount: '',
  telephone: '',
  department: '',
  numberPostOffice: '',
  addressCity: '',
  addressWardStreet: '',
  companyName: '',
  chatbot: null,
  smooth: null,
  settingDate: '',
};

const EditDomainPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [value, setValue] = useState(initValues);
  const [showForm, setShowForm] = useState(false);
  const [isVisibleConfirmModal, setIsVisibleConfirmModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditApp, setIsEditApp] = useState(false);
  const { idGroup, idDomain, action } = useParams();
  const [appList, setAppList] = useState([]);
  const [isCallOpenAdd, setIsCallOpenAdd] = useState(true);
  const [isCallOpenAddSmooth, setIsCallOpenAddSmooth] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [groupName, setGroupName] = useState('');

  useEffect(() => {
    onGetDomain();
  }, []);

  useEffect(() => {
    const isEditPage = action === 'edit';
    setIsEdit(isEditPage);
    setIsEditApp(isEditPage);
  }, [action]);

  const onGetDomain = () => {
    getDomain({
      groupId: idGroup,
      domainId: idDomain,
    })
      .then((res: any) => {
        if (res?.statusCode == 200) {
          setValue(res.data);
          setShowForm(true);
          setGroupName(res.data.group.name);
        }
      })
      .catch((e: any) => notification.error({ message: e.message }));
  };

  const handleSubmit = (data: any) => {
    setIsLoading(true);
    data = { ...data, groupName, groupId: idGroup };
    for (const key in data) {
      if (!data[key]) delete data[key];
    }
    patchDomain(idDomain, data)
      .then((res: any) => {
        if (res?.status == 'success') {
          navigate(`/domain`);
          notification.success({ message: t('message.updateSuccessfully') });
        }
      })
      .catch((e: any) => notification.error({ message: e.message }))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onOpenModal = () => {
    setIsCallOpenAdd(!isCallOpenAdd);
  };

  const onOpenSmoothModal = () => {
    setIsCallOpenAddSmooth(!isCallOpenAddSmooth);
  };

  return showForm ? (
    <Card padding="20">
      <Auth.FormWrapper style={{ padding: '0' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab={`${t('domain.domainInformation')}`} key="1">
            <h2>{t('domain.domainInformation')}</h2>
            <BaseForm
              layout="vertical"
              onFinish={handleSubmit}
              confirmSubmitProps={{
                active: true,
              }}
              requiredMark="optional"
              initialValues={value}
            >
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Row gutter={[10, 10]} justify={'end'}>
                    <Col>
                      <Button
                        type="primary"
                        onClick={() => navigate(`/domain/edit/${idGroup}/${idDomain}/policy-setting`)}
                      >
                        {t('button.editPolicy')}
                      </Button>
                    </Col>
                    {!isEdit && (
                      <Col>
                        <Button
                          type="primary"
                          icon={<EditOutlined />}
                          onClick={() => {
                            navigate(`/domain/edit/${idGroup}/${idDomain}`);
                          }}
                        >
                          {t('button.edit')}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem label={t('domain.group')}>
                    <Auth.FormInput disabled={true} value={groupName} placeholder={t('domain.domainName')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="domainName"
                    label={t('domain.domainName')}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      { max: 255, message: t('validator.maxLength', { count: 255 }) },
                    ]}
                  >
                    <Auth.FormInput disabled={true} placeholder={t('domain.domainName')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="mailAccount"
                    label={t('domain.mailAccount')}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      {
                        type: 'email',
                        message: t('validator.notValidEmail'),
                      },
                      { max: 255, message: t('validator.maxLength', { count: 255 }) },
                    ]}
                  >
                    <Auth.FormInput disabled={!isEdit} placeholder={t('domain.mailAccount')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="subMailAccount"
                    label={t('domain.subMailAccount')}
                    rules={[
                      {
                        type: 'email',
                        message: t('validator.notValidEmail'),
                      },
                      { max: 255, message: t('validator.maxLength', { count: 255 }) },
                    ]}
                  >
                    <Auth.FormInput disabled={!isEdit} placeholder={t('domain.subMailAccount')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="department"
                    label={t('domain.department')}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      { max: 255, message: t('validator.maxLength', { count: 255 }) },
                    ]}
                  >
                    <Auth.FormInput disabled={!isEdit} placeholder={t('domain.department')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="representativeName"
                    label={t('domain.representativeName')}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      { max: 255, message: t('validator.maxLength', { count: 255 }) },
                    ]}
                  >
                    <Auth.FormInput disabled={!isEdit} placeholder={t('domain.representativeName')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="telephone"
                    label={t('domain.telephone')}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      { pattern: /^0[0-9]{9,12}$/, message: t('validator.telephoneFormatIncorrect') },
                    ]}
                  >
                    <Auth.FormInput disabled={!isEdit} placeholder={t('domain.telephone')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="subTelephone"
                    label={t('domain.subTelephone')}
                    rules={[{ pattern: /^0[0-9]{9,12}$/, message: t('validator.telephoneFormatIncorrect') }]}
                  >
                    <Auth.FormInput disabled={!isEdit} placeholder={t('domain.subTelephone')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="numberPostOffice"
                    label={t('domain.numberPostOffice')}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      { pattern: /^〒?[0-9]{3}-?[0-9]{4}$/g, message: t('validator.numberPostOfficeFormatIncorrect') },
                    ]}
                  >
                    <Auth.FormInput disabled={!isEdit} placeholder={t('domain.numberPostOffice')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="companyName"
                    label={t('domain.companyName')}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      { max: 255, message: t('validator.maxLength', { count: 255 }) },
                    ]}
                  >
                    <Auth.FormInput disabled={!isEdit} placeholder={t('domain.companyName')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="addressCity"
                    label={t('domain.addressCity')}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('common.requiredField')));
                        },
                      },
                      { max: 255, message: t('validator.maxLength', { count: 255 }) },
                    ]}
                  >
                    <Auth.FormInput disabled={!isEdit} placeholder={t('domain.addressCity')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="addressWardStreet"
                    label={t('domain.addressWardStreet')}
                    rules={[{ max: 255, message: t('validator.maxLength', { count: 255 }) }]}
                  >
                    <Auth.FormInput disabled={!isEdit} placeholder={t('domain.addressWardStreet')} />
                  </Auth.FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <Auth.FormItem
                    name="settingDate"
                    getValueProps={(i) => ({ value: dayjs(i) })}
                    label={t('dashboard.settingDate')}
                  >
                    <DatePicker
                      className="w-100"
                      disabled={!isEdit}
                      allowClear={false}
                      format="YYYY-MM-DD"
                      locale={localStorage.getItem('x-language-code') == 'jp' ? locale_jp : locale_en}
                    />
                  </Auth.FormItem>
                </Col>
              </Row>
              {isEdit && (
                <BaseForm.Item noStyle>
                  <Auth.SubmitButton disabled={isLoading} type="primary" htmlType="submit">
                    {t('button.save')} <SaveOutlined />
                  </Auth.SubmitButton>
                </BaseForm.Item>
              )}
            </BaseForm>
          </TabPane>
          <TabPane tab={`${t('domain.appSettings')}`} key="2" className="w-100 mt-2">
            <h2 className="mb-4">{t('domain.appSettings')}</h2>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                {!isEditApp ? (
                  <Button
                    className="edit-app-domain-btn"
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => {
                      navigate(`/domain/edit/${idGroup}/${idDomain}`);
                      // setIsEditApp(true);
                    }}
                  >
                    {t('button.edit')}
                  </Button>
                ) : (
                  <>
                    {!value.chatbot && (
                      <Button className="d-inline-flex mr-2" type="primary" onClick={() => onOpenModal()}>
                        {t('button.addAIChatbot')} <PlusOutlined />
                      </Button>
                    )}
                    {!value.smooth && (
                      <Button className="d-inline-flex" type="primary" onClick={() => onOpenSmoothModal()}>
                        {t('button.addSmooth')} <PlusOutlined />
                      </Button>
                    )}
                  </>
                )}
              </Col>
              <AIChatPage isCallOpenAdd={isCallOpenAdd} reloadDetail={onGetDomain} />
              <SmoothAppPage isCallOpenAdd={isCallOpenAddSmooth} reloadDetail={onGetDomain} />
              <Col span={24}>
                <DomainAppSettings
                  isEdit={isEditApp}
                  dataList={appList}
                  data={value}
                  changePagination={() => {
                    console.log();
                  }}
                  reloadDetail={onGetDomain}
                />
              </Col>
            </Row>
          </TabPane>
          {/* <TabPane tab={`${t('policy.policySetting')}`} key="3" className="w-100 mt-2">
            <PasswordPolicyDomain />
          </TabPane> */}
        </Tabs>
      </Auth.FormWrapper>
    </Card>
  ) : (
    <>
      <Loading />
    </>
  );
};
export default EditDomainPage;
