import React, { useEffect, useState } from 'react';
import { Form, TablePaginationConfig } from 'antd';
import { GroupTableRow, Pagination } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Modal, ConfirmModal } from '@app/components/common/Modal/Modal';
import { EditOutlined, DeleteOutlined, StopOutlined, PlusOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Button } from '@app/components/common/buttons/Button/Button';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import { Col, Row } from 'antd';
import locale_jp from 'antd/es/date-picker/locale/ja_JP';
import locale_en from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import {
  postExternalApp,
  patchExternalApp,
  deleteExternalApp,
  patchAIChatbot,
  patchSmoothApp,
  blockAIChatbot,
  blockSmoothApp,
  saveLogExternalApp,
  getAIChatbotToken,
  saveLogChatbot,
  saveLogSmoothApp,
} from '@app/api/dashboard.api';
import { notification } from 'antd';
import { getDomain } from '@app/api/domain.api';
import moment from 'moment';
import { FORMAT, formatDate } from '@app/utils/utils';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

interface TableListProps {
  dataList: any;
  data: any;
  isEdit: boolean;
  changePagination: (data: any) => void;
  reloadDetail: () => void;
}

export const defaultDataExtApp: any = {
  id: '',
  name: '',
  url: '',
};

export const defaultDataChatbot: any = {
  id: null,
  isChatbot: false,
  name: '',
  dateOfContractExpiry: null,
  dateOfSysExpiry: null,
  isActive: true,
};

const DomainAppSettings: React.FC<TableListProps> = ({ dataList, isEdit, data, changePagination, reloadDetail }) => {
  const [tableData, setTableData] = useState<{ data: GroupTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [extAppForm] = Form.useForm();
  const [chatbotForm] = Form.useForm();
  const [initDataExtApp, setInitDataExtApp] = useState(defaultDataExtApp);
  const [initDataChatbot, setInitDataChatbot] = useState(defaultDataChatbot);
  const [listApp, setListApp] = useState([]);
  const [isVisibleChatbot, setIsVisibleChatbot] = useState(false);
  const [isVisibleApp, setIsVisibleApp] = useState(false);
  const [isLoadingChatbot, setIsLoadingChatbot] = useState(false);
  const [isLoadingExtApp, setIsLoadingExtApp] = useState(false);
  const { idGroup, idDomain } = useParams();
  const [listChatbotSmooth, setListChatbotSmooth] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    initDataExtApp && extAppForm.setFieldsValue(initDataExtApp);
  }, [initDataExtApp]);

  useEffect(() => {
    initDataChatbot && chatbotForm.setFieldsValue(initDataChatbot);
  }, [initDataChatbot]);

  useEffect(() => {
    setTableData({
      data: dataList.data,
      pagination: {
        current: dataList.page,
        total: dataList.total,
      },
      loading: false,
    });
  }, [dataList]);

  useEffect(() => {
    if (data.externalApps) {
      setListApp(
        data.externalApps.map((item: any) => {
          return {
            id: item.id,
            name: item?.name,
            isExternal: true,
            url: item.url,
            isActive: item?.isActive,
          };
        }),
      );
    }
    const listChatSmooth = [];
    if (data.chatbot) {
      listChatSmooth.push({
        id: data.chatbot.id,
        name: data.chatbot?.name,
        user: data.chatbot.user,
        isChatbot: true,
        url: null,
        expiryDateContract: data.chatbot.dateOfContractExpiry,
        expiryDateSystem: data.chatbot.dateOfSystemExpiry,
        isActive: data.chatbot.isActive,
      });
    }
    if (data.smooth) {
      const getSmooth = data.smooth;
      listChatSmooth.push({
        id: getSmooth.id,
        name: getSmooth?.name,
        user: getSmooth.user,
        isChatbot: false,
        url: getSmooth.url,
        expiryDateContract: getSmooth.dateOfContractExpiry,
        expiryDateSystem: getSmooth.dateOfSystemExpiry,
        isActive: getSmooth.isActive,
      });
    }
    setListChatbotSmooth(listChatSmooth);
  }, [data]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    changePagination(pagination);
  };

  const handleSubmitExtApp = async (data: any) => {
    setIsLoadingExtApp(true);
    await (initDataExtApp.id
      ? patchExternalApp(idGroup, idDomain, initDataExtApp.id, data)
      : postExternalApp(idGroup, idDomain, data)
    )
      .then((res: any) => {
        if (res?.status == 'success') {
          notification.success({ message: res.data });
          reloadDetail();
        }
      })
      .catch((e: any) => {
        notification.error({ message: e.message });
      })
      .finally(() => {
        extAppForm.resetFields();
        setIsLoadingExtApp(false);
        onCancelApp();
      });
  };

  const onSubmitDelete = (data: any) => {
    deleteExternalApp(idGroup, idDomain, data.id)
      .then((res: any) => {
        if (res?.status == 'success') {
          notification.success({ message: res.data || t('message.deleteSuccess') });
          reloadDetail();
        }
      })
      .catch((e: any) => notification.error({ message: e.message }));
  };

  const onEditExternalApp = (data: any) => {
    setInitDataExtApp(data);
    setIsVisibleApp(true);
  };

  const onSubmitSwitchStatus = (isActive: boolean, item: any, type: string) => {
    switch (type) {
      case 'app': {
        patchExternalApp(idGroup, idDomain, item.id, {
          isActive: isActive,
        })
          .then((res: any) => {
            if (res?.status == 'success') {
              notification.success({ message: res.data });
              reloadDetail();
            }
          })
          .catch((e: any) => notification.error({ message: e.message }))
          .finally(() => setIsLoadingExtApp(false));
        break;
      }
      case 'chatbot': {
        blockAIChatbot(item.id, idDomain)
          .then((res: any) => {
            if (res?.status == 'success') {
              notification.success({ message: res.data });
              setIsVisibleChatbot(false);
              reloadDetail();
            }
          })
          .catch((e: any) => notification.error({ message: e.message }))
          .finally(() => setIsLoadingExtApp(false));
        break;
      }
      case 'smooth': {
        blockSmoothApp(idDomain, item.id)
          .then((res: any) => {
            if (res?.status == 'success') {
              notification.success({ message: res.data });
              setIsVisibleChatbot(false);
              reloadDetail();
            }
          })
          .catch((e: any) => notification.error({ message: e.message }))
          .finally(() => setIsLoadingExtApp(false));
        break;
      }
    }
  };

  const onActive = (data: any, type: string) => {
    const content = (
      <>
        {t('domain.areYouSureWantToUnlockApp')} <strong>{data?.name}</strong>?
      </>
    );
    ConfirmModal({
      title: t('common.titleConfirmation'),
      content,
      onOk: () => onSubmitSwitchStatus(true, data, type),
      cancelText: t('button.no'),
      okText: t('button.yes'),
    });
  };

  const onDisable = (data: any, type: string) => {
    const content = (
      <>
        {t('domain.areYouSureWantToBlockApp')} <strong>{data?.name}</strong>?
      </>
    );
    ConfirmModal({
      title: t('common.titleConfirmation'),
      content,
      onOk: () => onSubmitSwitchStatus(false, data, type),
      cancelText: t('button.no'),
      okText: t('button.yes'),
    });
  };

  const onOpenModalChatbot = (data: any) => {
    setInitDataChatbot({
      id: data.id,
      isChatbot: data?.isChatbot,
      name: data?.name,
      dateOfContractExpiry: data.expiryDateContract && dayjs(data.expiryDateContract),
      dateOfSysExpiry: data.expiryDateSystem && dayjs(data.expiryDateSystem),
      isActive: data.isActive,
    });
    setIsVisibleChatbot(true);
  };

  const handleSubmitChatbot = async (data: any) => {
    setIsLoadingChatbot(true);
    await (initDataChatbot.isChatbot
      ? patchAIChatbot(initDataChatbot.id, idDomain, {
          ...data,
          dateOfContractExpiry: dayjs(data.dateOfContractExpiry).toISOString(),
          dateOfSysExpiry: dayjs(data.dateOfSysExpiry).toISOString(),
        })
      : patchSmoothApp(idDomain, initDataChatbot.id, data)
    )
      .then((res: any) => {
        if (res?.status == 'success') {
          notification.success({ message: res.data });
          reloadDetail();
        }
      })
      .catch((e: any) => notification.error({ message: e.message }))
      .finally(() => {
        chatbotForm.resetFields();
        setIsVisibleChatbot(false);
        setIsLoadingChatbot(false);
      });
  };

  const onOpenModal = () => {
    setInitDataExtApp(defaultDataExtApp);
    setIsVisibleApp(true);
  };

  const onCancelApp = () => {
    extAppForm.resetFields();
    setIsVisibleApp(false);
  };

  const onDeleteExternalApp = (data: any) => {
    const content = (
      <>
        {t('domain.areYouSureWantToDeleteExternalApp')} <strong>{data?.name}</strong>?
      </>
    );
    ConfirmModal({
      content,
      onOk: () => onSubmitDelete(data),
      title: t('common.titleConfirmation'),
      okText: t('button.yes'),
      cancelText: t('button.no'),
    });
  };

  const onClickExternalApp = (props: any) => {
    saveLogExternalApp(idDomain, props.id, props.name, data.domainName).catch((e) =>
      notification.error({ message: e.message }),
    );
    window.open(props.url, '_blank');
  };

  const columnsExternal: ColumnsType<any> = [
    {
      title: t('domain.app'),
      dataIndex: 'name',
      showSorterTooltip: false,
    },
    {
      title: t('domain.url'),
      dataIndex: 'url',
      showSorterTooltip: false,
      render: (text, props) => (
        <>
          <a onClick={() => onClickExternalApp(props)}>{props.url}</a>
        </>
      ),
    },
    {
      title: t('domain.action'),
      className: 'action-column',
      fixed: 'right',
      dataIndex: 'contents',
      width: '120px',
      render: (text, props) => (
        <>
          {isEdit && (
            <>
              <EditOutlined
                className="btn-icon-save"
                onClick={() => {
                  onEditExternalApp(props);
                }}
              />
              <DeleteOutlined
                className="btn-icon-close ml-2"
                onClick={() => {
                  onDeleteExternalApp(props);
                }}
              />
              {!props.isActive && (
                <CheckCircleOutlined className="btn-icon-active ml-2" onClick={() => onActive(props, 'app')} />
              )}
            </>
          )}
        </>
      ),
      showSorterTooltip: false,
    },
  ];

  const onSelectChatbot = (id: any) => {
    getAIChatbotToken(id)
      .then((res: any) => {
        if (res?.statusCode == 200) {
          saveLogChatbot({ id, name: res?.data?.name, whereId: idDomain, whereName: data.domainName });
          window.open(res.data.url, '_blank');
        }
      })
      .catch((e: any) => notification.error({ message: e.message }));
  };

  const columns: ColumnsType<any> = [
    {
      title: t('domain.app'),
      dataIndex: 'name',
      showSorterTooltip: false,
      render: (text, props) => {
        return (
          <>
            {props.isChatbot ? (
              <a
                className="type-chatbot"
                onClick={(event) => {
                  if (props.isActive) {
                    event.stopPropagation();
                    onSelectChatbot(props.id);
                  }
                }}
              >
                [{t('dashboard.chatbot')}]
              </a>
            ) : (
              <a
                className="type-smooth"
                onClick={(event) => {
                  if (props.isActive) {
                    saveLogSmoothApp({ ...props, domain: { domainName: data.domainName, id: idDomain } });
                    window.open(props.url, '_blank');
                  }
                }}
              >
                [{t('dashboard.smooth')}]
              </a>
            )}
            {props?.name}
          </>
        );
      },
    },
    {
      title: t('domain.user'),
      dataIndex: 'user',
      width: '200px',
      showSorterTooltip: false,
    },
    {
      title: t('domain.automatic'),
      dataIndex: 'expiryDateContract',
      width: '200px',
      render: (text, props) => (
        <>{!props.isExternal && <>{props.expiryDateContract && formatDate(props.expiryDateContract, FORMAT.DATE)}</>}</>
      ),
      showSorterTooltip: false,
    },
    {
      title: t('domain.suspend'),
      dataIndex: 'expiryDateSystem',
      width: '200px',
      render: (text, props) => (
        <>{!props.isExternal && <>{props.expiryDateSystem && formatDate(props.expiryDateSystem, FORMAT.DATE)}</>}</>
      ),
      showSorterTooltip: false,
    },

    {
      title: t('domain.action'),
      className: 'action-column',
      fixed: 'right',
      dataIndex: 'contents',
      width: '120px',
      render: (text, props) => (
        <>
          {isEdit && (
            <>
              <EditOutlined
                className="btn-icon-save"
                onClick={() => {
                  onOpenModalChatbot(props);
                }}
              />
              <>
                {props.isActive ? (
                  <StopOutlined
                    className="btn-icon-block ml-2"
                    onClick={() => {
                      onDisable(props, props.isChatbot ? 'chatbot' : 'smooth');
                    }}
                  />
                ) : (
                  <CheckCircleOutlined
                    className="btn-icon-active ml-2"
                    onClick={() => {
                      onActive(props, props.isChatbot ? 'chatbot' : 'smooth');
                    }}
                  />
                )}
              </>
            </>
          )}
        </>
      ),
      showSorterTooltip: false,
    },
  ];

  return (
    <>
      <Modal
        title={initDataExtApp.id ? t('dashboard.editExternalApp') : t('dashboard.createExternalApp')}
        visible={isVisibleApp}
        onCancel={onCancelApp}
        cancelText={t('button.cancel')}
        okText={t('button.save')}
        confirmLoading={isLoadingExtApp}
        okButtonProps={{
          type: 'primary',
          onClick: () => {
            extAppForm.submit();
          },
        }}
      >
        <Auth.FormWrapper className="p-05">
          <BaseForm
            confirmSubmitProps={{ active: true }}
            layout="vertical"
            form={extAppForm}
            onFinish={handleSubmitExtApp}
            requiredMark="optional"
            initialValues={initDataExtApp}
          >
            <Row gutter={[10, 10]}>
              <Col xs={24} lg={12}>
                <Auth.FormItem
                  name="name"
                  label={t('dashboard.name')}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('common.requiredField')));
                      },
                    },
                    { max: 255, message: t('validator.maxLength', { count: 255 }) },
                  ]}
                >
                  <Auth.FormInput placeholder={t('dashboard.name')} />
                </Auth.FormItem>
              </Col>
              <Col xs={24} lg={24}>
                <Auth.FormItem
                  name="url"
                  label={t('dashboard.url')}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('common.requiredField')));
                      },
                    },
                    {
                      pattern:
                        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
                      message: t('validator.allowOnlyHttps'),
                    },
                    { max: 255, message: t('validator.maxLength', { count: 255 }) },
                  ]}
                >
                  <Auth.FormInput placeholder={t('dashboard.url')} />
                </Auth.FormItem>
              </Col>
            </Row>
          </BaseForm>
        </Auth.FormWrapper>
      </Modal>

      <Modal
        title={initDataChatbot.isChatbot ? t('dashboard.editAIChatbot') : t('dashboard.editSmoothApp')}
        visible={isVisibleChatbot}
        onCancel={() => setIsVisibleChatbot(false)}
        cancelText={t('button.cancel')}
        okText={t('button.save')}
        confirmLoading={isLoadingChatbot}
        okButtonProps={{
          type: 'primary',
          onClick: () => {
            chatbotForm.submit();
          },
        }}
      >
        <Auth.FormWrapper className="p-05">
          <BaseForm
            confirmSubmitProps={{ active: true }}
            layout="vertical"
            onFinish={handleSubmitChatbot}
            requiredMark="optional"
            initialValues={initDataChatbot}
            form={chatbotForm}
          >
            <Row gutter={[10, 10]}>
              <Col xs={24} lg={12}>
                <Auth.FormItem
                  name="name"
                  label={t('dashboard.name')}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('common.requiredField')));
                      },
                    },
                    {
                      validator: async (rule, value) => {
                        const { isChatbot } = chatbotForm.getFieldsValue(['isChatbot']);
                        if (isChatbot && !/^[a-z0-9]+$/i.test(value))
                          throw new Error(t('validator.onlyDecimalAndNumeric'));
                      },
                    },
                  ]}
                >
                  <Auth.FormInput placeholder={t('dashboard.name')} />
                </Auth.FormItem>
              </Col>
              <Col xs={24} lg={12}></Col>
              <Col xs={24} lg={12}>
                <Auth.FormItem
                  name="dateOfContractExpiry"
                  label={t('domain.automatic')}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('common.requiredField')));
                      },
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    defaultValue={dayjs(initDataChatbot.dateOfContractExpiry)}
                    format="YYYY-MM-DD"
                    locale={localStorage.getItem('x-language-code') == 'jp' ? locale_jp : locale_en}
                  />
                </Auth.FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <Auth.FormItem
                  name="dateOfSysExpiry"
                  label={t('domain.suspend')}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('common.requiredField')));
                      },
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    defaultValue={dayjs(initDataChatbot.dateOfSysExpiry)}
                    format="YYYY-MM-DD"
                    locale={localStorage.getItem('x-language-code') == 'jp' ? locale_jp : locale_en}
                  />
                </Auth.FormItem>
              </Col>
            </Row>
          </BaseForm>
        </Auth.FormWrapper>
      </Modal>

      <div className="btn-add-app-domain">
        {isEdit && (
          <Button
            className=""
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              onOpenModal();
            }}
          >
            {t('button.addApp')}
          </Button>
        )}
      </div>
      <label>{t('domain.chatbotSmoothLabel')}</label>
      <Table
        className="mt-2 mb-2"
        columns={columns}
        dataSource={listChatbotSmooth}
        pagination={false}
        loading={tableData.loading}
        onChange={handleTableChange}
        bordered
      />
      <label>{t('domain.externalApp')}</label>
      <Table
        className="mt-2"
        columns={columnsExternal}
        dataSource={listApp}
        pagination={false}
        loading={tableData.loading}
        onChange={handleTableChange}
        bordered
      />
    </>
  );
};
export default DomainAppSettings;
