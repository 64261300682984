import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { MyPage } from '../components/MyPage/MyPage';
import { Help } from '../components/Help/Help';
import { Badge } from '@app/components/common/Badge/Badge';
import { readProfile } from '@app/services/localStorage.service';
import * as S from '../Header.styles';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);
  const [profile, setProfile] = useState<any>({
    fullName: '',
  });
  useEffect(() => {
    setProfile(user);
  }, [user]);
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <Row justify="space-between">
        <Col xl={15} xxl={12}>
          <HeaderSearch />
        </Col>
        <Col>
          <S.GHButton />
        </Col>
      </Row>
    </S.SearchColumn>
  ) : (
    <>
      <Col lg={12} xxl={12}>
        <S.HeaderName>
          {t('header.hello')} {profile?.fullName}
        </S.HeaderName>
      </Col>
      {/* <Col lg={8} xxl={8}>
        <Badge
          status="processing"
          text={
            <span>
              {t('header.plan')}: <strong>Free</strong>
            </span>
          }
        />
        <Badge
          className="ml-4"
          status="processing"
          text={
            <span>
              {t('header.purchased_licenses')}: <strong>Free</strong>
            </span>
          }
        />
      </Col> */}
    </>
  );

  return (
    <Row justify="space-between" align="middle">
      {leftSide}
      <S.ProfileColumn xl={12} xxl={12} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <Row align="middle" justify="end" gutter={[10, 10]}>
          <Col>
            <Row gutter={[{ xxl: 10 }, { xxl: 10 }]}>
              <Col>
                <SettingsDropdown />
              </Col>
            </Row>
          </Col>
          <Col>
            <ProfileDropdown />
          </Col>
        </Row>
      </S.ProfileColumn>
    </Row>
  );
};
