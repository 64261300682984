import { getProfile, postChangePassword } from '@app/api/login.api';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notification } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import * as S from './NewPasswordForm.styles';
import { logout } from '@app/services/localStorage.service';

interface NewPasswordFormData {
  password: string;
  confirmPassword: string;
}

const initStates = {
  oldPassword: '',
  password: '',
  confirmPassword: '',
};

export const NewPasswordForm: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dirty = useAppSelector((state) => state.dirty.dirty);
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();

  const beforeUnLoad = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (dirty) {
      e.returnValue = '';
    }
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', beforeUnLoad);

    return () => {
      window.removeEventListener('beforeunload', beforeUnLoad);
    };
  }, [dirty]);

  const handleSubmit = (values: any) => {
    setLoading(true);
    postChangePassword({
      oldPassword: values.oldPassword,
      newPassword: values.password,
      confirmPassword: values.confirmPassword,
    })
      .then((res: any) => {
        if (res?.statusCode == 200) {
          notification.success({
            message: res.data,
          });
          getProfile()
            .then((res: any) => {
              setTimeout(() => {
                navigate('/');
                notification.success({ message: t('message.loginSuccess') });
              }, 300);
            })
            .catch((e: any) => {
              notification.error({ message: e.message });
            });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onLogout = async () => {
    await logout().finally(() => setTimeout(() => navigate('/login')));
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <Auth.FormTitle>{t('profile.changePassword')}</Auth.FormTitle>
        {/* {location.pathname == '/reset-password' && <S.Description>{t('common.needResetPasswordTitle')}</S.Description>} */}
        <Auth.FormItem
          name="oldPassword"
          label={t('profile.oldPassword')}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('common.requiredField')));
              },
            },
          ]}
        >
          <Auth.FormInputPassword placeholder={t('profile.oldPassword')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="password"
          label={t('profile.newPassword')}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('common.requiredField')));
              },
            },
          ]}
          dependencies={['password']}
        >
          <Auth.FormInputPassword placeholder={t('profile.newPassword')} type={'password'} />
        </Auth.FormItem>
        <Auth.FormItem
          name="confirmPassword"
          label={t('profile.confirmPassword')}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('common.requiredField')));
              },
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('validator.confirmPasswordError')));
              },
            }),
          ]}
        >
          <Auth.FormInputPassword placeholder={t('profile.confirmPassword')} type={'password'} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('button.changePassword')}
          </S.SubmitButton>
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="default" onClick={onLogout}>
            {t('common.logout')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
});
