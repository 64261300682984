import React, { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicTable } from './BasicTable';
import {
  FileExcelOutlined,
  InsertRowRightOutlined,
  VerticalAlignTopOutlined,
  PlusOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { readProfile } from '@app/services/localStorage.service';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Button } from '@app/components/common/buttons/Button/Button';
import { exportDomain } from '@app/api/domain.api';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { Card } from '@app/components/common/Card/Card';
import * as S from 'components/tables/Tables/Tables.styles';
import { CSVLink } from 'react-csv';
import notification from 'antd/lib/notification';
import { FORMAT, formatDate } from '@app/utils/utils';
interface MemberListProps {
  dataList: any;
  groupId?: any;
  changePagination: (data: any) => void;
  changeKeyword: (keyword: any) => void;
  changeGroup: (groupId: any) => void;
  handleAfterSubmit: () => void;
  onRowClick?: (_data: any, _index: number | undefined) => void;
  hiddenGroupSearched?: boolean;
}

export const DomainTable: React.FC<MemberListProps> = ({
  dataList,
  groupId,
  changePagination,
  changeKeyword,
  changeGroup,
  handleAfterSubmit,
  onRowClick,
  hiddenGroupSearched,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const csvLink = useRef<any>();
  const [search, setSearch] = useState('');
  const [domainExport, setDomainExport] = useState<any>([]);
  const [headers, setHeaders] = useState<{ label: string; key: string }[]>([]);
  const exportDomains = () => {
    exportDomain(groupId, search)
      .then((result: any) => {
        if (result?.status == 'success') {
          const { data: domains } = result;

          const numberMaxExternalapp =
            Math.max(...domains.map(({ externalApps }: any) => externalApps.length)) < 0
              ? 0
              : Math.max(...domains.map(({ externalApps }: any) => externalApps.length));
          const externalappHeaders = Array.from(Array(numberMaxExternalapp || 0).keys()).map((number) => ({
            label: `${t('csvFile.externalAppNumber')} ${number}`,
            key: `externalAppNumber_${number}`,
          }));

          const headers = [
            { label: t('csvFile.domainName'), key: 'domainName' },
            { label: t('csvFile.companyName'), key: 'companyName' },
            { label: t('csvFile.status'), key: 'isActive' },
            { label: t('csvFile.groupName'), key: 'groupName' },
            { label: t('csvFile.emailAddress'), key: 'mailAccount' },
            { label: t('csvFile.subEmailAddress'), key: 'subMailAccount' },
            { label: t('csvFile.department'), key: 'department' },
            { label: t('csvFile.nameOfDirector'), key: 'representativeName' },
            { label: t('csvFile.telephone'), key: 'telephone' },
            { label: t('csvFile.subTelephone'), key: 'subTelephone' },
            { label: t('csvFile.numberPostOffice'), key: 'numberPostOffice' },
            { label: t('csvFile.addressCity'), key: 'addressCity' },
            { label: t('csvFile.addressWardStreet'), key: 'addressWardStreet' },
            { label: t('csvFile.settingDate'), key: 'settingDate' },
            { label: t('csvFile.numberOfAllUser'), key: 'numberOfAllUser' },

            { label: t('csvFile.chatBotName'), key: 'chatBotName' },
            { label: t('csvFile.chatbotAutoUpdateDate'), key: 'chatbotAutoUpdateDate' },
            { label: t('csvFile.chatbotSuspendDate'), key: 'chatbotSuspendDate' },
            { label: t('csvFile.chatBotStatus'), key: 'chatBotStatus' },
            { label: t('csvFile.numberOfChatbotUser'), key: 'numberOfChatbotUser' },

            { label: t('csvFile.smoothLink'), key: 'smoothLink' },
            { label: t('csvFile.smoothAutoUpdateDate'), key: 'smoothAutoUpdateDate' },
            { label: t('csvFile.smoothSuspendDate'), key: 'smoothSuspendDate' },
            { label: t('csvFile.smoothStatus'), key: 'smoothStatus' },
            { label: t('csvFile.numberOfSmoothUser'), key: 'numberOfSmoothUser' },

            ...externalappHeaders,
            { label: t('domain.numberOfAppUser'), key: 'numberOfAppUser' },
          ];

          setHeaders(headers);
          setDomainExport(
            domains.reduce(
              (
                domainExport: any,
                {
                  settingDate,
                  chatbotAutoUpdateDate,
                  chatbotSuspendDate,
                  smoothAutoUpdateDate,
                  smoothSuspendDate,
                  smoothStatus,
                  chatBotStatus,
                  isActive,
                  externalApps,
                  ...domain
                }: any,
              ) => {
                const externalAppCsv = externalApps.reduce((acc: any, { url }: any, index: any) => {
                  return { ...acc, [`externalAppNumber_${index}`]: url };
                }, {});
                return [
                  ...domainExport,
                  {
                    ...domain,
                    settingDate: settingDate ? formatDate(settingDate, FORMAT.DATE) : '',
                    chatbotAutoUpdateDate: chatbotAutoUpdateDate ? formatDate(chatbotAutoUpdateDate, FORMAT.DATE) : '',
                    chatbotSuspendDate: chatbotSuspendDate ? formatDate(chatbotSuspendDate, FORMAT.DATE) : '',
                    smoothAutoUpdateDate: smoothAutoUpdateDate ? formatDate(smoothAutoUpdateDate, FORMAT.DATE) : '',
                    smoothSuspendDate: smoothSuspendDate ? formatDate(smoothSuspendDate, FORMAT.DATE) : '',
                    isActive: isActive ? t('dashboard.active') : isActive === false ? t('dashboard.suspended') : '',
                    smoothStatus: smoothStatus
                      ? t('dashboard.active')
                      : smoothStatus === false
                      ? t('dashboard.suspended')
                      : '',
                    chatBotStatus: chatBotStatus
                      ? t('dashboard.active')
                      : chatBotStatus === false
                      ? t('dashboard.suspended')
                      : '',
                    ...externalAppCsv,
                  },
                ];
              },
              [],
            ),
          );
          csvLink?.current?.link?.click();
        }
      })
      .catch((e) => {
        return notification.error({ message: e.message });
      });
  };

  const basicMenu = (
    <Menu>
      <MenuItem icon={<VerticalAlignBottomOutlined />} onClick={() => exportDomains()}>
        <Button type="text" target="_blank" rel="noopener noreferrer">
          {t('groups.export')}
        </Button>
      </MenuItem>
      <MenuItem
        icon={<VerticalAlignTopOutlined />}
        onClick={() => {
          redirectToAddMultiDomain();
        }}
      >
        <Button type="text" target="_blank" rel="noopener noreferrer">
          {t('groups.import')}
        </Button>
      </MenuItem>
    </Menu>
  );

  const groups = JSON.parse(readProfile()).groups;

  const debounceFn = useCallback(
    debounce((data: any) => {
      changeKeyword(data);
    }, 1000),
    [],
  );

  const onChangeSearch = (data: any) => {
    debounceFn(data.target.value);
    setSearch(data.target.value);
  };

  const redirectToAddDomain = () => {
    navigate(`/domain/add${groupId ? `/${groupId}` : ''}`);
  };

  const redirectToAddMultiDomain = () => {
    navigate('/domain/add-multi');
  };

  return (
    <>
      <Card padding={20} className="height-max-content mb-2">
        <Button
          className="d-flex mr-2"
          type="primary"
          onClick={() => {
            redirectToAddDomain();
          }}
        >
          {t('button.addDomain')} <PlusOutlined />
        </Button>
        <Dropdown className="d-flex" overlay={basicMenu}>
          <Button type="primary" onClick={(e) => e.preventDefault()}>
            {t('button.CSVDomainManagement')} <FileExcelOutlined />
          </Button>
        </Dropdown>
      </Card>
      <S.Card
        id="basic-table"
        title={
          <>
            <InsertRowRightOutlined /> &nbsp; {t('domain.domainList')} &nbsp;
            <span className="count-record-table">
              ({dataList ? dataList.total : 0} {t('groups.records')})
            </span>
          </>
        }
        padding="1.5rem"
      >
        <>
          <div className="d-flex-space-between mb-2">
            {!hiddenGroupSearched && (
              <Select
                placeholder={t('domain.pleaseChooseGroup')}
                width={200}
                allowClear
                onChange={(data) => changeGroup(data)}
                showSearch
                style={{ width: 200 }}
                filterOption={(input, option) => {
                  return (option?.children?.toLowerCase() ?? '').includes(input.toLowerCase());
                }}
              >
                {groups.map((field: any) => (
                  <Option value={field.id}>{field.name}</Option>
                ))}
              </Select>
            )}
            <>
              <SearchInput
                className="right-search-input"
                placeholder={t('domain.searchDomain')}
                allowClear
                onChange={(data) => onChangeSearch(data)}
              />
            </>
          </div>
          <BasicTable
            groupId={groupId}
            dataList={dataList}
            changePagination={(data) => changePagination(data)}
            handleAfterSubmit={handleAfterSubmit}
            onRowClick={onRowClick}
          />
        </>
      </S.Card>
      <CSVLink
        data={domainExport}
        headers={headers}
        target="_blank"
        filename={`export_domain_${Date.now()}.csv`}
        ref={csvLink}
      />
    </>
  );
};
