import React from 'react';
import { Card } from 'components/common/Card/Card';
import { useTranslation } from 'react-i18next';
import { AppstoreOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse, Panel } from '@app/components/common/Collapse/Collapse';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { ExternalLinkPage } from './ExternalLinkPage';
import AIChatPage from './AIChatPage';
import SmoothAppPage from './SmoothAppPage';
import * as S from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import ExternalAppPage from './ExternalAppPage';

export const AppPreview: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card
      padding="1.875rem"
      title={
        <>
          <AppstoreOutlined />
          &nbsp;
          {t('dashboard.apps')}
        </>
      }
    >
      <S.CollapseWrapper defaultActiveKey={['2']} ghost>
        <div className="mb-2 ml-2">
          <div className="label-smooth-aichat">{t('dashboard.smooth')}</div>
          <SmoothAppPage isCallOpenAdd={null} />
        </div>
        <div className="mb-2 ml-2">
          <div className="label-smooth-aichat">{t('dashboard.aiChat')}</div>
          <AIChatPage isCallOpenAdd={null} />
        </div>
        <Panel header={`${t('dashboard.externalApps')}`} key="3">
          <ExternalAppPage />
        </Panel>
        <Panel header={`${t('dashboard.externalLink')}`} key="4">
          <ExternalLinkPage />
        </Panel>
      </S.CollapseWrapper>
    </Card>
  );
};
