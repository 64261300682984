import React, { useState, useEffect } from 'react';
import { Row, Col, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { MemberTable } from '@app/components/members/memberTable/MemberTable';
import { MemberHeader } from '@app/components/members/memberPreview/MemberHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { getMemberList } from '@app/api/member.api';

const MemberListPage: React.FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [dataList, setDataList] = useState({});

  useEffect(() => {
    onSearchMembers(page, limit, search);
  }, []);

  const onSearchMembers = (_page: number, _limit: number, _search: string) => {
    const payload = {
      page: _page,
      limit: _limit,
      search: _search,
    };
    getMemberList(payload)
      .then((res: any) => {
        if (res?.status === 'success') {
          setDataList(res.data);
        } else {
          setDataList({});
        }
      })
      .catch((e) => notification.error({ message: e.message }));
  };

  const changePagination = (paging: any) => {
    setPage(paging.current);
    setLimit(paging.pageSize);
    onSearchMembers(paging.current, paging.pageSize, search);
  };

  const changeKeyword = (search: any) => {
    setPage(1);
    setSearch(search);
    onSearchMembers(1, limit, search);
  };

  return (
    <>
      <PageTitle>{t('common.members')}</PageTitle>
      <Row gutter={[30, 30]}>
        <Col xs={24} lg={24}>
          <MemberHeader search={search} />
        </Col>
      </Row>
      <br />
      <MemberTable
        dataList={dataList}
        handleAfterSubmit={() => onSearchMembers(page, limit, search)}
        changePagination={(data) => changePagination(data)}
        changeKeyword={(data) => changeKeyword(data)}
      />
    </>
  );
};

export default MemberListPage;
