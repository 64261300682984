import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Card } from '@app/components/common/Card/Card';
import { Button } from '@app/components/common/buttons/Button/Button';
import { ProfileInfo } from '@app/components/profile/profileCard/ProfileInfo/ProfileInfo';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ProfileNav } from '@app/components/profile/profileCard/ProfileNav/ProfileNav';
import { useResponsive } from '@app/hooks/useResponsive';
import { getMember } from '@app/api/member.api';
import { notification } from 'antd';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setMember } from '@app/store/member/member';
import { Loading } from '@app/components/common/Loading';
import { readProfile } from '@app/services/localStorage.service';

const EditMemberPage: React.FC = () => {
  const { t } = useTranslation();
  const { isTablet: isTabletOrHigher, mobileOnly } = useResponsive();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.member.member);
  const user = id ? userState[0] : JSON.parse(readProfile()).user;
  const { isTablet } = useResponsive();
  const dispatch = useAppDispatch();

  const [dataProfile, setDataProfile] = useState({});
  const [loading, setLoading] = useState<boolean>(true);

  const isTitleShown = true; //isTabletOrHigher || (mobileOnly && location.pathname.indexOf("/members/edit"));
  const isMenuShown = true; //isTabletOrHigher || (mobileOnly && location.pathname.indexOf("/members/edit"));

  useEffect(() => {
    const check = (result: boolean, text: string) => result || location.pathname.includes(text);
    const path = ['personal-info', 'external-link', 'permission'];
    isTablet && !path.reduce(check, false) && navigate('personal-info');
  }, [isTablet, location.pathname, navigate]);

  useEffect(() => {
    getMember({
      userId: id,
    })
      .then((res: any) => {
        if (res?.statusCode == 200) {
          dispatch(setMember(res.data));
          setLoading(false);
        }
      })
      .catch((e: any) => {
        notification.error({ message: e.message });
      });
  }, []);

  useEffect(() => {
    if (userState) {
      setDataProfile(user);
    }
  }, [userState]);
  return !loading ? (
    <>
      <PageTitle>{t('profile.title')}</PageTitle>
      {!isTitleShown && (
        <Btn icon={<LeftOutlined />} type="text" onClick={() => navigate('/profile')}>
          {t('button.back')}
        </Btn>
      )}

      <Row gutter={[30, 30]}>
        {isMenuShown && (
          <Col xs={24} md={24} xl={8}>
            <ProfileCard>
              <Row gutter={[30, 30]}>
                <Col xs={24} md={12} xl={24}>
                  <ProfileInfo profileData={dataProfile} />
                </Col>

                <Col xs={24} md={12} xl={24}>
                  <ProfileNav />
                </Col>
              </Row>
            </ProfileCard>
          </Col>
        )}

        {isMenuShown && (
          <Col xs={24} md={24} xl={16}>
            <Outlet />
          </Col>
        )}
      </Row>
    </>
  ) : (
    <Card>
      <Loading />
    </Card>
  );
};

const ProfileCard = styled(Card)`
  height: unset;
`;

const Btn = styled(Button)`
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  padding: 0;
  height: unset;
  color: var(--secondary-color);
`;

export default EditMemberPage;
