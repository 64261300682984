import { callApi, METHOD } from '@app/api/http.api';
import axios from 'axios';
import { readToken } from '@app/services/localStorage.service';
import i18n from 'i18next';
import { ILogObject, objToQueryString, TypeUser } from '@app/utils/utils';
import history from '../components/router/history';

export const getReferenceInScope = async (): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.GET, 'members/type', null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getMemberList = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = {
      ...payload,
    };
    let response: any = await callApi(METHOD.GET, 'members', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getMember = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.GET, `members/${payload.userId}`, null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const postMember = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null && v != ''));
    payload = JSON.parse(JSON.stringify(payload).replace(/"\s+|\s+"/g, '"'));
    const { fullName, referenceId, referenceName, type } = payload;
    const query: ILogObject = {
      objectName: fullName,
      whereId: referenceId || 0,
      whereObject: type,
      whereName: referenceName || null,
    };
    let response: any = await callApi(METHOD.POST, `members` + `${objToQueryString(query)}`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const deleteMember = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    const { id, fullName, referenceId, referenceName, type } = payload;
    const query: ILogObject = {
      objectName: fullName,
      whereId: referenceId || 0,
      whereObject: type,
      whereName: referenceName || null,
    };
    let response: any = await callApi(METHOD.DELETE, `members/${id}` + `${objToQueryString(query)}`, { fullName });
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
  } catch (error) {
    throw error;
  }
};
export const unlockAccount = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    const { id, fullName, referenceId, referenceName, type } = payload;
    const query: ILogObject = {
      objectName: fullName,
      whereId: referenceId || 0,
      whereObject: type,
      whereName: referenceName || null,
    };
    let response: any = await callApi(METHOD.PATCH, `members/admin/unlock` + `${objToQueryString(query)}`, {
      userId: id,
    });
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const resetPassword = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    const { fullName, referenceId, referenceName, type } = payload;
    const query: ILogObject = {
      objectName: fullName,
      whereId: referenceId || 0,
      whereObject: type,
      whereName: referenceName || null,
    };
    let response: any = await callApi(
      METHOD.PATCH,
      `members/${payload.id}/admin/reset-password` + `${objToQueryString(query)}`,
      null,
    );
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const patchMember = async (idUser: any, payload: any): Promise<{ data: any } | undefined> => {
  try {
    const { fullName, referenceId, referenceName, type } = payload;
    const query: ILogObject = {
      objectName: fullName,
      whereId: referenceId || 0,
      whereObject: type,
      whereName: referenceName || null,
    };
    let response: any = await callApi(METHOD.PATCH, `members/${idUser}` + `${objToQueryString(query)}`, payload);
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message ? response?.data?.message : i18n.t('message.error500'));
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const blockMember = async (payload: any): Promise<{ data: any } | undefined> => {
  try {
    const { id, fullName, referenceId, referenceName, type } = payload;
    const query: ILogObject = {
      objectName: fullName,
      whereId: referenceId || 0,
      whereObject: type,
      whereName: referenceName || null,
    };
    let response: any = await callApi(METHOD.PUT, `members/${id}/block` + `${objToQueryString(query)}`, null);
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const exportMembers = async (search: any): Promise<any> => {
  const query: ILogObject = {
    objectName: null,
    whereId: null,
    whereObject: TypeUser.DOMAIN,
    whereName: null,
  };
  const payload = {
    search,
    ...query,
  };
  const response: any = await callApi(METHOD.GET, `members/export`, payload);

  return response?.data;
};

export const uploadFile = async (userId: any, payload: any) => {
  const formData = new FormData();
  formData.append('file', payload);
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_NX_CHAT_BOT_BASE_URL}/upload/${userId}`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${readToken()}` },
    });
    return response?.data;
  } catch (error: any) {
    if (error.response?.data?.statusCode == 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('expiresDate');
      localStorage.removeItem('expiresIn');
      history.push('/login');
    }
    throw new Error(
      error?.response?.data?.data?.message ? error?.response?.data?.data?.message : i18n.t('message.error500'),
    );
  }
};

export const getConversation = async (payload: any): Promise<{ data: any } | undefined> => {
  try {
    const { projectId, conversationId } = payload;
    const query: any = {
      conversationId,
    };
    let response: any = await callApi(
      METHOD.GET,
      `chatbots/chatbot-conversation/${projectId}` + `${objToQueryString(query)}`,
      null,
    );
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};
