import { callApi, METHOD } from '@app/api/http.api';
import i18n from '@app/i18n';

export interface IPasswordPolicyRequest {
  id?: number | string;
  payload?: any;
}

export const getPasswordPolicyList = async (): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.GET, 'settings/password/system', {});
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const setPasswordPolicyList = async (request: IPasswordPolicyRequest): Promise<{ data: any[] } | undefined> => {
  try {
    const { payload } = request;
    if (!payload) throw new Error(i18n.t('message.error500'));
    let response: any = await callApi(METHOD.PATCH, 'settings/password/system', payload);
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const getPasswordPolicyGroup = async (request: IPasswordPolicyRequest): Promise<{ data: any[] } | undefined> => {
  try {
    const { id } = request;
    if (!id) throw new Error(i18n.t('message.error500'));
    let response: any = await callApi(METHOD.GET, `settings/password/group/${id}`, {});
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const updatePasswordPolicyGroup = async (
  request: IPasswordPolicyRequest,
): Promise<{ data: any[] } | undefined> => {
  try {
    const { id, payload } = request;
    if (!id) throw new Error(i18n.t('message.error500'));
    let response: any = await callApi(METHOD.PATCH, `settings/password/group/${id}`, payload);
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const getPasswordPolicyDomain = async (
  request: IPasswordPolicyRequest,
): Promise<{ data: any[] } | undefined> => {
  try {
    const { id } = request;
    if (!id) throw new Error(i18n.t('message.error500'));
    let response: any = await callApi(METHOD.GET, `settings/password/domain/${id}`, {});
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const updatePasswordPolicyDomain = async (
  request: IPasswordPolicyRequest,
): Promise<{ data: any[] } | undefined> => {
  try {
    const { id, payload } = request;
    if (!id || !payload) throw new Error(i18n.t('message.error500'));
    let response: any = await callApi(METHOD.PATCH, `settings/password/domain/${id}`, payload);
    if (response?.data.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
  } catch (e: any) {
    throw e;
  }
};
