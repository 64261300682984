import React, { useEffect, useState } from 'react';
import { TablePaginationConfig, notification } from 'antd';
import { GroupTableRow, Pagination } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { EditOutlined, DeleteOutlined, StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { blockGroup, deleteGroup, IGroup } from '@app/api/group.api';
import { Modal } from '@app/components/common/Modal/Modal';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

interface TableListProps {
  dataList: any;
  changePagination: (data: any) => void;
  handleAfterSubmit: () => void;
  onRowClick?: (_data: any, _index: number | undefined) => void;
}

export const BasicTable: React.FC<TableListProps> = ({ dataList, changePagination, handleAfterSubmit, onRowClick }) => {
  const [tableData, setTableData] = useState<{ data: GroupTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isOpenModalBlock, setIsOpenModalBlock] = useState<boolean>(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [groupSelect, setGroupSelect] = useState<IGroup>();

  useEffect(() => {
    setTableData({
      data: dataList.data,
      pagination: {
        showSizeChanger: true,
        current: dataList.page,
        total: dataList.total,
      },
      loading: false,
    });
  }, [dataList]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    changePagination(pagination);
  };

  const onEdit = (groupId: number) => {
    navigate(`edit/${groupId}`);
  };

  const onView = (groupId: number) => {
    navigate(`view/${groupId}`);
  };

  const handleOpenModalBlock = (group: IGroup) => {
    setIsOpenModalBlock(true);
    setGroupSelect(group);
  };
  const handleCancelBlock = () => {
    setIsOpenModalBlock(false);
  };
  const handleSubmitBlock = async (groupId: number, name: string) => {
    await blockGroup({ groupId, name })
      .then((res: any) => {
        if (res && res?.status == 'success') {
          handleAfterSubmit();
          notification.success({ message: t('message.updateSuccess') });
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => {
        setIsOpenModalBlock(false);
      });
  };

  const handleOpenModalDelete = (group: IGroup) => {
    setIsOpenModalDelete(true);
    setGroupSelect(group);
  };
  const handleCancelDelete = () => {
    setIsOpenModalDelete(false);
  };
  const handleSubmitDelete = (groupId: number, name: string) => {
    deleteGroup({ groupId, name })
      .then((res: any) => {
        if (res && res?.status == 'success') {
          handleAfterSubmit();
          notification.success({ message: t('message.deleteGroupSuccessfully') });
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => {
        setIsOpenModalDelete(false);
      });
  };

  const columns: ColumnsType<IGroup> = [
    {
      title: t('groups.name'),
      dataIndex: 'name',
      showSorterTooltip: false,
      render: (text, { id }) => <a className="cursor-pointer">{text}</a>,
    },
    {
      title: t('groups.numberUser'),
      dataIndex: 'numberOfUsers',
      showSorterTooltip: false,
    },
    {
      title: t('groups.numberApp'),
      dataIndex: 'numberOfApps',
      showSorterTooltip: false,
    },
    {
      title: t('groups.status'),
      dataIndex: 'isActive',
      width: 130,
      render: (_, props) => (
        <>
          {props.isActive ? (
            <div className="badge-success">{t('members.active')}</div>
          ) : (
            <div className="badge-danger">{t('members.suspended')}</div>
          )}
        </>
      ),
      showSorterTooltip: false,
    },
    {
      title: t('groups.action'),
      className: 'action-column',
      dataIndex: 'contents',
      fixed: 'right',
      width: 120,
      render: (_, { id, isActive, ...props }) => (
        <>
          <EditOutlined
            className="btn-icon-save"
            onClick={(event) => {
              event.stopPropagation();
              onEdit(id);
            }}
          />
          <DeleteOutlined
            className="btn-icon-close ml-2"
            onClick={(event) => {
              event.stopPropagation();
              handleOpenModalDelete({ id, isActive, ...props });
            }}
          />
          <>
            {isActive ? (
              <StopOutlined
                className="btn-icon-block ml-2"
                onClick={(event) => {
                  event.stopPropagation();
                  handleOpenModalBlock({ id, isActive, ...props });
                }}
              />
            ) : (
              <CheckCircleOutlined
                className="btn-icon-active ml-2"
                onClick={(event) => {
                  event.stopPropagation();
                  handleOpenModalBlock({ id, isActive, ...props });
                }}
              />
            )}
          </>
        </>
      ),
      showSorterTooltip: false,
    },
  ];

  return (
    <>
      <Table
        className="event-table"
        columns={columns}
        dataSource={tableData.data}
        pagination={{ ...tableData.pagination, locale: { items_per_page: t('common.perPage') } }}
        loading={tableData.loading}
        onChange={handleTableChange}
        onRow={(_data: any, _index: number | undefined) => {
          return {
            onClick: () => {
              if (onRowClick) {
                onRowClick(_data, _index);
              } else {
                onView(_data.id);
              }
            },
          };
        }}
        scroll={{ x: 800 }}
        bordered
      />
      {groupSelect && (
        <Modal
          title={t('groups.blockGroup')}
          onCancel={handleCancelBlock}
          onOk={() => handleSubmitBlock(groupSelect.id, groupSelect.name)}
          visible={isOpenModalBlock}
        >
          {groupSelect.isActive ? t('groups.areYouSureWantToBlockGroup') : t('groups.areYouSureWantToUnLockGroup')}
          <strong>{groupSelect.name}</strong>?
        </Modal>
      )}

      {groupSelect && (
        <Modal
          title={t('groups.deleteGroup')}
          onCancel={handleCancelDelete}
          onOk={() => groupSelect && handleSubmitDelete(groupSelect.id, groupSelect.name)}
          visible={isOpenModalDelete}
        >
          {t('groups.areYouSureWantToDeleteGroup')}
          <strong>{groupSelect.name}</strong>?
        </Modal>
      )}
    </>
  );
};
