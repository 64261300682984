import React, { useState, useEffect } from 'react';
import ReactCrop, { makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useTranslation } from 'react-i18next';

interface Crop {
  aspect?: number | undefined;
  x: number;
  y: number;
  width: number;
  height: number;
  unit: 'px' | '%';
}

interface CropProps {
  src: any;
  onCrop: (data: any) => void;
}

const ProfileNav: React.FC<CropProps> = ({ src, onCrop }) => {
  const { t } = useTranslation();

  const [crop, setCrop] = useState<Crop>({
    aspect: 1,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    unit: 'px'
  });

  const onCropComplete = (crop:any, percentCrop: any) => {
    setCrop(crop)
    getCroppedImg(src, percentCrop, "image")
  }

  const onCropChange = (crop: any) => {
    setCrop(crop)
  }

  const getCroppedImg = (_image: any, pixelCrop: any, fileName: any) => {
    //var image = new Image(_image);
    const image = new Image();
    image.src = _image;
    image.crossOrigin="anonymous"
    image.addEventListener("load", (e) => {
      const canvas = document.createElement('canvas');
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height,
      );
     
      // As Base64 string
      const base64Image = canvas.toDataURL('image/jpeg');
      onCrop(base64Image)
    });
   
    // As a blob
    // return new Promise((resolve, reject) => {
    //   canvas.toBlob(file => {
    //     file.name = fileName;
    //     resolve(file);
    //     this.props.onCrop(file)
    //   }, 'image/jpeg');
    // });
  }

  return (
    <div className="App">
      {src && (
        <ReactCrop
          src={src}
          crop={crop}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      )}
    </div>
  );
};

export default ProfileNav