import { callApi, METHOD } from '@app/api/http.api';
import i18n from 'i18next';

export const getMailSetting = async (): Promise<any> => {
  try {
    const response: any = await callApi(METHOD.GET, 'mail-setting', null);
    if (response?.statusCode === 403) {
      console.log(response);
      return Promise.resolve({
        ...response,
        data: {
          ...response.data,
          message: i18n.t('message.doNotHavePermission', {
            action: i18n.t('common.view'),
            target: i18n.t('common.settingMail'),
          }),
        },
      });
    }
    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};

export const postMailSetting = async (payload: any): Promise<any> => {
  try {
    payload = {
      ...payload,
    };
    const response: any = await callApi(METHOD.POST, 'mail-setting', payload);
    if (response?.statusCode === 403) {
      return Promise.resolve({
        message: i18n.t('message.doNotHavePermission', {
          action: i18n.t('common.create'),
          target: i18n.t('common.settingMail'),
        }),
      });
    }
    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    //console.log(e)
  }
};

export const postVerifyEmail = async (payload: any): Promise<any> => {
  try {
    payload = {
      ...payload,
    };
    let response: any = await callApi(METHOD.POST, 'mail-setting/verify', payload);
    if (response?.data?.data) response = response.data;
    if (response?.statusCode === 403) {
      throw new Error(
        i18n.t('message.doNotHavePermission', {
          action: i18n.t('common.edit'),
          target: i18n.t('common.settingMail'),
        }),
      );
    }
    if (response?.status !== 'success')
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getTemplateMailDetail = async (id: any): Promise<any> => {
  try {
    const response: any = await callApi(METHOD.GET, `mail-template/${id}`, null);
    if (response?.statusCode === 403) {
      return Promise.resolve({
        message: i18n.t('message.doNotHavePermission', {
          action: i18n.t('common.view'),
          target: i18n.t('common.mailTemplateSetting'),
        }),
      });
    }
    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getTemplateMailByType = async (type: any): Promise<any> => {
  try {
    const response: any = await callApi(METHOD.GET, `mail-template/${null}?mailTemplateType=${type}`, null);
    if (response?.statusCode === 403) {
      return Promise.resolve({
        message: i18n.t('message.doNotHavePermission', {
          action: i18n.t('common.view'),
          target: i18n.t('common.mailTemplateSetting'),
        }),
      });
    }
    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getTemplateMailList = async (payload: any): Promise<any> => {
  try {
    payload = {
      ...payload,
    };
    const response: any = await callApi(METHOD.GET, 'mail-template', payload);
    if (response?.statusCode === 403) {
      return Promise.resolve({
        message: i18n.t('message.doNotHavePermission', {
          action: i18n.t('common.view'),
          target: i18n.t('common.mailTemplateSetting'),
        }),
      });
    }
    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getTemplateMailType = async (): Promise<any> => {
  try {
    const response: any = await callApi(METHOD.GET, 'mail-template/type', null);
    if (response?.statusCode === 403) {
      return Promise.resolve({
        message: i18n.t('message.doNotHavePermission', {
          action: i18n.t('common.view'),
          target: i18n.t('common.mailTemplateSetting'),
        }),
      });
    }
    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};

export const postTemplateMail = async (payload: any): Promise<any> => {
  try {
    const response: any = await callApi(METHOD.POST, 'mail-template', payload);
    if (response?.statusCode === 403) {
      return Promise.resolve({
        message: i18n.t('message.doNotHavePermission', {
          action: i18n.t('common.view'),
          target: i18n.t('common.mailTemplateSetting'),
        }),
      });
    }
    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    //console.log(e)
  }
};

export const patchTemplateMail = async (id: any, payload: any): Promise<any> => {
  try {
    const response: any = await callApi(METHOD.PATCH, `mail-template/${id}`, payload);
    if (response?.statusCode === 403) {
      return Promise.resolve({
        message: i18n.t('message.doNotHavePermission', {
          action: i18n.t('common.edit'),
          target: i18n.t('common.mailTemplateSetting'),
        }),
      });
    }
    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    //console.log(e)
  }
};

export const deleteTemplateMail = async (payload: any): Promise<any> => {
  try {
    const response: any = await callApi(METHOD.DELETE, `mail-template/${payload.userId}`, null);
    if (response?.statusCode === 403) {
      return Promise.resolve({
        message: i18n.t('message.doNotHavePermission', {
          action: i18n.t('common.delete'),
          target: i18n.t('common.mailTemplateSetting'),
        }),
      });
    }
    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    //console.log(e)
  }
};

export const postSendMail = async (payload: any): Promise<any> => {
  try {
    // payload = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null && v != ''));
    payload = JSON.parse(JSON.stringify(payload).replace(/"\s+|\s+"/g, '"'));
    const response: any = await callApi(METHOD.POST, 'mail-setting/send', payload);
    if (response?.statusCode === 403) {
      return Promise.resolve({
        message: i18n.t('message.doNotHavePermission', {
          action: i18n.t('common.view'),
          target: i18n.t('common.sendMail'),
        }),
      });
    }
    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    //console.log(e)
  }
};

export const getFrequently = async (): Promise<{ data: any[] } | undefined> => {
  try {
    const response: any = await callApi(METHOD.GET, `mail-template/frequently`, null);

    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getPeriod = async (): Promise<{ data: any[] } | undefined> => {
  try {
    const response: any = await callApi(METHOD.GET, `mail-template/period`, null);

    return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};
