import { UserOutlined, ProfileOutlined, LinkOutlined, TeamOutlined } from '@ant-design/icons';
import React from 'react';

interface ProfileNavItem {
  id: number;
  name: string;
  icon: React.ReactNode;
  color: 'primary' | 'error' | 'warning' | 'success';
  href: string;
  onlyEditMember: boolean,
  isDisabled: boolean,
}

export const profileNavData: ProfileNavItem[] = [
  {
    id: 1,
    name: 'profile.profile',
    icon: <UserOutlined />,
    color: 'primary',
    href: 'personal-info',
    onlyEditMember: false,
    isDisabled: false

  },
  {
    id: 4,
    name: 'profile.externalLink',
    icon: <LinkOutlined />,
    color: 'primary',
    href: 'external-link',
    onlyEditMember: false,
    isDisabled: false
  },
  // {
  //   id: 6,
  //   name: 'profile.groups',
  //   icon: <TeamOutlined />,
  //   color: 'primary',
  //   href: 'groups',
  //   onlyEditMember: false,
  //   isDisabled: false
  // },
  {
    id: 5,
    name: 'profile.permission',
    icon: <ProfileOutlined />,
    color: 'primary',
    href: 'permission',
    onlyEditMember: true,
    isDisabled: false
  },
  // {
  //   id: 5,
  //   name: 'profile.apps',
  //   icon: <LaptopOutlined />,
  //   color: 'primary',
  //   href: 'apps',
  //   onlyEditMember: true,
  //   isDisabled: true
  // },
  // {
  //   id: 7,
  //   name: 'profile.customAttributes',
  //   icon: <LaptopOutlined />,
  //   color: 'primary',
  //   href: 'custom-attributes',
  //   onlyEditMember: true,
  //   isDisabled: true
  // },
  // {
  //   id: 2,
  //   name: 'profile.devices',
  //   icon: <LaptopOutlined />,
  //   color: 'primary',
  //   href: 'devices',
  //   onlyEditMember: false,
  //   isDisabled: true
  // },
  // {
  //   id: 3,
  //   name: 'profile.pushAuthDevices',
  //   icon: <SisternodeOutlined />,
  //   color: 'primary',
  //   href: 'push-auth-devices',
  //   onlyEditMember: false,
  //   isDisabled: true
  // },
];
