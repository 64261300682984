import { SaveOutlined } from '@ant-design/icons';
import { postGroup } from '@app/api/group.api';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { RadioChangeEvent } from '@app/components/common/Radio/Radio';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { Col, notification, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
interface AddGroupData {
  name: string;
  mailAccount: string;
  subMailAccount: string;
  telephone: string;
  numberPostOffice: string;
  addressCity: string;
  addressWardStreet: string;
  subTelephone: string;
  department: string;
  representativeName: string;
}
export const initValues: AddGroupData = {
  name: '',
  mailAccount: '',
  subMailAccount: '',
  telephone: '',
  numberPostOffice: '',
  addressCity: '',
  addressWardStreet: '',
  subTelephone: '',
  department: '',
  representativeName: '',
};
const AddGroupPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [value, setValue] = useState(initValues);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const handleSubmit = (data: any) => {
    setIsLoading(true);
    postGroup(data)
      .then((res: any) => {
        if (res?.status == 'success') {
          notification.success({ message: res.data });
          navigate('/groups');
        }
      })
      .catch((e: any) => {
        notification.error({ message: e.message });
        setIsLoading(false);
      });
  };

  return (
    <>
      <div>{t('groups.labelCreateGroup')}</div>
      <div className="mb-2">{t('groups.labelNoteCreateGroup')}</div>
      <Auth.FormWrapper>
        <BaseForm
          confirmSubmitProps={{ active: true }}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
        >
          <Row gutter={[10, 10]}>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="name"
                label={t('groups.name')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('groups.name')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="mailAccount"
                label={t('groups.mailAccount')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { type: 'email', message: t('validator.notValidEmail') },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('groups.mailAccount')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="subMailAccount"
                label={t('groups.subMailAccount')}
                rules={[
                  { type: 'email', message: t('validator.notValidEmail') },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('groups.subMailAccount')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="department"
                label={t('groups.department')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('groups.department')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="representativeName"
                label={t('groups.representativeName')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('groups.representativeName')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="telephone"
                label={t('groups.telephone')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { pattern: /^0[0-9]{9,12}$/, message: t('validator.telephoneFormatIncorrect') },
                ]}
              >
                <Auth.FormInput placeholder={t('groups.telephone')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="subTelephone"
                label={t('groups.subTelephone')}
                rules={[{ pattern: /^0[0-9]{9,12}$/, message: t('validator.telephoneFormatIncorrect') }]}
              >
                <Auth.FormInput placeholder={t('groups.subTelephone')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="numberPostOffice"
                label={t('groups.numberPostOffice')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { pattern: /^〒?[0-9]{3}-?[0-9]{4}$/g, message: t('validator.numberPostOfficeFormatIncorrect') },
                ]}
              >
                <Auth.FormInput placeholder={t('groups.numberPostOffice')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="addressCity"
                label={t('groups.addressCity')}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('common.requiredField')));
                    },
                  },
                  { max: 255, message: t('validator.maxLength', { count: 255 }) },
                ]}
              >
                <Auth.FormInput placeholder={t('groups.addressCity')} />
              </Auth.FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Auth.FormItem
                name="addressWardStreet"
                label={t('groups.addressWardStreet')}
                rules={[{ max: 255, message: t('validator.maxLength', { count: 255 }) }]}
              >
                <Auth.FormInput placeholder={t('groups.addressWardStreet')} />
              </Auth.FormItem>
            </Col>
          </Row>
          <BaseForm.Item noStyle>
            <Auth.SubmitButton disabled={isLoading} type="primary" htmlType="submit">
              {t('button.save')} <SaveOutlined />
            </Auth.SubmitButton>
          </BaseForm.Item>
        </BaseForm>
      </Auth.FormWrapper>
    </>
  );
};
export default AddGroupPage;
