import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { GroupTable } from '@app/components/groups/groupTable/GroupTable';
import { Card } from '@app/components/common/Card/Card';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { getGroupList, exportGroup } from '@app/api/group.api';
import { notification } from 'antd';
import { Button } from '@app/components/common/buttons/Button/Button';
import {
  FileExcelOutlined,
  VerticalAlignTopOutlined,
  PlusOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { formatDate } from '@app/utils/utils';

const GroupListPage: React.FC = () => {
  const { t } = useTranslation();
  const csvLink = useRef<any>();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [dataList, setDataList] = useState([]);
  const [headers, setHeaders] = useState<{ label: string; key: string }[]>([]);
  const [groupExports, setGroupExports] = useState<any>([]);
  const navigate = useNavigate();

  const basicMenu = (
    <Menu>
      <MenuItem icon={<VerticalAlignBottomOutlined />} onClick={() => exportGroupList()}>
        <Button type="text" target="_blank" rel="noopener noreferrer">
          {t('groups.export')}
        </Button>
      </MenuItem>
      <MenuItem
        icon={<VerticalAlignTopOutlined />}
        onClick={() => {
          redirectToAddMultiGroup();
        }}
      >
        <Button type="text" target="_blank" rel="noopener noreferrer">
          {t('groups.import')}
        </Button>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    onSearchGroups(page, limit, search);
  }, []);

  const redirectToAddGroup = () => {
    navigate('/groups/add');
  };

  const redirectToAddMultiGroup = () => {
    navigate('/groups/add-multi');
  };

  const changePagination = (paging: any) => {
    setPage(paging.current);
    setLimit(paging.pageSize);
    onSearchGroups(paging.current, paging.pageSize, search);
  };

  const changeKeyword = (search: any) => {
    setPage(1);
    setSearch(search);
    onSearchGroups(1, limit, search);
  };

  const exportGroupList = async () => {
    await exportGroup(search)
      .then((res: any) => {
        if (res?.status == 'success') {
          const { data: groups } = res;
          const headers = [
            { label: t('csvFile.groupName'), key: 'name' },
            { label: t('csvFile.createdAt'), key: 'createdAt' },
            { label: t('csvFile.status'), key: 'isActive' },
            { label: t('csvFile.nameOfDirector'), key: 'representativeName' },
            { label: t('csvFile.department'), key: 'department' },
            { label: t('csvFile.emailAddress'), key: 'mailAccount' },
            { label: t('csvFile.subEmailAddress'), key: 'subMailAccount' },
            { label: t('csvFile.telephone'), key: 'telephone' },
            { label: t('csvFile.subTelephone'), key: 'subTelephone' },
            { label: t('csvFile.numberPostOffice'), key: 'numberPostOffice' },
            { label: t('csvFile.addressCity'), key: 'addressCity' },
            { label: t('csvFile.addressWardStreet'), key: 'addressWardStreet' },
            { label: t('csvFile.numberDomains'), key: 'numberDomain' },
            { label: t('csvFile.numberUsers'), key: 'numberUser' },
          ];
          setHeaders(headers);

          setGroupExports(
            groups.map(({ createdAt, isActive, ...group }: any) => {
              return {
                createdAt: createdAt ? formatDate(createdAt) : '',
                isActive: isActive ? t('dashboard.active') : isActive === false ? t('dashboard.suspended') : '',
                ...group,
              };
            }),
          );
          csvLink?.current?.link?.click();
        }
      })
      .catch((e) => notification.error({ message: e.message }));
  };

  const onSearchGroups = (page: number, limit: number, search: string) => {
    getGroupList({ page, limit, search })
      .then((res: any) => {
        if (res && res?.status && res?.status == 'success') {
          setDataList(res.data);
        } else {
          setDataList([]);
        }
      })
      .catch((e) => notification.error({ message: e.message }));
  };

  return (
    <>
      <PageTitle>{t('common.groups')}</PageTitle>
      <Row gutter={[30, 30]}>
        <Col xs={24} lg={24}>
          <Card padding="1rem" className="d-inherit">
            <Button
              className=""
              type="primary"
              onClick={() => {
                redirectToAddGroup();
              }}
            >
              {t('button.addGroup')} <PlusOutlined />
            </Button>
            <Dropdown overlay={basicMenu} className="ml-2">
              <Button type="primary" onClick={(e) => e.preventDefault()}>
                {t('button.CSVGroupManagement')} <FileExcelOutlined />
              </Button>
            </Dropdown>
          </Card>
        </Col>
      </Row>
      <br />
      <GroupTable
        dataList={dataList}
        changePagination={(data) => changePagination(data)}
        changeKeyword={(data) => changeKeyword(data)}
        handleAfterSubmit={() => onSearchGroups(page, limit, search)}
      />
      <CSVLink
        data={groupExports}
        headers={headers}
        target="_blank"
        filename={`export_group_${Date.now()}.csv`}
        ref={csvLink}
      />
    </>
  );
};

export default GroupListPage;
