import { callApi, METHOD } from '@app/api/http.api';
import { getProfile } from '@app/api/login.api';
import i18n from '@app/i18n';
import { ILogObject, objToQueryString, TypeUser } from '@app/utils/utils';

export interface IGroup {
  addressCity: string;
  addressWardStreet: string;
  createdAt: string;
  department: string;
  id: number;
  isActive: boolean;
  mailAccount: string;
  name: string;
  numberPostOffice: string;
  representativeName: string;
  subMailAccount: string;
  subTelephone: string;
  telephone: string;
}

export interface IUpdateGroup {
  groupId: number;
  name: string;
}

export const getGroupList = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = {
      ...payload,
    };
    let response: any = await callApi(METHOD.GET, 'groups', payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const getGroup = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    let response: any = await callApi(METHOD.GET, `groups/${payload.groupId}`, null);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const postGroup = async (payload: any): Promise<{ data: any[] } | undefined> => {
  try {
    payload = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null && v != ''));
    payload = JSON.parse(JSON.stringify(payload).replace(/"\s+|\s+"/g, '"'));
    const { name } = payload;
    const query: ILogObject = {
      objectName: name,
      whereId: null,
      whereObject: TypeUser.GROUP,
      whereName: name,
    };
    let response: any = await callApi(METHOD.POST, `groups` + `${objToQueryString(query)}`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    } else {
      getProfile();
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const patchGroup = async (idGroup: any, payload: any): Promise<{ data: any[] }> => {
  try {
    payload = Object.fromEntries(
      Object.entries(payload).filter(
        ([_, v]) => typeof v == 'boolean' || (typeof v != 'boolean' && v != null && !Number.isNaN(v)),
      ),
    );
    payload = JSON.parse(JSON.stringify(payload).replace(/"\s+|\s+"/g, '"'));
    const { name } = payload;
    const query: ILogObject = {
      objectName: name,
      whereId: idGroup,
      whereObject: TypeUser.GROUP,
      whereName: name,
    };
    let response: any = await callApi(METHOD.PATCH, `groups/${idGroup}` + `${objToQueryString(query)}`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    } else {
      getProfile();
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const exportGroup = async (search: any): Promise<any> => {
  try {
    const query: ILogObject = {
      objectName: null,
      whereId: null,
      whereObject: TypeUser.GROUP,
      whereName: null,
    };
    const payload = {
      search,
      ...query,
    };
    let response: any = await callApi(METHOD.GET, `groups/export`, payload);
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const blockGroup = async (payload: any): Promise<{ data: any } | undefined> => {
  try {
    const { groupId, name } = payload;
    const query: ILogObject = {
      objectName: name,
      whereId: groupId,
      whereObject: TypeUser.GROUP,
      whereName: name,
    };
    let response: any = await callApi(METHOD.PUT, `groups/${groupId}/block` + `${objToQueryString(query)}`, {});
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    } else {
      getProfile();
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};

export const deleteGroup = async (payload: IUpdateGroup): Promise<{ data: any } | undefined> => {
  try {
    const { groupId, name } = payload;
    const query: ILogObject = {
      objectName: name,
      whereId: groupId,
      whereObject: TypeUser.GROUP,
      whereName: name,
    };
    let response: any = await callApi(METHOD.DELETE, `groups/${groupId}` + `${objToQueryString(query)}`, { name });
    if (response?.data?.data) response = response.data;
    if (response?.status !== 'success') {
      throw new Error(response?.data?.message || response?.data?.[0]?.messages?.[0] || i18n.t('message.error500'));
    } else {
      getProfile();
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
};
