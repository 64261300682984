import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ForgotHMACForm } from '@app/components/auth/ForgotHMACForm/ForgotHMACForm';

const ForgotHMACPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.forgotHMACTitle')}</PageTitle>
      <ForgotHMACForm />
    </>
  );
};

export default ForgotHMACPage;
