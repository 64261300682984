import React, { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './index.style';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notification } from 'antd';
import { postUnlockAccount } from '@app/api/login.api';

interface IUnlockAccountFormData {
  email: string;
}

const initValues = {
  email: null,
};

export const UnlockAccount: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values: IUnlockAccountFormData) => {
    setLoading(true);
    const formatMsg = (str: string) => {
      const arr = str.split(/(?:\\[rn]|[\r\n]+)+/g);
      return arr.length ? (
        <>
          {arr[0]}
          <br />
          {arr[1]}
        </>
      ) : (
        str
      );
    };
    await postUnlockAccount(values)
      .then((result: any) => {
        if (result?.status === 'success') {
          // navigate(`/login`);
          notification.success({ message: result.data || t('message.pleaseCheckEmail') });
        }
      })
      .catch((e) => notification.error({ message: formatMsg(e.message) }));
    setLoading(false);
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.BackWrapper onClick={() => navigate('/login')}>
          <Auth.BackIcon />
          {t('button.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('common.unlockAccount')}</Auth.FormTitle>
        <S.Description>{t('unlockAccount.description')}</S.Description>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('common.requiredField')));
              },
            },
            {
              type: 'email',
              message: t('validator.notValidEmail'),
            },
            { max: 255, message: t('validator.maxLength', { count: 255 }) },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('forgotPassword.sendInstructions')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
