import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { DropdownMenu } from '@app/components/header/Header.styles';
import { logout } from '@app/services/localStorage.service';
import notification from 'antd/lib/notification';
export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onLogout = async () => {
    logout();
    navigate('/login');
  };
  const onProfile = async () => {
    navigate('/profile');
  };

  return (
    <DropdownMenu selectable={false} {...props}>
      <S.MenuItem key={0} onClick={onProfile}>
        <S.Text>
          <Link to="/profile">{t('common.profile')}</Link>
        </S.Text>
      </S.MenuItem>
      <S.ItemsDivider />
      <S.MenuItem key={1} onClick={onLogout}>
        <S.Text>
          <Link to="/login">{t('common.logout')}</Link>
        </S.Text>
      </S.MenuItem>
    </DropdownMenu>
  );
};
