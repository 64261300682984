import { DiffOutlined, SaveOutlined } from '@ant-design/icons';
import { getRole, getRolesPermissions, getRolesSetting, patchRole, postRole } from '@app/api/role.api';
import { Card } from '@app/components/common/Card/Card';
import { ConfirmModal } from '@app/components/common/Modal/Modal';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Option, Select } from '@app/components/common/selects/Select/Select';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { RoleTable } from '@app/components/role/roleTable/RoleTable';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setDirty } from '@app/store/dirty/dirty';
import { Col, Input, Row, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export enum RoleAction {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
}
const RoleSettingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, action } = useParams();
  const [loading, setLoading] = useState(false);
  const [typeAction, setTypeAction] = useState<RoleAction>((action as RoleAction) || RoleAction.VIEW);
  const [showSelect, setShowSelect] = useState(true);

  const [roleRender, setRoleRender] = React.useState([]);
  const [domains, setDomains] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [roleList, setRoleList] = useState<any[]>([]);
  const [roleType, setRoleType] = useState<any[]>([]);
  const [groupSelected, setGroupSelected] = useState<string | null>(null);
  const [isShowTable, setIsShowTable] = useState<boolean>(true);
  const [typeSelected, setTypeSelected] = useState<string | null>(null);
  const [domainSelected, setDomainSelected] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [roleSetting, setRoleSetting] = useState({});

  const dispatch = useAppDispatch();

  useEffect(() => {
    setShowSelect(false);
    setLoading(true);
    getRolesSetting(typeAction === RoleAction.EDIT ? 'update' : typeAction)
      .then((res) => {
        if (res?.status == 'success') {
          const { settingRoles, TypesRole } = res.data;
          setRoleSetting(settingRoles);
          setRoleType(TypesRole);
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => {
        setLoading(false);
        setShowSelect(true);
      });
    !id && onSearchRoles();
  }, [typeAction, id]);

  useEffect(() => {
    action === RoleAction.VIEW && dispatch(setDirty(false));
    setTypeAction(action as RoleAction);
  }, [action]);

  useEffect(() => {
    id && onGetRole('');
  }, [id]);

  const onSubmit = () => {
    const handleSubmit = () => {
      const permissions: any[] = [];
      const subRoles: any[] = [];
      roleList.forEach((root: any) => {
        root.permissions.forEach((rootPermission: any) => {
          rootPermission.isActive && !rootPermission.disabled && permissions.push(`${rootPermission.name}`);
        });
        const subRoleRoot = (root.children || []).map((data: any) => {
          return {
            type: root.type === 'system' ? 'group' : 'domain',
            resourceId: data.id,
            id: data.roleId,
            permissions: data.permissions
              .filter((_data: any) => _data.isActive && !_data.disabled)
              .map((_data: any) => `${_data.name}`),
            subRoles: data.children
              ? data.children.map((_data: any) => {
                  return {
                    permissions: _data.permissions
                      .filter((_data: any) => _data.isActive && !_data.disabled)
                      .map((_data: any) => `${_data.name}`),
                    type: 'domain',
                    resourceId: _data.id,
                    id: _data.roleId,
                    subRoles: [],
                  };
                })
              : [],
          };
        });
        subRoleRoot.length && subRoles.push(subRoleRoot);
      });
      const getSubRole = subRoles.flat(1);
      const resource: any = (domainSelected ? domains : groupSelected ? groups : []).find(
        ({ id }: any) => id === (domainSelected || groupSelected),
      );
      const payload = {
        type: typeSelected,
        name,
        permissions,
        resourceId: domainSelected || groupSelected || 0,
        whereName: resource?.domainName || resource?.name || null,
        subRoles: getSubRole,
      };
      setLoading(true);
      (id ? patchRole(id, payload) : postRole(payload))
        .then((res: any) => {
          if (res && res.status == 'success') {
            notification.success({ message: res.data });
            navigate(`/role`);
          }
        })
        .catch((e) => notification.error({ message: e.message }))
        .finally(() => setLoading(false));
    };

    if (action !== RoleAction.VIEW) {
      ConfirmModal({
        title: t('common.titleConfirmation'),
        content: `${t('common.contentOkConfirm')}`,
        onOk: () => handleSubmit(),
        okText: t('button.yes'),
        cancelText: t('button.no'),
      });
    } else {
      navigate(`/role/edit/${id}`);
    }
  };

  const onGetRole = async (_roleBase: any) => {
    setShowSelect(false);
    setLoading(true);
    await getRole({
      id,
    })
      .then((res: any) => {
        if (res && res?.status == 'success') {
          if (res.data.type.includes('domain')) {
            const { groupId, domainId } = res.data;
            setGroupSelected(groupId);
            setDomainSelected(domainId);
            handleChangeType(res.data, _roleBase, groupId, domainId);
          }
          if (res.data.type.includes('group')) {
            setGroupSelected(res.data.resourceId);
            handleChangeType(res.data, _roleBase, res.data.resourceId);
          }
          if (res.data.type.includes('system')) {
            handleChangeType(res.data, _roleBase);
          }
          setName(res.data.name);
          onSearchRoles(res?.data?.type, res?.data?.resourceId);
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setShowSelect(true);
        });
      });
  };

  const onSearchRoles = (typeRole?: string, resourceId?: number) => {
    if (!typeRole) return;
    setIsShowTable(false);
    setLoading(true);
    const payload: any = {
      typeRole,
      resourceId,
      optionCheck: typeAction === RoleAction.EDIT ? 'update' : typeAction,
    };

    if (!!id) {
      payload['roleId'] = parseInt(id);
    }

    getRolesPermissions(payload)
      .then(async (res: any) => {
        if (res && res.data) {
          setRoleRender(res.data?.data); // update role
          setRoleList(res.data?.data);
          setDomains(res?.data?.settingRoles?.domains || []);
          setGroups(res?.data?.settingRoles?.groups || []);
        }
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => {
        setLoading(false);
        setIsShowTable(true);
      });
  };

  const onChangeGroup = (data: any) => {
    setGroupSelected(data);
    setDomainSelected(null);
    !id && typeSelected?.includes('group') && onSearchRoles(typeSelected, data);
  };

  const onChangeDomain = (data: any) => {
    setDomainSelected(data);
    !id && typeSelected?.includes('domain') && onSearchRoles(typeSelected, data);
  };

  const handleChangeType = (data: any, _: any | undefined, group?: any, domain?: any) => {
    const type = typeof data == 'string' ? data : data?.type || null;
    setTypeSelected(type);
    !id && onSearchRoles(type);
    setGroupSelected(group || null);
    setDomainSelected(domain || null);
  };

  const handleChangeName = (data: any) => {
    setName(data.target.value);
  };

  return (
    <>
      <PageTitle>
        {typeAction === RoleAction.CREATE
          ? t('role.createRole')
          : typeAction === RoleAction.EDIT
          ? t('role.editRole')
          : t('role.viewRole')}
      </PageTitle>
      <Card padding="1rem">
        <Card
          className="height-auto clear-shadow"
          padding="1.75rem"
          title={
            <>
              <DiffOutlined />{' '}
              {typeAction === RoleAction.CREATE
                ? t('role.createRole')
                : typeAction === RoleAction.EDIT
                ? t('role.editRole')
                : t('role.viewRole')}
            </>
          }
        >
          {/* {showSelect ? ( */}
          <BaseForm
            fields={[
              { name: ['type'], value: typeSelected },
              { name: ['name'], value: name },
              { name: ['group'], value: groupSelected },
              { name: ['domain'], value: domainSelected },
            ]}
            onFinish={onSubmit}
            requiredMark="optional"
          >
            <Row gutter={8} justify={'space-between'}>
              <Col xl={20}>
                <Row gutter={8}>
                  <Col xl={6}>
                    <Auth.FormItem
                      name="type"
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                      ]}
                    >
                      <Select
                        // value={typeSelected}
                        loading={loading}
                        disabled={[RoleAction.VIEW, RoleAction.EDIT].some((t) => t === action)}
                        onChange={handleChangeType}
                      >
                        <Option value={null}>{t('role.selectRoleType')}</Option>
                        {roleType.map((object: string) => (
                          <Option value={object}>{object}</Option>
                        ))}
                      </Select>
                    </Auth.FormItem>
                  </Col>
                  <Col xl={6}>
                    <Auth.FormItem
                      name="name"
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      ]}
                    >
                      <Input
                        // value={name}
                        disabled={action === RoleAction.VIEW}
                        placeholder={t('role.roleName')}
                        onBlur={(e) => {
                          setName(e.target.value.trim());
                        }}
                        onChange={handleChangeName}
                      />
                    </Auth.FormItem>
                  </Col>
                  {['domainAdmin', 'domainUser', 'groupAdmin', 'groupUser'].includes(typeSelected || '') && (
                    <Col xl={6}>
                      <Auth.FormItem
                        name="group"
                        rules={[
                          {
                            required: true,
                            validator: (_, value) => {
                              return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('common.requiredField')));
                            },
                          },
                        ]}
                      >
                        <Select
                          // value={groupSelected}
                          loading={loading}
                          disabled={[RoleAction.VIEW, RoleAction.EDIT].some((t) => t === action)}
                          onChange={(data: any) => onChangeGroup(data)}
                        >
                          <Option value={null}>{t('role.selectGroup')}</Option>
                          {groups.map((object: any) => (
                            <Option value={object.id}>{object.name}</Option>
                          ))}
                        </Select>
                      </Auth.FormItem>
                    </Col>
                  )}
                  {typeSelected?.includes('domain') && (
                    <Col xl={6}>
                      <Auth.FormItem
                        name="domain"
                        rules={[
                          {
                            required: true,
                            validator: (_, value) => {
                              return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('common.requiredField')));
                            },
                          },
                        ]}
                      >
                        <Select
                          // value={domainSelected}
                          loading={loading}
                          disabled={[RoleAction.VIEW, RoleAction.EDIT].some((t) => t === action) || !groupSelected}
                          onChange={(data: any) => onChangeDomain(data)}
                        >
                          <Option value={null}>{t('role.selectDomain')}</Option>
                          {domains
                            .filter((i: any) => i.groupId == groupSelected)
                            .map((object: any) => (
                              <Option value={object.id}>{object.domainName}</Option>
                            ))}
                        </Select>
                      </Auth.FormItem>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xl={4}>
                <Row justify={'end'}>
                  <Auth.FormItem>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      className={'btn-submit-create-role'}
                      hidden={typeSelected === 'systemAdmin'}
                      loading={loading}
                    >
                      {[RoleAction.CREATE, RoleAction.EDIT].some((t) => t === action) ? (
                        <span>
                          <SaveOutlined /> {t('button.save')}
                        </span>
                      ) : (
                        t('button.edit')
                      )}
                    </Button>
                  </Auth.FormItem>
                </Row>
              </Col>
            </Row>
          </BaseForm>
          {/* ) : (
            <Loading />
          )} */}
        </Card>

        {typeSelected && isShowTable && (
          <Row gutter={[30, 30]}>
            <Col xs={24} lg={24}>
              {/* <RoleSetting /> */}
              <RoleTable
                typeAction={typeAction}
                typeSelected={typeSelected}
                data={roleRender}
                roleSetting={roleSetting}
                reloadDataTable={(data: any) => setRoleList(data)}
              />
            </Col>
          </Row>
        )}
      </Card>
    </>
  );
};

export default RoleSettingPage;
