import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { getGroup, patchGroup } from '@app/api/group.api';
import { Card } from '@app/components/common/Card/Card';
import { Loading } from '@app/components/common/Loading';
import { TabPane, Tabs } from '@app/components/common/Tabs/Tabs';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import DomainListPage from '@app/pages/DomainPages/DomainListPage';
import { Col, Row, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

interface EditGroupData {
  name: string;
  mailAccount: string;
  subMailAccount?: string;
  telephone: string;
  numberPostOffice: string;
  addressCity: string;
  addressWardStreet?: string;
  subTelephone?: string;
  department: string;
  representativeName: string;
}
export const initValues: EditGroupData = {
  name: '',
  mailAccount: '',
  subMailAccount: undefined,
  telephone: '',
  numberPostOffice: '',
  addressCity: '',
  addressWardStreet: undefined,
  subTelephone: undefined,
  department: '',
  representativeName: '',
};

const EditGroupPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [value, setValue] = useState(initValues);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { idGroup: groupId, action } = useParams();

  useEffect(() => {
    setIsEdit(action === 'edit');
  }, [action]);

  useEffect(() => {
    getGroup({
      groupId,
    })
      .then((res: any) => {
        if (res?.statusCode == 200 && res.data) {
          setValue(res.data);
          setShowForm(true);
        }
      })
      .catch((e: any) => notification.error({ message: e.message }));
  }, []);

  const handleSubmit = (data: any) => {
    setIsLoading(true);
    patchGroup(groupId, data)
      .then((res: any) => {
        if (res?.status == 'success') {
          notification.success({ message: t('message.updateSuccess') });
          navigate('/groups');
        }
      })
      .catch((error) => {
        notification.error({ message: error.message });
        setIsLoading(false);
      });
  };

  return showForm ? (
    <>
      <Card padding="1.75rem">
        <Tabs defaultActiveKey="1">
          <TabPane tab={`${t('groups.groupInformation')}`} key="1">
            <Auth.FormWrapper className="p-0 overflow-hidden">
              <h2>{t('groups.groupInformation')}</h2>
              <BaseForm
                confirmSubmitProps={{ active: true }}
                layout="vertical"
                onFinish={handleSubmit}
                requiredMark="optional"
                initialValues={value}
              >
                <Row gutter={[10, 10]}>
                  <Col span={24}>
                    <Row gutter={[10, 10]} justify={'end'}>
                      <Col>
                        <Button type="primary" onClick={() => navigate(`/groups/edit/${groupId}/policy-setting`)}>
                          {t('button.editPolicy')}
                        </Button>
                      </Col>
                      {!isEdit && (
                        <Col>
                          <Button
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => {
                              navigate(`/groups/edit/${groupId}`);
                            }}
                          >
                            {t('button.edit')}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Auth.FormItem
                      name="name"
                      label={t('groups.name')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      ]}
                    >
                      <Auth.FormInput disabled={true} placeholder={t('groups.name')} />
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Auth.FormItem
                      name="mailAccount"
                      label={t('groups.mailAccount')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        {
                          type: 'email',
                          message: t('validator.notValidEmail'),
                        },
                        { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      ]}
                    >
                      <Auth.FormInput disabled={!isEdit} placeholder={t('groups.mailAccount')} />
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Auth.FormItem
                      name="subMailAccount"
                      label={t('groups.subMailAccount')}
                      rules={[
                        {
                          type: 'email',
                          message: t('validator.notValidEmail'),
                        },
                        { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      ]}
                    >
                      <Auth.FormInput disabled={!isEdit} placeholder={t('groups.subMailAccount')} />
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Auth.FormItem
                      name="department"
                      label={t('groups.department')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      ]}
                    >
                      <Auth.FormInput disabled={!isEdit} placeholder={t('groups.department')} />
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Auth.FormItem
                      name="representativeName"
                      label={t('groups.representativeName')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      ]}
                    >
                      <Auth.FormInput disabled={!isEdit} placeholder={t('groups.representativeName')} />
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Auth.FormItem
                      name="telephone"
                      label={t('groups.telephone')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        { pattern: /^0[0-9]{9,12}$/, message: t('validator.telephoneFormatIncorrect') },
                      ]}
                    >
                      <Auth.FormInput disabled={!isEdit} placeholder={t('groups.telephone')} />
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Auth.FormItem
                      name="subTelephone"
                      label={t('groups.subTelephone')}
                      rules={[{ pattern: /^0[0-9]{9,12}$/, message: t('validator.telephoneFormatIncorrect') }]}
                    >
                      <Auth.FormInput disabled={!isEdit} placeholder={t('groups.subTelephone')} />
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Auth.FormItem
                      name="numberPostOffice"
                      label={t('groups.numberPostOffice')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        {
                          pattern: /^〒?[0-9]{3}-?[0-9]{4}$/g,
                          message: t('validator.numberPostOfficeFormatIncorrect'),
                        },
                      ]}
                    >
                      <Auth.FormInput disabled={!isEdit} placeholder={t('groups.numberPostOffice')} />
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Auth.FormItem
                      name="addressCity"
                      label={t('groups.addressCity')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        { max: 255, message: t('validator.maxLength', { count: 255 }) },
                      ]}
                    >
                      <Auth.FormInput disabled={!isEdit} placeholder={t('groups.addressCity')} />
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Auth.FormItem
                      name="addressWardStreet"
                      label={t('groups.addressWardStreet')}
                      rules={[{ max: 255, message: t('validator.maxLength', { count: 255 }) }]}
                    >
                      <Auth.FormInput disabled={!isEdit} placeholder={t('groups.addressWardStreet')} />
                    </Auth.FormItem>
                  </Col>
                </Row>
                {isEdit && (
                  <BaseForm.Item noStyle>
                    <Auth.SubmitButton disabled={isLoading} className="mt-2" type="primary" htmlType="submit">
                      {t('button.save')} <SaveOutlined />
                    </Auth.SubmitButton>
                  </BaseForm.Item>
                )}
              </BaseForm>
            </Auth.FormWrapper>
          </TabPane>
          <TabPane tab={`${t('groups.domainList')}`} key="2">
            {groupId && <DomainListPage groupId={Number(groupId)} hiddenGroupSearched />}
          </TabPane>
          {/* <TabPane tab={`${t('policy.policySetting')}`} key="3">
            <PasswordPolicyDomain />
          </TabPane> */}
        </Tabs>
      </Card>
    </>
  ) : (
    <>
      <Loading />
    </>
  );
};
export default EditGroupPage;
