import styled from 'styled-components';
import { Upload as AntdUpload } from 'antd';

export const Upload = styled(AntdUpload)``;

export const DraggerIconWrapper = styled.div`
    font-size: 4rem;
    color: var(--primary-color);
  `;

export const DraggerTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const DraggerDescription = styled.div`
  font-size: 14px;
  padding: 0 1rem;
`;

