import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Image, Spin, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Button } from '@app/components/common/buttons/Button/Button';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import { CheckCircleOutlined, CloseCircleOutlined, LeftOutlined } from '@ant-design/icons';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './index.style';
import { patchUnlockAccountConfirm } from '@app/api/login.api';

export const UnlockAccountConfirm: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [status, setStatus] = useState(0);
  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');
  const otp = new URLSearchParams(search).get('otp');

  useEffect(() => {
    patchUnlockAccountConfirm({ email, otp })
      .then((result: any) => {
        if (result.statusCode === 200) {
          setStatus(1);
          notification.success({ message: t('message.unlockAccountSuccess') });
        } else {
          setStatus(2);
        }
      })
      .catch((e) => notification.error({ message: e.message }));
  }, []);

  const goLogin = () => {
    navigate('/login');
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <S.ContentWrapper>
          <S.ImageWrapper>
            <Image src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>{t('common.verifyEmailTile')}</Auth.FormTitle>
          <S.VerifyEmailDescription>
            {status == 0
              ? t('common.verifyingEmail')
              : status == 1
              ? t('common.verifyEmailSuccess')
              : t('common.verifyEmailError')}
          </S.VerifyEmailDescription>
          {status == 0 ? (
            <Spin />
          ) : status == 1 ? (
            <CheckCircleOutlined className="done-check-icon" />
          ) : (
            <CloseCircleOutlined className="error-check-icon" />
          )}
          {status == 1 && (
            <Button className="mt-2" type="text" onClick={() => goLogin()} size="small">
              <LeftOutlined /> {t('button.back')}
            </Button>
          )}
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
