import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { profileNavData } from '@app/constants/profileNavData';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './ProfileNav.styles';

export const ProfileNav: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [isEditMember, setIsEditMember] = useState(false);

  useEffect(() => {
    location.pathname.indexOf('/members') && setIsEditMember(true);
  }, [location.pathname]);

  return (
    <S.Wrapper>
      {profileNavData
        .filter((data) => !isEditMember || (isEditMember && !data.onlyEditMember))
        .map((item) => (
          <S.Btn
            key={item.id}
            icon={item.icon}
            type="text"
            color={item.color}
            onClick={() => navigate(item.href)}
            className="pl-0"
            isActive={location.pathname.indexOf(item.href) >= 0}
            disabled={item.isDisabled}
          >
            {t(item.name)}
          </S.Btn>
        ))}
    </S.Wrapper>
  );
};
