import { readUser } from '@app/services/localStorage.service';
import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';

const initialState: any = {
  member: readUser(),
};

export const setMember = createAction<PrepareAction<any>>('member/setMember', (newMember) => {
  return {
    payload: newMember,
  };
});

export const member = createSlice({
  name: 'member',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setMember, (state, action) => {
      state.member = action.payload;
    });
  },
});

export default member.reducer;
