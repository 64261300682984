import { PolicyForm } from './policyForm';
import { getPasswordPolicyList, setPasswordPolicyList } from '@app/api/policySetting.api';
import { getPasswordPolicyGroup, updatePasswordPolicyGroup } from '@app/api/policySetting.api';
import { getPasswordPolicyDomain, updatePasswordPolicyDomain } from '@app/api/policySetting.api';

export const PasswordPolicy = () => {
  return PolicyForm({
    getPolicy: getPasswordPolicyList,
    updatePolicy: setPasswordPolicyList,
  });
};

export const PasswordPolicyGroup = () => {
  return PolicyForm({
    getPolicy: getPasswordPolicyGroup,
    updatePolicy: updatePasswordPolicyGroup,
  });
};

export const PasswordPolicyDomain = () => {
  return PolicyForm({
    getPolicy: getPasswordPolicyDomain,
    updatePolicy: updatePasswordPolicyDomain,
  });
};


