import { SaveOutlined } from '@ant-design/icons';
import {
  getFrequently,
  getPeriod,
  getTemplateMailByType,
  patchTemplateMail,
  postTemplateMail,
} from '@app/api/mail.api';
import { Card } from '@app/components/common/Card/Card';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Option, Select } from '@app/components/common/selects/Select/Select';
import { RadioBtn } from '@app/components/header/components/settingsDropdown/settingsOverlay/SettingsOverlay/SettingsOverlay.styles';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { Col, Form, Radio, Row, Tooltip, notification } from 'antd';
import { CKEditor } from 'ckeditor4-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const initValues = {
  templateType: 'manual',
  email: 'umematsu.jp@umematsu.com',
};

const typeList: {
  tooltip?: string;
  text: string;
  value: string;
}[] = [
  {
    value: 'manual',
    text: 'Manual',
  },
  {
    value: 'alert1',
    text: 'alert1',
    tooltip: 'mail.tooltip1',
  },
  {
    value: 'alert2',
    text: 'alert2',
    tooltip: 'mail.tooltip2',
  },
  {
    value: 'alert3',
    text: 'alert3',
    tooltip: 'mail.tooltip3',
  },
];

const AddMailTemplatePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState<any>();
  const [isAlert1, setIsAlert1] = useState(false);
  const [type, setType] = useState('manual');

  // const [typeList, setTypeList] = useState([]);
  const [listFrequently, setListFrequently] = useState<any[]>([]);
  const [listPeriod, setListPeriod] = useState<any[]>([]);
  const [content, setContent] = useState('');
  const changeContent = (data: any) => {
    setContent(data.editor.getData());
  };

  useEffect(() => {
    getTemplateType();
    getFrequentlyPeriod();
  }, []);

  const getTemplateType = () => {
    // getTemplateMailType().then((res: any) => {
    //   if (res && res?.status == 'success') {
    //     setTypeList(res.data);
    //   }
    // });
  };

  const changeTemplateType = (data: any) => {
    setIsAlert1(data.target.value == 'alert1');
    setType(data.target.value);
    setIsLoading(true);
    if (data.target.value !== 'manual') {
      getTemplateMailByType(data.target.value)
        .then((res: any) => {
          if (res?.status == 'success') {
            setContent(res.data.text);
            form.resetFields();
            form.setFieldsValue(res.data);
            form.setFieldValue('templateType', data.target.value);
            form.setFieldValue('content', res.data.text);
            setId(res.data.id);
          } else {
            form.setFieldValue('content', '');
            setContent('');
            form.resetFields();
            form.setFieldValue('templateType', data.target.value);
            notification.error({ message: res.message });
          }
        })
        .catch(() => notification.error({ message: t('message.error500') }));
    } else {
      setContent('');
      form.resetFields();
      form.setFieldValue('templateType', data.target.value);
      setId(null);
    }
    setIsLoading(false);
  };

  const handleSubmit = (_data: any) => {
    setIsLoading(true);
    const data = {
      name: _data.name,
      type: _data.templateType,
      subject: _data.subject,
      text: content,
      frequently: _data.frequently,
      period: _data.period,
    };
    if (!id) {
      postTemplateMail(data)
        .then((res: any) => {
          if (res && res?.status == 'success') {
            notification.success({ message: res.data });
            navigate('/mail-setting?tab=2');
          } else {
            notification.error({ message: res?.message || t('message.error500') });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      patchTemplateMail(id, data)
        .then((res: any) => {
          if (res && res?.status == 'success') {
            notification.success({ message: res.data });
            navigate('/mail-setting?tab=2');
          } else {
            notification.error({ message: res?.message || t('message.error500') });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getFrequentlyPeriod = () => {
    getFrequently().then((res) => {
      const list = res?.data || [];
      setListFrequently(list);
    });
    getPeriod().then((res) => {
      const list = res?.data || [];
      setListPeriod(list);
    });
  };

  return (
    <>
      <PageTitle>{t('common.mailSetting')}</PageTitle>
      <Card padding={20}>
        <Auth.FormWrapper className="p-0 overflow-hidden">
          <BaseForm
            confirmSubmitProps={{ active: true }}
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            form={form}
            initialValues={initValues}
          >
            <Row gutter={[30, 0]}>
              <Col xs={24} md={12}>
                <Auth.FormItem
                  name="templateType"
                  label={t('mail.templateType')}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('common.requiredField')));
                      },
                    },
                  ]}
                >
                  <Radio.Group className="text-left" onChange={(data) => changeTemplateType(data)} value={type}>
                    {typeList.map((field) =>
                      field.tooltip ? (
                        <Tooltip placement="top" title={t(field.tooltip)} key={field.text} mouseLeaveDelay={0}>
                          <RadioBtn value={field.value}>{field.text}</RadioBtn>
                        </Tooltip>
                      ) : (
                        <RadioBtn key={field.value} value={field.value}>
                          {field.text}
                        </RadioBtn>
                      ),
                    )}
                  </Radio.Group>
                </Auth.FormItem>
              </Col>
              <Col xs={24} md={12}></Col>
              <Col xs={24} md={12}>
                <Auth.FormItem
                  name="name"
                  label={t('mail.name')}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('common.requiredField')));
                      },
                    },
                    { max: 255, message: t('validator.maxLength', { count: 255 }) },
                  ]}
                >
                  <Auth.FormInput placeholder={t('mail.name')} />
                </Auth.FormItem>
              </Col>
              <Col xs={24} md={12}>
                <Auth.FormItem
                  name="subject"
                  label={t('mail.subject')}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('common.requiredField')));
                      },
                    },
                    { max: 255, message: t('validator.maxLength', { count: 255 }) },
                  ]}
                >
                  <Auth.FormInput placeholder={t('mail.subject')} />
                </Auth.FormItem>
              </Col>
              <Col xs={24} md={24}>
                <Auth.FormItem
                  name="content"
                  label={t('mail.content')}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('common.requiredField')));
                      },
                    },
                  ]}
                >
                  <CKEditor
                    debug={true}
                    name="contentMail"
                    initData={content}
                    type={'classic'}
                    onChange={(data) => {
                      changeContent(data);
                    }}
                    style={{ borderColor: 'none' }}
                  />
                </Auth.FormItem>
              </Col>
              {isAlert1 && (
                <>
                  <Col xs={24} md={12}>
                    <Auth.FormItem
                      name="frequently"
                      label={t('mail.frequently')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                      ]}
                    >
                      <Select placeholder={t('mail.pleaseChooseFrequently')}>
                        {listFrequently.map((field: any) => (
                          <Option value={field}>{field}</Option>
                        ))}
                      </Select>
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} md={12}>
                    <Auth.FormItem
                      name="period"
                      label={t('mail.period')}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                      ]}
                    >
                      <Select placeholder={t('mail.pleaseChoosePeriod')}>
                        {listPeriod.map((field: any) => (
                          <Option value={field}>{field}</Option>
                        ))}
                      </Select>
                    </Auth.FormItem>
                  </Col>
                </>
              )}
              <Col xs={24} md={24}>
                <BaseForm.Item noStyle>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    {t('button.save')} <SaveOutlined />
                  </Button>
                </BaseForm.Item>
              </Col>
            </Row>
          </BaseForm>
        </Auth.FormWrapper>
      </Card>
    </>
  );
};

export default AddMailTemplatePage;
