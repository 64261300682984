import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Button } from '@app/components/common/buttons/Button/Button';
import * as Auth from '@app/components/members/chooseMethod/AddSingleUser.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Col, Row, Radio, message } from 'antd';
import { CKEditor } from 'ckeditor4-react';
import { Card } from '@app/components/common/Card/Card';
import { SendOutlined } from '@ant-design/icons';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { readProfile } from '@app/services/localStorage.service';
import { getTemplateMailList, getMailSetting, postSendMail } from '@app/api/mail.api';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { notification } from 'antd';
import { getProfile } from '@app/api/login.api';
import i18n from '@app/i18n';
import { Loading } from '@app/components/common/Loading';

interface ISendEmailSetting {
  text?: string;
}

interface IProfile {
  email?: string;
  name?: string;
  replyTo?: string;
  status?: string;
}

const SendMailPage: React.FC = () => {
  const { t } = useTranslation();

  const [initValues, setInitValues] = useState<ISendEmailSetting>();
  const [initProfile, setInitProfile] = useState<IProfile>();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowCkeditor, setIsShowCkeditor] = useState(true);
  const [destination, setDestination] = useState<string>('');
  const [templateList, setTemplateList] = useState([]);
  const [template, setTemplate] = useState<any>({});
  const [content, setContent] = useState('');
  const [isShowForm, setIsShowForm] = useState(false);

  const [groups, setGroups] = useState([]);
  const [domains, setDomains] = useState([]);
  const [form] = BaseForm.useForm();

  const changeContent = (data: any) => {
    setContent(data.editor.getData());
  };

  useEffect(() => {
    onGetMailSetting();
  }, []);

  const onGetMailSetting = () => {
    setIsLoading(true);
    getMailSetting()
      .then((res: any) => {
        if (res?.status === 'success') {
          setInitProfile(res.data);
          onSearchTemplate();
        } else {
          notification.error({
            message: res
              ? res.statusCode === 403
                ? i18n.t('message.doNotHavePermission', {
                    target: i18n.t('common.sendMail'),
                  })
                : res.data.message
              : t('message.error500'),
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  const onSearchTemplate = () => {
    setIsLoading(true);
    setIsShowForm(false);
    const payload = {
      page: 1,
      limit: 999,
      search: '',
    };
    getTemplateMailList(payload).then((res: any) => {
      if (res?.status == 'success') {
        setTemplateList(res.data.data.filter((item: any) => item.type == 'manual'));
        setIsShowForm(true);
      } else {
        notification.error({ message: res ? res.message : t('message.error500') });
        setTemplateList([]);
      }
    });
    getProfile()
      .then((res: any) => {
        const { groups: g, domains: d } = res;
        setGroups(g);
        setDomains(d);
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (_data: any) => {
    setIsLoading(true);
    const data = {
      allOfSystem: destination?.includes('1'),
      groupIds: _data.groupIds ? [_data.groupIds] : [],
      domainIds: _data.domainIds ? [_data.domainIds] : [],
      text: content,
      subject: template.subject,
      templateId: template.id,
    };
    postSendMail(data)
      .then((res: any) => {
        if (res?.status == 'success') notification.success({ message: res.data });
        else notification.error({ message: res ? res.message : t('message.error500') });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeTemplate = (data: any) => {
    const getFilter: any = templateList.filter((item: any) => item?.id == data)[0];
    setIsShowCkeditor(false);
    setTemplate(getFilter);
    setContent(getFilter.text);
    setInitValues({
      ...initValues,
      text: getFilter?.text,
    });
    setTimeout(() => {
      setIsShowCkeditor(true);
    }, 500);
  };

  const onChangeDestination = (data: any) => {
    form.resetFields(['groupIds', 'domainIds']);
    setDestination(data);
  };

  return !isLoading ? (
    isShowForm ? (
      <>
        <PageTitle>{t('common.mailSetting')}</PageTitle>
        <Auth.FormWrapper className="p-0 overflow-hidden max-height-100">
          <BaseForm
            confirmSubmitProps={{ active: true }}
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initValues}
            form={form}
            className={'add-mail-form'}
          >
            {(_, instance) => {
              return (
                <Row gutter={[30, 0]}>
                  <Col xs={24} md={12}>
                    <Auth.FormItem
                      name="mailTemplate"
                      label={t('mail.mailTemplate')}
                      rules={[
                        {
                          required: true,
                          message: t('common.requiredField'),
                        },
                      ]}
                    >
                      <Select
                        placeholder={t('mail.chooseTemplate')}
                        onChange={changeTemplate}
                        showSearch
                        filterOption={(input, option) => {
                          return (option?.children?.toLowerCase() ?? '').includes(input.toLowerCase());
                        }}
                      >
                        {templateList.map((field: any) => (
                          <Option value={field.id}>{field.name}</Option>
                        ))}
                      </Select>
                    </Auth.FormItem>
                  </Col>
                  {/* <Col xs={24} md={12}></Col> */}
                  <Col xs={24} md={12}>
                    <Auth.FormItem
                      name="destination"
                      label={t('mail.destination')}
                      rules={[
                        {
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                        {
                          required: true,
                          validator: (_, value) => {
                            return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                              ? Promise.resolve()
                              : Promise.reject(new Error(t('common.requiredField')));
                          },
                        },
                      ]}
                    >
                      <Select placeholder={t('mail.chooseDestination')} onChange={onChangeDestination}>
                        <Option value="1">{t('mail.allOfSystem')}</Option>
                        <Option value="2">{t('mail.allOfGroup')}</Option>
                        <Option value="3">{t('mail.allOfDomain')}</Option>
                      </Select>
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} md={24}></Col>
                  {destination !== '' && !destination.includes('1') && (
                    <Col xs={24} md={12}>
                      <Auth.FormItem
                        name="groupIds"
                        label={t('domain.group')}
                        rules={[
                          {
                            validator: (_, value) => {
                              return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('common.requiredField')));
                            },
                          },
                          {
                            required: true,
                            validator: (_, value) => {
                              return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('common.requiredField')));
                            },
                          },
                        ]}
                      >
                        <Select
                          placeholder={t('domain.pleaseChooseGroup')}
                          showSearch
                          filterOption={(input, option) => {
                            return (option?.children?.toLowerCase() ?? '').includes(input.toLowerCase());
                          }}
                        >
                          {groups
                            .filter((g: any) => g.isActive)
                            .map((field: any) => (
                              <Option value={field.id}>{field.name}</Option>
                            ))}
                        </Select>
                      </Auth.FormItem>
                    </Col>
                  )}
                  {!destination.includes('1') && destination.includes('3') && (
                    <Col xs={24} md={12}>
                      <Auth.FormItem
                        name="domainIds"
                        label={t('domain.domain')}
                        rules={[
                          {
                            validator: (_, value) => {
                              return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('common.requiredField')));
                            },
                          },
                          {
                            required: true,
                            validator: (_, value) => {
                              return (![undefined, null].includes(value) ? value : '').toString().trim() !== ''
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('common.requiredField')));
                            },
                          },
                        ]}
                      >
                        <Select
                          placeholder={t('domain.pleaseChooseDomain')}
                          showSearch
                          filterOption={(input, option) => {
                            return (option?.children?.toLowerCase() ?? '').includes(input.toLowerCase());
                          }}
                        >
                          {domains
                            .filter((item: any) => item.groupId === instance.getFieldValue('groupIds'))
                            .filter((d: any) => d.isActive)
                            .map((field: any) => (
                              <Option value={field.id}>{field.domainName}</Option>
                            ))}
                        </Select>
                      </Auth.FormItem>
                    </Col>
                  )}
                  <Col xs={24} md={24}></Col>
                  <Col xs={24} md={12}>
                    <Auth.FormItem name="name" label={t('mail.name')}>
                      {initProfile?.name}
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} md={12}>
                    <Auth.FormItem label={t('mail.mail')}>{initProfile?.email}</Auth.FormItem>
                  </Col>
                  <Col xs={24} md={12}>
                    <Auth.FormItem name="replyTo" label={t('mail.replyTo')}>
                      {initProfile?.replyTo}
                    </Auth.FormItem>
                  </Col>
                  {template.subject !== null && template.subject !== undefined && (
                    <Col xs={24} md={12}>
                      <Auth.FormItem name="subject" label={t('mail.subject')}>
                        {template.subject}
                      </Auth.FormItem>
                    </Col>
                  )}
                  <Col xs={24} md={24}>
                    <Auth.FormItem
                      name="text"
                      label={t('mail.content')}
                      rules={[
                        () => ({
                          validator() {
                            if (!content || content === '') {
                              return Promise.reject(new Error(t('common.requiredField')));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      {isShowCkeditor ? (
                        <CKEditor
                          debug={true}
                          name="contentMail"
                          initData={content}
                          readOnly={false}
                          type={'classic'}
                          onChange={(data) => {
                            changeContent(data);
                          }}
                          style={{ borderColor: 'none' }}
                        />
                      ) : (
                        <Spinner size="large" />
                      )}
                    </Auth.FormItem>
                  </Col>
                  <Col xs={24} md={24}>
                    <BaseForm.Item noStyle>
                      <Button type="primary" htmlType="submit" loading={isLoading}>
                        {t('button.send')} <SendOutlined />
                      </Button>
                    </BaseForm.Item>
                  </Col>
                </Row>
              );
            }}
          </BaseForm>
        </Auth.FormWrapper>
      </>
    ) : (
      <></>
    )
  ) : (
    <Loading />
  );
};

export default SendMailPage;
