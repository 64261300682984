import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Button } from '@app/components/common/buttons/Button/Button';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import { notification } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LeftOutlined,
  UndoOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { putVerifyEmail, getVerifyEmail } from '@app/api/login.api';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './VerifyForm.styles';

interface SecurityCodeFormProps {
  onBack?: () => void;
  onFinish?: () => void;
}

export const VerifyForm: React.FC<SecurityCodeFormProps> = ({ onBack, onFinish }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [status, setStatus] = useState(0);
  const [isResending, setIsResending] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    putVerifyEmail({
      email: urlParams.get('email'),
      token: urlParams.get('token'),
    })
      .then((res: any) => {
        if (res?.status === 'success') {
          setStatus(1);
          notification.success({ message: res.data });
        } else {
          setStatus(2);
        }
      })
      .catch((e) => {
        setStatus(2);
      });
  }, []);

  const goLogin = () => {
    navigate('/login');
  };

  const reSendVerify = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setIsResending(true);
    getVerifyEmail({
      email: urlParams.get('email'),
    })
      .then((res: any) => {
        if (res?.status === 'success') {
          notification.success({ message: res.data });
        }
      })
      .finally(() => {
        setIsResending(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <S.ContentWrapper>
          <S.ImageWrapper>
            <Image src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>{t('common.verifyEmailTile')}</Auth.FormTitle>
          <S.VerifyEmailDescription>
            {status == 0
              ? t('common.verifyingEmail')
              : status == 1
              ? t('common.verifyEmailSuccess')
              : t('common.verifyEmailError')}
          </S.VerifyEmailDescription>
          {status == 0 ? (
            <Spin />
          ) : status == 1 ? (
            <CheckCircleOutlined className="done-check-icon" />
          ) : (
            <CloseCircleOutlined className="error-check-icon" />
          )}
          {status == 1 && (
            <Button className="mt-2" type="text" onClick={() => goLogin()} size="small">
              <LeftOutlined /> {t('button.back')}
            </Button>
          )}
          {status == 2 && (
            <Button className="mt-2" type="text" onClick={() => reSendVerify()} size="small">
              {isResending ? <LoadingOutlined /> : <UndoOutlined />} {t('button.reSendVerify')}
            </Button>
          )}
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
