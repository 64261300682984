import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';

const initialState: any = {
  dirty: null,
};

export const setDirty = createAction<PrepareAction<any>>('dirty/setDirty', (newDirty) => {

  return {
    payload: newDirty,
  };
});

export const dirty = createSlice({
  name: 'dirty',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setDirty, (state, action) => {
      state.dirty = action.payload;
    });
  },
});

export default dirty.reducer;
