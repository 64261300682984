import { CheckOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Switch } from '@app/components/common/Switch/Switch';
import { RoleAction } from '@app/pages/RoleSetting/RoleSettingPage';
import { Dropdown, Menu, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Pagination, RoleTableRow } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
interface tableProps {
  typeAction?: RoleAction;
  dataTable?: any;
  roleSetting?: any;
  isPermissionPage: boolean;
  typeSelected: any;
  reloadDataTable: (data: any) => void;
}

const initialPagination: Pagination = {
  current: 1,
  pageSize: 999,
};

export const BasicTable: React.FC<tableProps> = ({
  typeAction = RoleAction.VIEW,
  dataTable,
  roleSetting,
  isPermissionPage,
  typeSelected,
  reloadDataTable,
}) => {
  const [isLoadingSwitch, setIsLoadingSwitch] = useState(false);

  const [tableData, setTableData] = useState<{ data: RoleTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: true,
  });

  const [rootType, setRootType] = React.useState<'system' | 'domain' | 'group'>('system');

  React.useEffect(() => {
    Array.isArray(dataTable) && setRootType(dataTable[0]?.type || 'system');
  }, [dataTable]);

  const { t } = useTranslation();

  useEffect(() => {
    setTableData({
      data: dataTable,
      pagination: initialPagination,
      loading: false,
    });
    // setInterval(() => {
    //   console.log(dataTable)
    // }, 10000)
  }, [dataTable, isLoadingSwitch]);

  const actionFunc = (isActive: boolean, role: any) => {
    const action = role.permissions.map((per: any) => (per.disabled ? per : { ...per, isActive }));
    role.permissions = action;
  };

  const actionAll = async (isActive: boolean, props: any) => {
    setIsLoadingSwitch(true);
    actionFunc(isActive, props);
    if (props.children) {
      props.children.forEach(async (child: any) => {
        actionFunc(isActive, child);
        if (child.children) {
          child.children.forEach(async (subchild: any) => {
            actionFunc(isActive, subchild);
          });
        }
      });
    }
    setTimeout(() => {
      setIsLoadingSwitch(false);
    }, 1);
  };
  const actionThisLevel = async (isActive: boolean, props: any) => {
    setIsLoadingSwitch(true);
    actionFunc(isActive, props);
    setTimeout(() => {
      setIsLoadingSwitch(false);
    }, 1);
  };

  const actionColumn: ColumnsType<RoleTableRow> = [
    {
      title: 'Action',
      dataIndex: 'action',
      className: 'action-column',
      fixed: 'right',
      width: '10%',
      render: (_, props: any) => {
        interface menuProps {
          isActive: boolean;
        }
        const MenuAction = ({ isActive }: menuProps) => (
          <Menu>
            {props.type !== 'domain' && <Menu.Item onClick={() => actionAll(isActive, props)}>All</Menu.Item>}
            <Menu.Item onClick={() => actionThisLevel(isActive, props)}>All this level</Menu.Item>
          </Menu>
        );
        return (
          <>
            <Space className="ml-1 mb-2">
              <Dropdown overlay={<MenuAction isActive={true} />}>
                <a style={{ color: 'green' }}>
                  Activate <DownOutlined />
                </a>
              </Dropdown>
            </Space>

            <Space className="ml-1 mb-2">
              <Dropdown overlay={<MenuAction isActive={false} />}>
                <a style={{ color: 'orange' }}>
                  Deactivate <DownOutlined />
                </a>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];
  const columns: ColumnsType<RoleTableRow> = [
    {
      title: t('role.system'),
      dataIndex: 'name',
      width: '30%',
      className: 'max-width-30',
      showSorterTooltip: false,
      render: (name: string, props: any) => {
        const color = props.type === 'system' ? 'purple' : props.type === 'group' ? 'geekblue' : 'green';
        return (
          <>
            <Tag color={color}>{props.type.toUpperCase()}</Tag>
            {name}
          </>
        );
      },
    },
    {
      title: t('role.permission'),
      dataIndex: 'action',
      className: 'action-column',
      fixed: 'right',
      width: '60%',
      render: (status: boolean, record: any) => {
        const checkImpact = (clone: any, permissionName: string) => {
          const filteredByValue: any = Object.fromEntries(
            Object.entries(roleSetting).filter(
              ([key, value]: any) => value && value.implications && value?.implications.includes(permissionName),
            ),
          );
          if (filteredByValue) {
            for (const property in filteredByValue) {
              clone.forEach((item: any) => {
                item.permissions.forEach((_item: any) => {
                  if (_item.name == property) {
                    _item.isActive = false;
                  }
                });
              });
            }
          }
        };
        const checkImpactSingle = (item: any, permissionName: string, value: boolean) => {
          if (!roleSetting?.[permissionName]?.implications) return;
          const getImplication = roleSetting[permissionName].implications;
          if (getImplication.length) {
            getImplication.forEach((item1: any, _index: number) => {
              const pos = item.permissions.map((e: any) => e.name).indexOf(item1);
              if (pos >= 0 && value) {
                record.permissions[pos].isActive = value;
              }
            });
          }
          const filteredByValue: any = Object.fromEntries(
            Object.entries(roleSetting).filter(
              ([key, value]: any) => value && value.implications && value?.implications.includes(permissionName),
            ),
          );
          if (filteredByValue) {
            for (const property in filteredByValue) {
              item?.permissions.forEach((_item: any) => {
                if (_item.name.replace('All', '') == property) {
                  _item.isActive = false;
                }
              });
            }
            item?.permissions.forEach((_item: any) => {
              if (_item.name.includes('Full') || _item.name.includes('full')) {
                _item.isActive = false;
              }
            });
          }
        };
        const onChangeRole = (status: boolean, index: number, object: any) => {
          record.permissions[index].isActive = status;
          const clone = dataTable;
          setIsLoadingSwitch(true);

          //check system full access,
          if (object.name == 'systemFullAccess') {
            clone.forEach((item: any) => {
              item.permissions.forEach((item1: any) => {
                item1.isActive = status;
              });
            });
          }
          if (object.type == rootType) {
            const permissionName = `${record.permissions[index].name}`;
            const getImplication = roleSetting[permissionName].implications;
            if (getImplication.length) {
              getImplication.forEach((item: any, _index: number) => {
                const pos = record.permissions.map((e: any) => e.name).indexOf(item);
                if (pos >= 0 && status) {
                  record.permissions[pos].isActive = status;
                } else {
                  const posClone = clone.map((e: any) => e.initName).indexOf(item);
                  if (posClone >= 0) {
                    const posClonePer = clone[posClone].permissions.map((e: any) => e.name).indexOf(item);
                    if (posClonePer >= 0) {
                      clone[posClone].permissions[posClonePer].isActive = status;
                      reloadDataTable(clone);
                    }
                  }
                }
              });
            }
            checkImpact(clone, permissionName);
          }

          /**
           * check relation to parent
           */
          if (rootType === 'system') {
            switch (object.type) {
              case 'group':
                clone[0].permissions.forEach((itemRoot: any) => {
                  if (
                    itemRoot.name.replace('All', '') == object.name.replace('All', '') &&
                    !object.name.includes('User') &&
                    !object.name.includes('Link') &&
                    !object.name.includes('Role') &&
                    !object.name.includes('Log') &&
                    !object.name.includes('Policy')
                  ) {
                    checkImpactSingle(clone[0], object.name, false);
                    itemRoot.isActive = false;
                  }
                });

                checkImpactSingle(clone[0].children[object.indexResourceParent], object.name, status);
                break;
              case 'domain':
                const findCurrentParent = clone[0].children.findIndex((item: any) => {
                  return item.children?.some((item: any) => item.id === record.id);
                });
                if (
                  !object.name.includes('User') &&
                  !object.name.includes('Role') &&
                  !object.name.includes('Link') &&
                  !object.name.includes('Log') &&
                  !object.name.includes('Policy')
                ) {
                  checkImpactSingle(clone[0], object.name, false);
                  checkImpactSingle(clone[0].children[findCurrentParent], object.name, false);
                }

                checkImpactSingle(
                  clone[0].children[findCurrentParent].children[object.indexResourceParent],
                  object.name,
                  status,
                );
                break;
              default:
                break;
            }
          }
          if (rootType === 'group') {
            switch (object.type) {
              case 'domain':
                clone[0].permissions.forEach((itemRoot: any) => {
                  if (
                    itemRoot.name.replace('All', '') == object.name.replace('All', '') &&
                    !object.name.includes('User') &&
                    !object.name.includes('Role') &&
                    !object.name.includes('Link') &&
                    !object.name.includes('Log') &&
                    !object.name.includes('Policy')
                  ) {
                    checkImpactSingle(clone[0], object.name, false);
                    itemRoot.isActive = false;
                  }
                });

                checkImpactSingle(clone[0].children[object.indexResourceParent], object.name, status);
                break;
              default:
                break;
            }
          }

          record.children &&
            record.children.forEach((itemLv1: any) => {
              itemLv1.permissions.forEach((permissionLv1: any) => {
                if (
                  permissionLv1.name.replace('All', '') == object.name.replace('All', '') &&
                  !permissionLv1.name.includes('User') &&
                  !permissionLv1.name.includes('Role') &&
                  !permissionLv1.name.includes('Link') &&
                  !permissionLv1.name.includes('Log') &&
                  !permissionLv1.name.includes('Policy')
                ) {
                  checkImpact(record.children, permissionLv1.name);
                  permissionLv1.isActive = status;
                }
              });
              itemLv1.children &&
                itemLv1.children.forEach((itemLv2: any) => {
                  itemLv2.permissions.forEach((permissionLv2: any) => {
                    if (
                      permissionLv2.name.replace('All', '') == object.name.replace('All', '') &&
                      !permissionLv2.name.includes('User') &&
                      !permissionLv2.name.includes('Role') &&
                      !permissionLv2.name.includes('Link') &&
                      !permissionLv2.name.includes('Log') &&
                      !permissionLv2.name.includes('Policy')
                    ) {
                      checkImpact(itemLv1.children, permissionLv2.name);
                      permissionLv2.isActive = status;
                    }
                  });
                });
            });

          /**
           * check full permission
           */

          reloadDataTable(clone);
          setTimeout(() => {
            setIsLoadingSwitch(false);
          }, 0);
        };
        return (
          <>
            {record.permissions.map((object: any, index: number) => (
              <Space className="ml-2 mb-1">
                {!isLoadingSwitch ? (
                  <Switch
                    disabled={typeSelected == 'systemAdmin' || typeAction === RoleAction.VIEW || object.disabled}
                    defaultChecked={object.isActive}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(status) => onChangeRole(status, index, object)}
                  />
                ) : (
                  <span>
                    <Switch
                      disabled={object.disabled}
                      defaultChecked={object.isActive}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </span>
                )}
                {t(`role.${object.name}`)}
              </Space>
            ))}
          </>
        );
      },
    },
    ...(typeAction !== RoleAction.VIEW ? actionColumn : []),
  ];
  return (
    <>
      <Table
        columns={columns}
        expandable={{
          defaultExpandedRowKeys: [1],
        }}
        dataSource={tableData.data}
        pagination={false}
        loading={tableData.loading}
        bordered
      />
      {isPermissionPage ? (
        <Button className="float-right" type="primary">
          {t('button.apply')}
        </Button>
      ) : (
        ''
      )}
    </>
  );
};
